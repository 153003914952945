import type { Tab } from "@/react/prop-types";
import { ProjectDetailTearsheetTabs } from "./types";
import { CannedMessageType } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";

//adding more objects to this array will automatically start rendering other tabs, just maintain unique ID and labels.
export const projectDetailTabs: Tab[] = [
   { id: 0, label: ProjectDetailTearsheetTabs.OVERVIEW, visible: true },
   { id: 1, label: ProjectDetailTearsheetTabs.SPECIFICATIONS, visible: true },
   { id: 2, label: ProjectDetailTearsheetTabs.NOTES, visible: true },
   { id: 3, label: ProjectDetailTearsheetTabs.ACTIVITY, visible: true },
];

export const projectDetailFieldsSection1 = [
   {
      id: "views.company.workforce_planning.projects.name",
      name: "name",
      type: "text",
      required: true,
   },
   {
      id: "views.company.workforce_planning.projects.project_number",
      name: "job_number",
      type: "text",
   },
   {
      id: "views.company.workforce_planning.projects.status",
      name: "status",
      type: "pill-select",
      required: true,
   },
   {
      id: "views.company.workforce_planning.projects.groups",
      name: "group_ids",
      type: "multi-select",
      values: null,
      required: true,
   },
   {
      id: "views.company.workforce_planning.projects.start_date",
      name: "start_date",
      type: "date",
      values: null,
      required: true,
   },
   {
      id: "views.company.workforce_planning.projects.est_end_date",
      name: "est_end_date",
      type: "date",
      values: null,
   },
   {
      id: "views.company.workforce_planning.projects.daily_start_time",
      name: "daily_start_time",
      type: "select",
      values: null,
   },
   {
      id: "views.company.workforce_planning.projects.daily_end_time",
      name: "daily_end_time",
      type: "select",
      values: null,
   },
   {
      id: "views.company.workforce_planning.projects.timezone",
      name: "timezone",
      type: "select",
      values: null,
      required: true,
   },
];

export const projectDetailFieldsSection2 = [
   {
      id: "views.company.workforce_planning.projects.address",
      name: "address_1",
      type: "text",
   },
   {
      id: "views.company.workforce_planning.projects.address_2",
      name: "address_2",
      type: "text",
   },
   {
      id: "views.company.workforce_planning.projects.city",
      name: "city_town",
      type: "text",
   },
   {
      id: "views.company.workforce_planning.projects.state",
      name: "state_province",
      type: "text",
   },
   {
      id: "views.company.workforce_planning.projects.postal",
      name: "zipcode",
      type: "text",
   },
   {
      id: "views.company.workforce_planning.projects.country",
      name: "country",
      type: "text",
   },
];

export const projectDetailFieldsSection3 = [
   {
      id: "views.company.workforce_planning.projects.est_avg_rate",
      name: "bid_rate",
      type: "currency",
   },
   {
      id: "views.company.workforce_planning.projects.percent_complete",
      name: "percent_complete",
      type: "number",
   },
   {
      id: "views.company.workforce_planning.projects.customer",
      name: "customer_name",
      type: "text",
   },
   {
      id: "views.company.workforce_planning.projects.project_type",
      name: "project_type",
      type: "text",
   },
   {
      id: "views.company.workforce_planning.projects.color",
      name: "color",
      type: "hex-color",
   },
];

export const customAlertTypes = [
   {
      type: CannedMessageType.ASSIGNMENT_NEW,
      title: "views.company.workforce_planning.project_details_custom_assignment_alerts.new_assignment",
      defaultTitle: "New Assignment",
   },
   {
      type: CannedMessageType.ASSIGNMENT_EDIT,
      title: "views.company.workforce_planning.project_details_custom_assignment_alerts.change_to_existing_assignment",
      defaultTitle: "Change to Existing Assignment",
   },
   {
      type: CannedMessageType.ASSIGNMENT_TRANSFER,
      title: "views.company.workforce_planning.project_details_custom_assignment_alerts.assignment_transfer",
      defaultTitle: "Assignment Transfer",
   },
   {
      type: CannedMessageType.ASSIGNMENT_DELETE,
      title: "views.company.workforce_planning.project_details_custom_assignment_alerts.deleted_assignment",
      defaultTitle: "Deleted Assignment",
   },
];
