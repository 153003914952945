import type { TagOption } from "@/react/prop-types";
import { ActionMode } from "@/react/prop-types";
import {
   useI18nContext,
   Box,
   Grid,
   H3,
   Spinner,
   Button,
   Menu,
   Card,
   Required,
   Typography,
} from "@procore/core-react";
import React, { useEffect, useState } from "react";
import type { AddTagsModalProps, ProjectTagData } from "../types";
import { FileUpload } from "@/react/shared/components/file-upload";
import styles from "./project-details.scss";

import classnames from "classnames";
import { prepareTagPayload } from "../helpers";
import { DetailsCardHeader } from "@/react/shared/components/details-card-header";

const cx = classnames.bind(styles);

export const AddTags = ({
   open,
   onClose,
   handleCreate,
   availableTags,
   showLoader,
}: AddTagsModalProps) => {
   const I18n = useI18nContext();
   const [attachments, setAttachments] = useState<any[]>([]);
   const [deletedAttachmentIds, setDeletedAttachmentIds] = useState<string[]>([]);
   const [groupedItems, setGroupedItems] = useState<{ [key: string]: any }>({});
   const [filteredTagOptions, setFilteredTagOptions] = useState<TagOption[]>(availableTags || []);
   const [selectedTagId, setSelectedTagId] = useState("");
   const [loading, setLoading] = useState(false);

   const handleClose = () => {
      onClose();
      setAttachments([]);
      setDeletedAttachmentIds([]);
      setSelectedTagId("");
      setFilteredTagOptions(availableTags || []);
   };

   useEffect(() => {
      setFilteredTagOptions(availableTags);
   }, [availableTags]);

   const groupByCategories = (items: any[]) => {
      const groups: { [key: string]: any[] } = {};

      if (!items.length) return groups;

      items.forEach((item: any) => {
         const categories = item.categories.length > 0 ? item.categories : ["All Tags"];

         categories.forEach((category: string) => {
            if (!groups[category]) {
               groups[category] = [];
            }
            groups[category].push(item);
         });
      });
      return groups;
   };

   const FilterTags = (searchTerm: string) => {
      if (!availableTags.length) return;
      const filteredTags = filteredTagOptions.filter((tag) =>
         tag.name.toLowerCase().startsWith(searchTerm.toLowerCase()),
      );
      setFilteredTagOptions(filteredTags);
   };

   const handleTagCreation = async () => {
      const payload: ProjectTagData = prepareTagPayload(
         attachments,
         deletedAttachmentIds,
         selectedTagId,
      );
      await handleCreate(payload, ActionMode.SAVE);
      handleClose();
   };

   useEffect(() => {
      setGroupedItems(groupByCategories(filteredTagOptions));
   }, [filteredTagOptions]);

   const sortByCategory = ([a]: [string, any], [b]: [string, any]) => {
      if (a === "All Tags") return 1;
      if (b === "All Tags") return -1;
      return 0;
   };

   // Using the sortByCategory function in sorting
   const sortedEntries = Object.entries(groupedItems).sort(sortByCategory);

   if (!open) {
      return null;
   }

   return (
      <React.Fragment>
         <DetailsCardHeader
            titleKey="views.company.workforce_planning.project_details_tags.title"
            helpTextKey="views.company.workforce_planning.project_details_tags.tags_certification_help_text"
            buttonTextKey="views.company.workforce_planning.project_details_tags.add_tag"
            btnDisabled={true}
         />
         <Box padding="sm" className={cx("custom-box")}>
            <Grid>
               <Grid.Row>
                  <Grid.Col colWidth={6} style={{ display: "flex" }}>
                     <H3>
                        {I18n.t("views.company.workforce_planning.project_details_tags.tag_title")}{" "}
                        <Required style={{ marginLeft: "5px" }} />
                     </H3>
                  </Grid.Col>
                  <Grid.Col colWidth={6}>
                     <H3>
                        {I18n.t(
                           "views.company.workforce_planning.project_details_tags.tags_attachments_label",
                        )}
                     </H3>
                  </Grid.Col>
               </Grid.Row>

               <Grid.Row style={{ marginTop: "5px" }}>
                  <Grid.Col colWidth={6} style={{ display: "flex" }}>
                     <Box>
                        <Card shadowStrength={2}>
                           <Menu
                              scrollable
                              style={{
                                 maxHeight: "300px",
                              }}
                              onSelect={({ item }) => setSelectedTagId(item)}
                              onSearch={(e) => FilterTags(e.target.value || "")}
                           >
                              <Menu.Search
                                 placeholder={I18n.t(
                                    "views.company.workforce_planning.project_details_tags.tag_search_placeholder",
                                 )}
                              />
                              <Menu.Header>
                                 <Box padding="lg">
                                    <Typography
                                       color="gray70"
                                       italic
                                       style={{
                                          display: "flex",
                                          justifyContent: "center",
                                       }}
                                    >
                                       {I18n.t(
                                          "views.company.workforce_planning.project_details_tags.tag_menu_header",
                                       )}
                                    </Typography>
                                 </Box>
                              </Menu.Header>
                              <Menu.Options>
                                 {sortedEntries.length > 0 ? (
                                    sortedEntries.map(([category, items]) => (
                                       <React.Fragment key={category}>
                                          <Menu.Group>{category}</Menu.Group>
                                          {items.map((item: any) => (
                                             <Menu.Item
                                                selected={selectedTagId === item.id}
                                                key={item.id}
                                                item={item.id}
                                                style={{ backgroundColor: "white" }}
                                             >
                                                {item.name}
                                             </Menu.Item>
                                          ))}
                                       </React.Fragment>
                                    ))
                                 ) : (
                                    <Menu.Item style={{ pointerEvents: "none" }}>
                                       {I18n.t(
                                          "views.company.workforce_planning.project_details_tags.no_tags",
                                       )}
                                    </Menu.Item>
                                 )}
                              </Menu.Options>
                           </Menu>
                        </Card>
                     </Box>
                  </Grid.Col>

                  <Grid.Col colWidth={6}>
                     <Box>
                        <FileUpload
                           attachments={attachments}
                           setAttachments={setAttachments}
                           deletedAttachmentIds={deletedAttachmentIds}
                           setDeletedAttachmentIds={setDeletedAttachmentIds}
                           loading={loading}
                           setLoading={setLoading}
                        />
                     </Box>
                  </Grid.Col>
               </Grid.Row>
            </Grid>
            <Box
               display="flex"
               justifyContent="space-between"
               alignItems="center"
               style={{ width: "100%" }}
               padding="sm"
            >
               <Required showLabel />
               <Box>
                  <Button
                     variant="secondary"
                     onClick={handleClose}
                     style={{ marginRight: "8px" }}
                     disabled={loading}
                  >
                     {I18n.t("views.company.workforce_planning.cancel")}
                  </Button>

                  <Button
                     variant="primary"
                     onClick={handleTagCreation}
                     disabled={!selectedTagId || loading}
                     style={{ width: !showLoader ? "auto" : "70px" }}
                  >
                     {!showLoader ? (
                        I18n.t("views.company.workforce_planning.save")
                     ) : (
                        <Spinner loading={true} size="md" color="white" />
                     )}
                  </Button>
               </Box>
            </Box>
         </Box>
         <Box padding="xxs" />
      </React.Fragment>
   );
};
