import React from "react";
import { Box, Card, EmptyState, FlexList, H2, Spinner, useI18nContext } from "@procore/core-react";
import classnames from "classnames";
import styles from "./project-details.scss";
import type { ProjectDetailsActivitiesProps } from "../types";

const cx = classnames.bind(styles);

const EmptyActivities: React.FC = () => {
   const I18n = useI18nContext();

   return (
      <React.Fragment>
         <Box padding="md">
            <FlexList justifyContent="space-between">
               <H2>
                  {I18n.t("views.company.workforce_planning.project_details_activities.title")}
               </H2>
            </FlexList>
         </Box>
         <Box padding="md">
            <EmptyState compact>
               <EmptyState.NoItemsWithoutCreatePermissions />
               <EmptyState.Title>
                  {I18n.t(
                     "views.company.workforce_planning.project_details_activities.activities_empty_state_title",
                  )}
               </EmptyState.Title>
               <EmptyState.Description className={cx("empty-state-description")}>
                  {I18n.t(
                     "views.company.workforce_planning.project_details_activities.activities_empty_state_description",
                  )}
               </EmptyState.Description>
            </EmptyState>
         </Box>
      </React.Fragment>
   );
};

export const ProjectDetailsActivities: React.FC<ProjectDetailsActivitiesProps> = ({
   projectData,
   loading: projectDataLoading,
}) => {
   return (
      <Card style={{ marginBottom: "5px" }}>
         <Spinner loading={projectDataLoading}>{projectData && <EmptyActivities />}</Spinner>
      </Card>
   );
};
