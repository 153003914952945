import type { TimeZoneOption, TimeZonesMapKeys } from "@/lib/utils/timezones";
import type {
   CustomField,
   PillValue,
   SelectValue,
   SortModel,
   StreamResponseConversion,
} from "@/react/prop-types";
import type { Order } from "@laborchart-modules/common/dist/reql-builder/query-definitions";
import type { CellValueChangeParams, TableApi, IServerFilter } from "@procore/data-table";
import type { ProjectDetailFormValues } from "../tearsheets/project/types";
import type { FindProjectsSortBy } from "./project-list-enums";

export type CoreApiSort = {
   sort_by?: FindProjectsSortBy;
   direction?: Order;
   custom_field_id?: string;
};

export type projectListOptions = {
   timezone: string;
   limit: number;
   group_id?: string;
   filters: any;
   starting_after?: string;
   search?: string;
};

export type color = {
   label: string | null;
   color: string | null;
};

export type ProjectListData = {
   id: string;
   name: string;
   project_name: string;
   color: color;
   start_date: Date;
   est_end_date: Date;
   project_hash: string;
   address_1: string;
   address_2: string;
   city: string;
   state: string;
   project_type: string;
   postal: string;
   status: PillValue<string>;
   customer_name: string;
   created_at: Date | string;
   created_by: string;
   country: string;
   daily_start_time: SelectValue<any>;
   daily_end_time: SelectValue<any>;
   timezone: TimeZoneOption<TimeZonesMapKeys>;
   bid_rate: number;
   percent_complete: string;
   wage_overrides: Array<SelectValue<string>>;
   groups: string;
   group_ids?: string[];
};

export type ProjectListDataTableProps = {
   fetchProjectList: (
      serverFilters: IServerFilter[],
      sortModel: SortModel[],
      startingAfter?: string,
      search?: string,
   ) => Promise<any>;
   groupId: string;
   savedView: any;
   currentDateTime: string;
   tableApi: TableApi | undefined;
   handleTableReady: (api: TableApi) => void;
   customFields?: CustomField[] | null;
   groupOptions: StreamResponseConversion;
   updateProject: (params: CellValueChangeParams<any>) => Promise<any>;
};

export type EditProjectFormValues = Omit<Partial<ProjectDetailFormValues>, "name">;

export const PROJECT_STATUS_OPTIONS = [
   {
      id: "active",
      color: "green",
      label: "views.company.workforce_planning.active",
   } as const,
   {
      id: "inactive",
      color: "gray",
      label: "views.company.workforce_planning.inactive",
   } as const,
   {
      id: "pending",
      color: "yellow",
      label: "views.company.workforce_planning.pending",
   } as const,
];
