import { Button, FlexList, useI18nContext } from "@procore/core-react";
import React from "react";
import { AuthAction, usePermissionContext } from "@/react/providers/permission-context-provider";
import { Plus } from "@procore/core-icons";
import { onCreatePersonClick, usePersonTearsheet } from "../tearsheets/people/people-tearsheet";

export const PeopleListQuickControls = () => {
   const { checkAuthAction } = usePermissionContext();
   const I18n = useI18nContext();
   const [canCreatePerson, setCanCreatePerson] = React.useState(false);
   const { dispatch: personTearsheetDispatch } = usePersonTearsheet();

   React.useEffect(() => {
      setCanCreatePerson(checkAuthAction(AuthAction.CREATE_PEOPLE));
   }, [checkAuthAction]);

   return (
      <FlexList size="xs" alignItems="center" style={{ marginLeft: "auto" }}>
         {canCreatePerson && (
            <Button onClick={() => onCreatePersonClick(personTearsheetDispatch)} icon={<Plus />}>
               {I18n.t("views.company.workforce_planning.create")}
            </Button>
         )}
      </FlexList>
   );
};
