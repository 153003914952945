import * as React from "react";
import { Tearsheet } from "@procore/core-react";
import { CreateProjectTearsheet } from "./create-project-tearsheet-content";
import { ProjectDetailTearsheet } from "./project-detail-tearsheet-content";
import { LaborPlansContainer } from "../../labor-plans/labor-plans-container";
import type { TableApi } from "@procore/data-table";
import { useGetCustomFields, useGetIntegratedFields } from "../../common/queries/queries";
import { CustomFieldEntity } from "@laborchart-modules/common/dist/rethink/schemas/enums/custom-fields";
import { AssignmentTearsheetContent } from "../assignment/assignment-tearsheet-content";

type ProjectTearsheetState = OpenState | ClosedState;
type OpenState = { isOpen: true } & (
   | CreateProject
   | ProjectDetail
   | CreateLaborPlan
   | CreateAssignment
);
type ClosedState = { isOpen: false; show: null };
type CreateProject = { show: "create-project" };
type ProjectDetail = { show: "project-detail"; projectId: string; showActiveTab?: string };
type CreateLaborPlan = { show: "create-labor-plan"; projectId: string };
type CreateAssignment = { show: "create-assignment"; projectId: string };

type CreateProjectAction = { type: "open-create-project" };
type ProjectDetailAction = {
   type: "open-project-detail";
   projectId: string;
   showActiveTab?: string;
};
type CreateLaborPlanAction = { type: "create-labor-plan"; projectId: string };
type CloseAction = { type: "close" };
type CreateAssignmentAction = { type: "open-create-assignment"; projectId: string };

export type ProjectTearsheetActions =
   | CreateProjectAction
   | ProjectDetailAction
   | CloseAction
   | CreateLaborPlanAction
   | CreateAssignmentAction;

function reducer(
   _state: ProjectTearsheetState,
   action: ProjectTearsheetActions,
): ProjectTearsheetState {
   switch (action.type) {
      case "open-create-project": {
         return {
            isOpen: true,
            show: "create-project",
         };
      }
      case "open-project-detail": {
         return {
            isOpen: true,
            show: "project-detail",
            projectId: action.projectId,
            showActiveTab: action.showActiveTab,
         };
      }
      case "create-labor-plan": {
         return {
            isOpen: true,
            show: "create-labor-plan",
            projectId: action.projectId,
         };
      }
      case "open-create-assignment": {
         return {
            isOpen: true,
            show: "create-assignment",
            projectId: action.projectId,
         };
      }
      case "close": {
         return {
            isOpen: false,
            show: null,
         };
      }
   }
}

const initialState: ProjectTearsheetState = {
   isOpen: false,
   show: null,
};

const ProjectTearsheetContext = React.createContext<
   (ProjectTearsheetState & { dispatch: React.Dispatch<ProjectTearsheetActions> }) | null
>(null);

export function ProjectTearsheetProvider({
   children,
   projectsTableApi,
}: {
   children: React.ReactNode;
   projectsTableApi: TableApi | undefined;
}) {
   const [state, dispatch] = React.useReducer(reducer, initialState);
   return (
      <ProjectTearsheetContext.Provider value={{ ...state, dispatch }}>
         {children}
         <ProjectTearsheet projectsTableApi={projectsTableApi} />
      </ProjectTearsheetContext.Provider>
   );
}

export function useProjectTearsheet() {
   const context = React.useContext(ProjectTearsheetContext);

   if (!context) {
      throw Error(
         "'useProjectTearsheet' is supposed to be used in the component that is a child of 'ProjectTearsheetProvider'.",
      );
   }

   return context;
}

export function ProjectTearsheet(props: { projectsTableApi: TableApi | undefined }) {
   const state = useProjectTearsheet();
   const isProjectDetailOpen = state.show === "project-detail";
   const isCreateProjectOpen = state.show === "create-project";
   const isCreateLaborPlan = state.show === "create-labor-plan";
   const isCreateAssignmentOpen = state.show === "create-assignment";
   const { data: customFields } = useGetCustomFields(CustomFieldEntity.PROJECT);

   const { data: integratedFields } = useGetIntegratedFields();

   const handleClose = () => state.dispatch({ type: "close" });

   const onProjectCreated = (projectId: string) => {
      state.dispatch({ type: "open-project-detail", projectId });
      props.projectsTableApi?.refreshServerSide({});
   };

   const onUpdateProject = () => props.projectsTableApi?.refreshServerSide({});

   const onCreateLaborPlanClick = (projectId: string) => {
      state.dispatch({ type: "create-labor-plan", projectId });
   };
   const onShowProjectDetail = (projectId: string) =>
      state.dispatch({ type: "open-project-detail", projectId });

   return (
      <Tearsheet open={state.isOpen} onClose={handleClose}>
         {isCreateProjectOpen && (
            <CreateProjectTearsheet onClose={handleClose} onProjectCreated={onProjectCreated} />
         )}
         {isProjectDetailOpen && (
            <ProjectDetailTearsheet
               projectId={state.projectId}
               handleCreateLaborPlanClick={onCreateLaborPlanClick}
               onUpdateProject={onUpdateProject}
               customFields={customFields!}
               integratedFields={integratedFields!}
               showActiveTab={state.showActiveTab}
            />
         )}
         {isCreateLaborPlan && (
            <LaborPlansContainer
               projectId={state.projectId}
               showProjectDetail={onShowProjectDetail}
               tearsheet
            />
         )}
         {isCreateAssignmentOpen && (
            <AssignmentTearsheetContent
               projectId={state.projectId}
               onClose={handleClose}
               showProjectDetail={onShowProjectDetail}
            />
         )}
      </Tearsheet>
   );
}

export function onCreateProjectClick(dispatch: React.Dispatch<ProjectTearsheetActions>) {
   dispatch({ type: "open-create-project" });
}

export function onProjectDetailClick(
   dispatch: React.Dispatch<ProjectTearsheetActions>,
   id: string,
   showActiveTab?: string,
) {
   dispatch({ type: "open-project-detail", projectId: id, showActiveTab });
}
export function onCreateAssignmentClick(
   dispatch: React.Dispatch<ProjectTearsheetActions>,
   id: string,
) {
   dispatch({ type: "open-create-assignment", projectId: id });
}
