import { Flex, Search, Button } from "@procore/core-react";
import type { Dispatch, SetStateAction } from "react";
import React, { useState } from "react";
import { useI18nContext } from "@procore/core-react";
import { Filter } from "@procore/core-icons";
import { Sliders } from "@procore/core-icons";
import type { ganttFilterType } from "./prop-types";
import { GanttControlPanelFilterPills } from "./gantt-control-panel-filter-pills";
import "./gantt-container.css";

export const GanttControlPanel = (props: {
   search: string;
   setSearch: (value: string) => void;
   toggleConfigPanel: () => void;
   toggleFilterPanel: () => void;
   ganttFilter: ganttFilterType;
   setGanttFilter: Dispatch<SetStateAction<ganttFilterType>>;
}) => {
   const I18n = useI18nContext();
   const { search, setSearch, toggleConfigPanel, toggleFilterPanel, ganttFilter, setGanttFilter } =
      props;

   // Instead of passing the isConfigPanelOpen state in as a prop, just initialize it here and let it always default to closed
   const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);

   return (
      <Flex direction="row" gap="sm" className="ganttControlPanelWrapper">
         <div style={{ display: "flex", alignItems: "center" }}>
            <div>
               <Search
                  value={search}
                  onSubmit={(value) => setSearch(value)}
                  onClear={() => setSearch("")}
                  placeholder={I18n.t("views.company.workforce_planning.gantt.search_placeholder")}
                  style={{ width: "300px" }}
               />
            </div>
            <Button
               variant="tertiary"
               icon={<Filter size="sm" />}
               onClick={() => {
                  toggleFilterPanel();
               }}
               style={{ margin: "0 4px 0 8px" }}
               className={"gantt-filter-toggle-button"}
            />
            <GanttControlPanelFilterPills
               ganttFilter={ganttFilter}
               setGanttFilter={setGanttFilter}
            />
         </div>
         <Button
            variant="tertiary"
            icon={<Sliders size="sm" />}
            onClick={() => {
               toggleConfigPanel();
               setIsConfigPanelOpen(!isConfigPanelOpen);
            }}
            className={isConfigPanelOpen ? "controlPanelButtonActive" : ""}
         />
      </Flex>
   );
};
