import { Url as UrlUtils } from "@/lib/utils/url"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { FanoutManager } from "@/lib/managers/fanout-manager"
import { fanoutManager } from "@/lib/managers/fanout-manager"
import { FanoutMessage } from "@/lib/managers/fanout-manager"
import { Store } from "@/stores/store"
import { ActivityStore } from "@/stores/activity-store.core";
import { ActivityStore as LegacyActivityStore} from "@/stores/activity-store"

### Models ###
import { Person } from "@/models/person"
import { Activity } from "@/models/activity"
import { ColumnHeader } from "@/models/column-header"

### Framework Includes ###
import ko from "knockout"

export class PeopleStore extends Store
   constructor: ->
      super()
      @vault = {
         "unassigned-people": null
         "off-people": null
         "assigned-people": null
         "get-person": null
         "person-activity": null
      }

   createPerson: (data, callback) ->
      assertArgs(arguments, Object, Function)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/people"
         method: "POST"
         data: data
      }, (err, data) =>
         return callback(err) if err
         callback(null, data)

   unlockUser: (personId, callback) ->
      assertArgs(arguments, String, Function)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/people/#{personId}/unlock"
         method: "POST"
      }, (err, success) =>
         return callback(err) if err
         callback(null, success)

   subscribeToPersonActivity: (listenerData, listenerNamespace, forceRequest, callback) ->
      [listenerData, listenerNamespace, forceRequest, callback] = assertArgs(arguments, optional(Object), String, optional(Boolean), Function)
      @getPersonActivity = ((message, listenerData, forceRequest) ->
         [message, listenerData, forceRequest] = assertArgs(arguments, optional(FanoutMessage), optional(Object), optional(Boolean))
         requestData = fanoutManager.getListenerData(listenerNamespace, FanoutManager.Channel.PERSON_ACTIVITY, {personId: listenerData.personId}) or listenerData

         return @requestPersonActivity_(requestData, listenerNamespace, callback) if forceRequest
         timestamp = if message? then message.timestamp else 0
         @maybeGetVaultData PeopleStore.ValutKey.PERSON_ACTIVITY, timestamp, (err, data) =>
            return @requestPersonActivity_(requestData, listenerNamespace, callback) if err == Store.Error.VAULT_OUTDATED
            return console.log "Error: ", err if err?
            callback(null, ko.unwrap(data))
            return fanoutManager.getSubscription(listenerData, listenerNamespace, FanoutManager.Channel.PERSON_ACTIVITY, {personId: listenerData.personId}, @getPersonActivity)
      ).bind(@)
      force = if forceRequest?  then forceRequest else false
      @getPersonActivity(listenerData, force)

   getFilteredPeople: (params, callback) ->
      assertArgs(arguments, Object, Function)
      companyId = authManager.companyId()
      groupId = authManager.selectedGroupId()
      params = UrlUtils.serializeParams(params)
      @makeRequest {
         url: "/api/companies/#{companyId}/groups/#{groupId}/people?#{params}"
         method: "GET"
      }, (err, result) =>
         return callback(err) if err
         data = {
            people: result.data.map (person) ->
               model = new Person(person)
               model['name'] = person.formatted_name if person.formatted_name?
               return model
            totalCount: result.total_count
         }
         callback(null, data)

   getAllAvailablePeopleForMessage: (params, callback) ->
      assertArgs(arguments, Object, Function)
      companyId = authManager.companyId()
      params = UrlUtils.serializeParams(params)
      # Using my-groups allows all results available to a user to come back.
      @makeRequest {
         url: "/api/companies/#{companyId}/groups/my-groups/people/messages?#{params}"
         method: "GET"
      }, (err, data) =>
         return callback(err) if err
         people = data.map (person) ->
            return new Person(person, true)
         callback(null, people)

   getFilteredPeopleForMessage: (params, callback) ->
      assertArgs(arguments, Object, Function)
      companyId = authManager.companyId()
      params = UrlUtils.serializeParams(params)
      groupId = authManager.selectedGroupId()
      @makeRequest {
         url: "/api/companies/#{companyId}/groups/#{groupId}/people/messages?#{params}"
         method: "GET"
      }, (err, data) =>
         return callback(err) if err
         people = data.map (person) ->
            return new Person(person, true)
         callback(null, people)

   getAbbrvPerson: (personId, callback) ->
      assertArgs(arguments, String, Function)
      companyId = authManager.companyId()
      groupId = authManager.selectedGroupId()
      @makeRequest {
         url: "/api/companies/#{companyId}/groups/#{groupId}/people/#{personId}/abbrv"
         method: "GET"
      }, (err, data) =>
         return callback(err) if err
         callback(null, new Person(data, true))

   requestPasswordReset: (email, callback) ->
      assertArgs(arguments, String, Function)
      @makeRequest {
         url: "/api/reset-password"
         method: "POST"
         data: {email: email}
      }, (err, success) =>
         return callback(err) if err
         callback(null, success)

   resetPassword: (resetId, password, callback) ->
      assertArgs(arguments, String, String, Function)
      @makeRequest {
         url: "/api/reset-password/#{resetId}"
         method: "POST"
         data: {password: password}
      }, (err, success) =>
         return callback(err) if err
         callback(null, success)

   resetPasswordInternally: (personId, password, newPassword, callback) ->
      assertArgs(arguments, String, String, String, Function)
      @makeRequest {
         url: "/api/people/#{personId}/reset-password/internal"
         method: "POST"
         data: {
            current_password: password
            new_password: newPassword
         }
      }, (err, success) =>
         return callback(err) if err
         callback(null, success)

   updateColumnHeaders: (listViewType, columnData, callback) ->
      data = columnData.map (columnHeader) ->
         header = {
            key: columnHeader.key()
            sequence: columnHeader.sequence()
            sortable: columnHeader.sortable()
            width: ko.unwrap(columnHeader.width) or null
            sub_properties: columnHeader.subProperties().map (item) ->
               return {
                  ...item,
                  enabled: ko.unwrap(item.enabled)
                  width: ko.unwrap(item.width) or null
               }
            meta: columnHeader.meta() or null
         }
         header['name'] = columnHeader.name() if columnHeader.name?
         header['key_id'] = columnHeader.keyId() if columnHeader.keyId()?
         return header
      @makeRequest {
         url: "/api/people/#{authManager.authedUser().id}/column-headers/#{listViewType}"
         method: "POST"
         data: {column_headers: data}
      }, (err, success) =>
         return callback(err) if err
         callback(null, success)

   resetColumnHeaders: (listViewType, callback) ->
      @makeRequest {
         url: "/api/people/#{authManager.authedUser().id}/column-headers/#{listViewType}/reset"
         method: "DELETE"
      }, (err, success) =>
         return callback(err) if err
         callback(null, success)

   getColumnHeaders: (listViewType, callback) ->
      assertArgs(arguments, String, Function)
      @makeRequest {
         url: "/api/people/#{authManager.authedUser().id}/column-headers/#{listViewType}"
         method: "GET"
      }, (err, data) =>
         return callback(err) if err
         headers =  {
            savedColumns: data.saved_columns.map (header) ->
               return new ColumnHeader(header)
            customColumns: data.custom_columns.map (header) ->
               header['sortable'] = true unless header.meta.field_type == "multi-select"
               return new ColumnHeader(header)
         }
         callback(null, headers)

   getPersonQrInfo: (companyQrId, personQrId, callback) ->
      assertArgs(arguments, String, String, Function)
      @makeRequest {
         url: "/ex-api/qr-companies/#{companyQrId}/qr-people/#{personQrId}"
         method: "GET"
      }, (err, data) =>
         return callback(err) if err
         callback(null, data)

   getUsersHomeInfo: (callback) ->
      assertArgs(arguments, Function)
      companyId = authManager.companyId()
      @makeRequest {
         url: "/api/companies/#{companyId}/home-info"
         method: "GET"
      }, (err, data) =>
         return callback(err) if err
         formattedData = {
            companyName: data.company_name
            logoUrl: data.logo_url
            groupCount: data.group_count
            peopleCount: data.people_count
            projectCount: data.project_count
         }
         callback(null, formattedData)

   # async
   getSinglePerson: (personId) ->
      companyId = authManager.companyId()
      groupId = authManager.selectedGroupId()
      response = await (await fetch("/api/companies/#{companyId}/groups/#{groupId}/people/#{personId}",
      {
         method: 'GET',
      })).json()
      return new Person(response)

   requestPersonActivity_: (requestData, listenerNamespace, callback) =>
      query = {
         entity_id: requestData.personId,
         entity_type: LegacyActivityStore.ActivityEntityType.PEOPLE
         limit: 25
      }
      if requestData.category != "all"
         query['included_categories'] = [requestData.category]
      try
         results = await ActivityStore.findActivityPaginated(query).payload
         activity = []
         for item from results.data
            activity.push(new Activity(item))
         @maybeSetVaultData(PeopleStore.ValutKey.PERSON_ACTIVITY, activity, Date.now(), callback)
      catch err
         return console.log "Error: ", err

      fanoutManager.getSubscription(requestData, listenerNamespace, FanoutManager.Channel.PERSON_ACTIVITY, {personId: requestData.personId}, @getPersonActivity)

PeopleStore.ValutKey = {
   UNASSIGNED_PEOPLE: "unassigned-people"
   OFF_PEOPLE: "off-people"
   ASSIGNED_PEOPLE: "assigned-people"
   GET_PERSON: "get-person"
   PERSON_ACTIVITY: "person-activity"
}

PeopleStore.ListViewType = {
   People: "people"
   Projects: "project"
   Requests: "request"
   Assignments: "assignment"
   AssignmentsList: "assignments-list"
   TimeOff: "time-off"
}

export peopleStore = new  PeopleStore()