export enum FindProjectsSortBy {
   ADDRESS_1 = "address_1",
   ADDRESS_2 = "address_2",
   BID_RATE = "bid_rate",
   CITY_TOWN = "city_town",
   COUNTRY = "country",
   CREATED_AT = "created_at",
   CUSTOM_FIELD = "custom_field",
   CUSTOMER_NAME = "customer_name",
   DAILY_END_TIME = "daily_end_time",
   DAILY_START_TIME = "daily_start_time",
   EST_END_DATE = "est_end_date",
   HAS_PROCORE_MAPPING = "has_procore_mapping",
   ID = "id",
   NAME = "name",
   NUMBER = "job_number",
   PERCENT_COMPLETE = "percent_complete",
   START_DATE = "start_date",
   STATE_PROVINCE = "state_province",
   STATUS = "status",
   TAG_INSTANCES = "tag_instances",
   TIMEZONE = "timezone",
   TYPE = "type",
   WAGE_OVERRIDES = "wage_overrides",
   ZIP_CODE = "zip_code",
}

export enum FieldMapping {
   project_hash = "job_number",
   city = "city_town",
   state = "state_province",
   postal = "zipcode",
   groups = "group_ids",
   status = "status",
   start_date = "start_date",
   est_end_date = "est_end_date",
   daily_start_time = "daily_start_time",
   daily_end_time = "daily_end_time",
   timezone = "timezone",
   bid_rate = "bid_rate",
   percent_complete = "percent_complete",
}
