import React, { useCallback, useState } from "react";
import {
   Dropzone,
   Flex,
   Thumbnail,
   spacing,
   useDropzone,
   useI18nContext,
} from "@procore/core-react";
import { CloudinaryStore } from "@/stores/cloudinary-store.core";
import { getFileDimensions, resizeFile, readFile } from "./helpers";

export const PHOTO_ACCEPTED_FILE_TYPES = [".jpg", ".jpeg", ".png", ".x-png", ".gif", ".bmp"];
export const PHOTO_MIN_DIMENSION = 104;
export const PHOTO_MAX_DIMENSION = 1080;

const PhotoDropzone = (props: any) => {
   return (
      <Flex direction="column" alignItems="center" justifyContent="center">
         <Thumbnail
            src={props.thumbnail ?? ""}
            size="lg"
            label={props.label}
            clickable
            onClick={props.open}
            variant="image"
         />
      </Flex>
   );
};
export const PhotoUploader = React.memo(
   (props: { imgUrl: string; onUploadPhoto: (url: string) => void }) => {
      const I18n = useI18nContext();
      const [hasError, setHasError] = useState<boolean>(false);
      const [thumbnail, setThumbnail] = useState<string>(props.imgUrl);

      const onDrop = useCallback(
         async (acceptedFiles: File[]) => {
            if (acceptedFiles?.length) {
               const inputFile = await readFile(acceptedFiles?.[0]);
               const { width, height } = await getFileDimensions(inputFile as string);

               const isInvalidSize =
                  width > PHOTO_MAX_DIMENSION ||
                  height > PHOTO_MAX_DIMENSION ||
                  width < PHOTO_MIN_DIMENSION ||
                  height < PHOTO_MIN_DIMENSION;

               setHasError(isInvalidSize);
               if (isInvalidSize) {
                  setThumbnail("");
                  return;
               }

               const resizedDataUrl = await resizeFile(
                  acceptedFiles?.[0],
                  PHOTO_MIN_DIMENSION,
                  PHOTO_MIN_DIMENSION,
                  "base64",
               );

               setThumbnail(resizedDataUrl as string);
               const uploadResponse = await CloudinaryStore.uploadPhoto(resizedDataUrl as string)
                  .payload;

               if ("error" in uploadResponse) {
                  console.error(uploadResponse.error.message);
                  return;
               }

               props.onUploadPhoto(uploadResponse.secure_url);
            }
         },
         [props.onUploadPhoto],
      );

      const dropzone = useDropzone({
         multiple: false,
         maxFileNumber: 1,
         accept: PHOTO_ACCEPTED_FILE_TYPES,
         preventDropOnDocument: true,
         onDrop,
      });

      const handleDropError = useCallback((error: any) => {
         if (error === "reset") {
            setHasError(false);
         }
      }, []);

      return (
         <Dropzone
            {...dropzone}
            {...(hasError
               ? {
                    dropError: {
                       type: "MAX_FILE_SIZE",
                       title: "",
                       message: I18n.t(
                          "views.company.workforce_planning.projects.select_image.dimenssionError",
                          {
                             min: PHOTO_MIN_DIMENSION,
                             max: PHOTO_MAX_DIMENSION,
                          },
                       ),
                    },
                 }
               : {})}
            dispatchDropError={handleDropError}
            isIconVisible={false}
            rootProps={{
               style: {
                  padding: 0,
                  margin: 0,
                  width: "auto",
                  height: 104,
                  border: 0,
                  marginTop: `${spacing.lg}px`,
               },
            }}
            contentRenderer={(props) => (
               <PhotoDropzone
                  {...props}
                  thumbnail={thumbnail}
                  label={I18n.t("views.company.workforce_planning.projects.select_image.label")}
               />
            )}
         />
      );
   },
);
