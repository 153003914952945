import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import {
   Assignment2Store,
   Assignment2Store as AssignmentStore,
} from "@/stores/assignment-2-store.core";
import { SettingsStore } from "@/stores/settings-store.core";
import type {
   GanttOptions,
   GanttProject,
   RawGanttData,
   Category,
   GroupableTask,
   Task,
   GroupedTasks,
   TaskLookup,
   noSubcategoryTasks,
   ganttFilterType,
} from "./prop-types";
import { TaskType } from "./prop-types";
import { getAttachedDate, getDetachedDay } from "@laborchart-modules/common/dist/datetime";
import { Box, DetailPage, Spinner } from "@procore/core-react";

import { GanttControlPanel } from "./gantt-control-panel";
import { useGroupContext } from "@/react/providers/group-context-provider";
import type { BryntumGanttProps } from "@bryntum/gantt-react";
import { BryntumGantt } from "@bryntum/gantt-react";
import "@bryntum/gantt/gantt.stockholm.css";
import type { Gantt, Panel, Store, TaskModelConfig, ViewPreset } from "@bryntum/gantt";
import { TaskModel } from "@bryntum/gantt";
import { DateHelper, PresetManager, ProjectModel, StringHelper } from "@bryntum/gantt";
import { timeFormat, timeParse } from "d3-time-format";
import {
   ProjectTearsheetProvider,
   // useProjectTearsheet,
} from "../tearsheets/project/project-tearsheet";
import { calendarsData, getCalendarName } from "./gantt-calendar";
import { defaultStore } from "@/stores/default-store";
import { CaretsInVertical, CaretsOutVertical } from "@procore/core-icons";
import { renderToString } from "react-dom/server";
import type { Filter } from "@/lib/components/chip-filter/chip-filter";
import { GanttFilterPanel, statuses } from "./gantt-filter-panel";
import {
   getGanttConfigurePanelValues,
   updateGanttConfigurePanelLocalStorage,
} from "./gantt-config-panel";
import "./gantt-container.css";

export const GanttContainer = () => {
   return (
      <ProjectTearsheetProvider projectsTableApi={undefined}>
         <Container />
      </ProjectTearsheetProvider>
   );
};

export const INITIAL_GANTT_FILTER: ganttFilterType = {
   jobTitles: [],
   projectStatuses: [],
   onlyShow: [],
   hideEmptyProject: false,
};

const Container = () => {
   const groupId = localStorage.getItem("selectedGroupId") ?? useGroupContext().groupId;
   const ganttFilterSaved: ganttFilterType | null = JSON.parse(
      localStorage.getItem("gantt-filter")!,
   );
   const [ganttData, setGanttData] = useState<RawGanttData>();
   const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

   /* For more information on what is inside of the PresetManager, you can view the file `node_modules/@bryntum/gantt/gantt.module.js`
      and search for line "var PresetManager = class extends PresetStore"

      You can also check the Bryntum docs here for examples on how to use or alter this PresetManager or create custom ViewPreset's:
         - https://bryntum.com/products/gantt/docs/api/Scheduler/preset/PresetManager
         - https://bryntum.com/products/gantt/docs/api/Scheduler/preset/ViewPreset
   */
   const availablePresets = (PresetManager.records.slice(3, 10) as ViewPreset[]).map(
      (preset: ViewPreset) => {
         return {
            id: "my_" + preset.id,
            base: preset.id,
            // mainUnit and defaultSpan are used to dynamically set the viewing range of our gantt
            mainUnit: preset.mainUnit,
            defaultSpan: preset.defaultSpan,
            // timeResolution makes drag-and-drop snaps to your unit
            timeResolution: {
               unit: "day",
               increment: 1,
            },
         };
      },
   ) as ViewPreset[];

   const viewPreset =
      availablePresets.find(
         (preset: any) => preset.base === localStorage.getItem("gantt-view-preset"),
      ) ?? availablePresets.at(-1);

   const mainUnitInMs = (() => {
      const ONE_DAY = 1000 * 60 * 60 * 24;
      switch (viewPreset?.mainUnit) {
         case "day":
            return ONE_DAY;
         case "week":
            return ONE_DAY * 7;
         case "month":
            return ONE_DAY * 30;
         case "year":
            return ONE_DAY * 365;
         default:
            return ONE_DAY * 365;
      }
   })();

   const mainUnitOffset = mainUnitInMs * (viewPreset?.defaultSpan ?? 1);

   const START_DATE = new Date();
   START_DATE.setTime(START_DATE.getTime() - mainUnitOffset * 4);
   const END_DATE = new Date();
   END_DATE.setTime(END_DATE.getTime() + mainUnitOffset * 4);

   const [currentGanttRange, setCurrentGanttRange] = useState({
      startDay: getDetachedDay(START_DATE),
      endDay: getDetachedDay(END_DATE),
   });
   const earliestStartDateViewed = useRef<number>(currentGanttRange.startDay);
   const latestEndDateViewed = useRef<number>(currentGanttRange.endDay);

   const [search, setSearch] = useState<string>(localStorage.getItem("gantt-search") ?? "");
   const prevSearch = useRef<string>(search);
   const [paidShiftHours, setPaidShiftHours] = useState(8); // Paid shift is 8 hours by default
   const fetchTimeoutId = useRef<number>(NaN);
   const [ganttFilter, setGanttFilter] = useState<ganttFilterType>(
      ganttFilterSaved ?? { ...INITIAL_GANTT_FILTER, projectStatuses: [statuses[0]] }, // Select active projects by default
   );

   useEffect(() => {
      localStorage.setItem("gantt-filter", JSON.stringify(ganttFilter));
      fetchData();
   }, [ganttFilter]);

   // const getDateFilterFormatted = (date: string): string => {
   //    const dateObj = new Date(date);
   //    const monthStr = `${dateObj.getMonth() < 10 ? "0" : ""}${dateObj.getMonth()}`;
   //    const dayStr = `${dateObj.getDate() < 10 ? "0" : ""}${dateObj.getDate()}`;
   //    const yearStr = `${dateObj.getFullYear()}`;

   //    return `${yearStr}${monthStr}${dayStr}`;
   // };

   const getFilterFormatted = (filter: ganttFilterType): Record<string, Filter[]> => {
      const appliedFilters: Record<string, Filter[]> = {};

      if (filter.jobTitles?.length)
         appliedFilters["Job Titles"] = filter.jobTitles.map((jobTitle) => {
            return {
               property: "position_id",
               negation: false,
               filterName: "Job Titles",
               value: jobTitle.id,
            } as Filter;
         });

      if (filter.projectStatuses?.length)
         appliedFilters["Status"] = filter.projectStatuses.map((status) => {
            return {
               property: "status",
               negation: false,
               filterName: "Status",
               value: status.name,
            } as Filter;
         });

      if (filter.onlyShow?.length)
         appliedFilters["Only Show"] = filter.onlyShow.map((value) => {
            return {
               property: "only_show",
               negation: false,
               filterName: "Only Show",
               value,
            } as Filter;
         });

      if (filter.hideEmptyProject)
         appliedFilters["Hide Empty Projects"] = [
            {
               property: "only_projects_with_data",
               negation: false,
               filterName: "Hide Empty Projects",
               value: true,
            } as Filter,
         ];

      // if (filter.startDate?.qualifier && filter.startDate?.date.length)
      //    appliedFilters["Start Date"] = [
      //       {
      //          property: "start_date",
      //          negation: false,
      //          filterName: "Start Date",
      //          value: getDateFilterFormatted(filter.startDate.date),
      //          classifier: filter.startDate.qualifier,
      //       } as Filter,
      //    ];

      // if (filter.endDate?.qualifier && filter.endDate?.date.length)
      //    appliedFilters["Est End Date"] = [
      //       {
      //          property: "est_end_date",
      //          negation: false,
      //          filterName: "Est End Date",
      //          value: getDateFilterFormatted(filter.endDate.date),
      //          classifier: filter.endDate.qualifier,
      //       } as Filter,
      //    ];

      return appliedFilters;
   };

   const fetchData = async () => {
      ganttRef.current?.instance.mask({
         mode: "bright",
         cls: "gantt-loading-mask",
         html: (
            <div style={{ height: "100%", width: "100%", display: "grid", placeItems: "center" }}>
               <Spinner loading={true} />
            </div>
         ),
      });

      const startDay = earliestStartDateViewed.current;
      const endDay = latestEndDateViewed.current;

      const params: GanttOptions = {
         skip: 0,
         projectSort: "name",
         group_id: groupId,
         startDay,
         endDay,
         search: search,
      };

      const appliedFilters = getFilterFormatted(ganttFilter);
      params.filters = appliedFilters;

      const rawGanttData = await AssignmentStore.getRawProjectsGanttData(params);

      ganttRef.current?.instance.unmask();
      setGanttData(rawGanttData);
      setIsInitialLoad(false);
   };

   useEffect(() => {
      const fetchCostingData = async () => {
         try {
            const costingInfo = await SettingsStore.getCostingInfo()?.payload;

            setPaidShiftHours(costingInfo?.data?.paid_shift_hours ?? 8);
         } catch (e) {
            console.error(e);
         }
      };

      fetchCostingData();
   }, []);

   useEffect(() => {
      // Making sure we don't call fetchData on the for this effect on initial page load. We only want to execute this
      // after the user themselves updates the input value.
      if (search != prevSearch.current) {
         prevSearch.current = search;
         localStorage.setItem("gantt-search", search ?? "");
         fetchData();
      }
   }, [search]);

   useEffect(() => {
      // clearing interval if this event fires again before 500ms to achieve a debounce effect
      clearTimeout(fetchTimeoutId.current);
      const currentStartDate = currentGanttRange.startDay;
      const currentEndDate = currentGanttRange.endDay;

      const hasViewRangeExtended = () => {
         // If we've already zoomed out past the starting or ending point of the current range then there's no need to fetch
         // more data because we've already loaded a larger set of data which includes the current range.
         let rangeViewExtended = false;

         if (currentStartDate < earliestStartDateViewed.current) {
            rangeViewExtended = true;
            earliestStartDateViewed.current = currentStartDate;
         }
         if (currentEndDate > latestEndDateViewed.current) {
            rangeViewExtended = true;
            latestEndDateViewed.current = currentEndDate;
         }

         return rangeViewExtended;
      };

      // Wait until we go 500ms without receiving another zoom event to check if we need to fetch more data (debounced for performance)
      fetchTimeoutId.current = window.setTimeout(() => {
         if (hasViewRangeExtended()) {
            fetchData();
         }
      }, 500);
   }, [currentGanttRange]);

   const defaultDateFormat = defaultStore.getDateFormat();
   const getDatesRangeString = useCallback(
      (startDate: Date, endDate: Date, startTime?: string, endTime?: string) => {
         return `${DateHelper.format(startDate, defaultDateFormat)} ${
            startTime ?? ""
         } - ${DateHelper.format(endDate, defaultDateFormat)} ${endTime ?? ""}`;
      },
      [defaultDateFormat],
   );
   // Function to parse "HH:MM:SS" strings into Date objects
   const parseTimeString = useCallback((timeString: string): Date => {
      const [hours, minutes, seconds] = timeString.split(":").map(Number);
      const date = new Date();
      date.setHours(hours, minutes, seconds, 0);
      return date;
   }, []);
   // Function that returns the percentage allocation of the assignment/request
   const getPercentAllocation = useCallback(
      (projectJobNumber: string, id: string): number | null => {
         const project = ganttData?.projects.find(
            (project) => project.job_number === projectJobNumber,
         );

         const task =
            project?.assignments.find((assignment) => assignment.id === id) ??
            project?.requests.find((request) => request.id === id);

         return task?.percent_allocated ?? null;
      },
      [ganttData],
   );
   const getTaskTimeAllocation = useCallback(
      (originalData: any): string => {
         const { startTime, endTime, projectJobNumber, id } = originalData;

         if (!startTime || !endTime) {
            return `${getPercentAllocation(projectJobNumber, id)}`;
         } else {
            const startTime = formatTime(parseTimeString(originalData.startTime));
            const endTime = formatTime(parseTimeString(originalData.endTime));

            return `${startTime} - ${endTime}`;
         }
      },
      [ganttData],
   );
   // Function that returns custom tooltip template for tasks
   const tooltipTemplateCallback = useCallback(
      (taskRecord: any, startDate: Date, endDate: Date): string => {
         const originalData = taskRecord.originalData;
         const { startTime, endTime } = originalData;

         let isAssignmentOrRequest = false,
            taskTimeAllocation = "";

         if (originalData.type === TaskType.ASSIGNMENT || originalData.type === TaskType.REQUEST) {
            isAssignmentOrRequest = true;
            taskTimeAllocation = getTaskTimeAllocation(originalData);
         }

         return `<div>
         <div style="text-align: center">${StringHelper.encodeHtml(
            getDatesRangeString(startDate, endDate),
         )}</div>
         ${
            isAssignmentOrRequest
               ? `<div style="text-align: center">
               ${StringHelper.encodeHtml(
                  `${startTime && endTime ? taskTimeAllocation : `${taskTimeAllocation}%`}`,
               )}
            </div>`
               : ""
         }
         </div>`;
      },
      [ganttData],
   );
   /**
    * This function returns a pill displaying the number of open requests that appear on the project's bar
    */
   const requestsNumberPill = useCallback((requestsNumber: number): string => {
      if (!requestsNumber) return "";

      const pillsHeader = `${requestsNumber} open request${requestsNumber > 1 ? "s" : ""}`;

      return `<div class='ganttProjectRequestsPill'>${pillsHeader}</div>`;
   }, []);

   /* istanbul ignore next */
   function lightenHexColor(hex: string, amount: number) {
      if (!hex) return;

      if (hex.indexOf("#") !== 0) {
         hex = "#" + hex;
      }

      const num = parseInt(hex.slice(1), 16);
      let r = num >> 16;
      let g = (num >> 8) & 0x00ff;
      let b = num & 0x0000ff;

      // Scale each component by the percentage amount towards 255
      r = Math.floor(r + (255 - r) * amount);
      g = Math.floor(g + (255 - g) * amount);
      b = Math.floor(b + (255 - b) * amount);

      // Combine back into a hex string
      return "#" + ((r << 16) | (g << 8) | b).toString(16).padStart(6, "0");
   }

   // const { dispatch: projectTearsheetDispatch } = useProjectTearsheet();
   const dateFormat = timeFormat("%m/%d/%Y");
   const formatTime = timeFormat("%-I:%M %p"); // formats the time to "h:mm am/pm" (eg. 1:30 pm)
   let lastSortEventTarget: null | HTMLElement = null;

   const hideWeekends = (gantt: Gantt, value: boolean) => {
      const { timeAxis } = gantt;

      gantt.element.classList.toggle("b-hide-weekends", value);

      gantt.runWithTransition(() => {
         if (value) {
            timeAxis.filterBy(
               (tick: any) =>
                  timeAxis.unit !== "day" ||
                  (tick.startDate.getDay() !== 6 && tick.startDate.getDay() !== 0),
            );
         } else {
            timeAxis.clearFilters();
         }
      });
   };

   const ganttConfig: BryntumGanttProps = useMemo(() => {
      return {
         startDate: getAttachedDate(earliestStartDateViewed.current),
         endDate: getAttachedDate(latestEndDateViewed.current),
         visibleDate: new Date(),
         presets: availablePresets,
         viewPreset: viewPreset,
         autoAdjustTimeAxis: false,
         rowHeight: getGanttConfigurePanelValues().rowHeight,
         // infiniteScroll: true,
         columns: [
            {
               alwaysClearCell: false, // v6 defaults this to true, so we're explicitly setting back to false to maintain prior behavior for now
               type: "name",
               text: "Project Name",
               field: "name",
               width: 250,
               htmlEncode: false,
               // This headerRenderer function expects you to return a string, so we're using the renderToString method to generate
               // static HTML string of our React components. This static HTML does not include any JavaScript or interactivity,
               // such as event handlers like onClick, so we're using a setTimeout to defer the execution of the querySelectors
               // until after the current callstack is clear (ie. until after the header finishes rendering). This async defermnet
               // ensures that the DOM elements are ready and available when we attempt to get them with querySelector.
               headerRenderer: ({ headerElement, column }) => {
                  // This updates the lastSortEventTarget value BEFORE the beforeSort listener executes.
                  headerElement.addEventListener("click", (e) => {
                     lastSortEventTarget = e.target as HTMLElement;
                  });

                  // These go in the setTimeout to make sure that the children of the headerElement have time to render first.
                  setTimeout(() => {
                     const expandAllIcon = headerElement.querySelector(
                        ".gantt-expand-all-icon",
                     ) as HTMLElement;
                     const collapseAllIcon = headerElement.querySelector(
                        ".gantt-collapse-all-icon",
                     ) as HTMLElement;

                     expandAllIcon?.addEventListener("click", () => {
                        expandAllIcon.style.display = "none";
                        collapseAllIcon.style.display = "block";
                        column.grid.collapseAll();
                        localStorage.setItem("gantt-expanded", "false");
                     });
                     collapseAllIcon?.addEventListener("click", () => {
                        expandAllIcon.style.display = "block";
                        collapseAllIcon.style.display = "none";
                        column.grid.expandAll();
                        localStorage.setItem("gantt-expanded", "true");
                     });
                  }, 0);

                  return `
               ${renderToString(<CaretsOutVertical className="gantt-expand-all-icon" />)}
               ${renderToString(
                  <CaretsInVertical
                     className="gantt-collapse-all-icon"
                     style={{ display: "none" }}
                  />,
               )}
               <span>${column.text}</span>`;
               },
               // renderer: ({ record }: any) => {
               //    return record.name; // This is put here for now until the completion of WFP-3181

               // console.info("record:", record);
               // if (record.originalData.type !== "project") return record.name;

               // return (
               //    <Link
               //       onClick={() => {
               //          const projectId = record.id;
               //          return projectTearsheetDispatch({ type: "open-project-detail", projectId });
               //       }}
               //    >
               //       {record.name}
               //    </Link>
               // );
               // },
            },
            {
               alwaysClearCell: false, // v6 defaults this to true, so we're explicitly setting back to false to maintain prior behavior for now
               type: "name",
               text: "Job Title",
               field: "jobTitleName",
               headerRenderer: ({ headerElement, column }) => {
                  // This updates the lastSortEventTarget value BEFORE the beforeSort listener executes.
                  headerElement.addEventListener("click", (e) => {
                     lastSortEventTarget = e.target as HTMLElement;
                  });

                  return `<span>${column.text}</span>`;
               },
            },
         ],
         barMargin: 4,
         height: "calc(100vh - 250px)",
         //#region Features
         strips: {
            right: {
               type: "panel",
               dock: "right",
               header: false,
               collapsible: true,
               ui: "procore",
               cls: "b-sidebar",
               scrollable: { overflowY: true },
               collapsed: true,
               defaults: {
                  labelPosition: "above",
                  width: "15em",
               },
               items: [
                  {
                     tag: "span",
                     html: "Configure Gantt",
                  },
                  {
                     type: "slidetoggle",
                     label: "Project Information",
                     labelPosition: "after", // we have some custom css to support the 'after' styling
                     value: getGanttConfigurePanelValues().showProjectInformation,
                     onChange: ({ value }) => {
                        updateGanttConfigurePanelLocalStorage("showProjectInformation", value);
                        ganttRef!.current!.instance.element.classList.toggle(
                           "b-hide-project-information",
                           !value,
                        );
                     },
                  },
                  {
                     type: "slidetoggle",
                     label: "Allocation Information",
                     labelPosition: "after", // we have some custom css to support the 'after' styling
                     value: getGanttConfigurePanelValues().showAllocationInformation,
                     onChange: ({ value }) => {
                        updateGanttConfigurePanelLocalStorage("showAllocationInformation", value);
                        ganttRef!.current!.instance.element.classList.toggle(
                           "hide-allocation-information",
                           !value,
                        );
                     },
                  },
                  {
                     type: "slidetoggle",
                     label: "Hide weekends",
                     labelPosition: "after", // we have some custom css to support the 'after' styling
                     value: getGanttConfigurePanelValues().hideWeekends,
                     onChange: ({ value }) => {
                        updateGanttConfigurePanelLocalStorage("hideWeekends", value);
                        hideWeekends(ganttRef!.current!.instance!, value);
                     },
                  },
                  {
                     ref: "rowHeightSlider",
                     type: "slider",
                     label: "Row height",
                     labelPosition: "above",
                     min: 25,
                     max: 45,
                     showValue: false,
                     showTooltip: true,
                     value: getGanttConfigurePanelValues().rowHeight,
                     onInput: ({ value }) => {
                        ganttRef!.current!.instance.rowHeight = value;
                     },
                     onChange: ({ value }) => {
                        updateGanttConfigurePanelLocalStorage("rowHeight", value);
                        // TODO: Whenever this bug-fix gets released by Bryntum, we should be able to remove the below here
                        // Bug ticket: https://github.com/bryntum/support/issues/9698
                        ganttRef!.current!.instance.features.taskNonWorkingTime.disabled = true;
                        ganttRef!.current!.instance.features.taskNonWorkingTime.disabled = false;
                     },
                  },
                  {
                     ref: "borderRadiusSlider",
                     type: "slider",
                     label: "Task border radius",
                     labelPosition: "above",
                     min: 0,
                     value: getGanttConfigurePanelValues().taskBorderRadius,
                     max: 20,
                     showValue: false,
                     showTooltip: true,
                     onInput: ({ value }) => {
                        ganttRef!.current!.instance.element.style.setProperty(
                           "--task-border-radius",
                           `${value}px`,
                        );
                     },
                     onChange: ({ value }) => {
                        updateGanttConfigurePanelLocalStorage("taskBorderRadius", value);
                     },
                  },
               ],
            },
         },
         headerMenuFeature: false,
         taskMenuFeature: false,
         timeAxisHeaderMenuFeature: false,
         sortFeature: "name",
         timeRangesFeature: {
            showCurrentTimeLine: {
               name: "Today",
               cls: "gantt-current-timeline",
            },
            tooltipTemplate: ({ timeRange }) => {
               const dateString = DateHelper.format(
                  new Date(timeRange.startDate),
                  defaultStore.getDateFormat(),
               );
               return dateString + " - " + new Date().toLocaleTimeString();
            },
         },
         taskNonWorkingTimeFeature: true,
         taskTooltipFeature: {
            maxWidth: "unset",
            minWidth: "400px",
            width: "max-content",
            style: "border-radius: 6px;",
            template: ({ taskRecord }: any) => {
               const originalData: any = taskRecord.originalData;

               if (originalData.type === TaskType.PROJECT) {
                  const startTime = formatTime(parseTimeString(originalData.dailyStartTime));
                  const endTime = formatTime(parseTimeString(originalData.dailyEndTime));

                  return StringHelper.xss`
                  <div class='gantt-project-tooltip'>
                     <div class='gantt-project-tooltip__header'>
                        <span class="gantt-project-tooltip-color-icon" style="color: ${
                           originalData.projectColor
                        }"></span>
                        <b>${taskRecord.name} ${
                     originalData.projectJobNumber ? `(${originalData.projectJobNumber})` : ""
                  }</b>
                     </div>
                     <hr>
                     <div class='gantt-project-tooltip__footer'>
                        <div class='flex'>
                           <div class='gantt-project-tooltip-date'>
                              <b>Dates</b><br>
                              ${dateFormat(new Date(taskRecord.startDate))} - ${dateFormat(
                     new Date(taskRecord.endDate),
                  )}
                           </div>
                           <div class='gantt-project-tooltip-daily'>
                              <b>Times</b><br>
                              ${startTime} - ${endTime}
                           </div>
                        </div>
                     </div>
                  </div>
               `;
               } else if (
                  originalData.type === TaskType.ASSIGNMENT ||
                  originalData.type === TaskType.REQUEST
               ) {
                  const { startTime, endTime } = originalData;
                  const isTaskWithTime = startTime && endTime;
                  const taskTimeAllocation = getTaskTimeAllocation(originalData);
                  const italicize = originalData.type === TaskType.REQUEST;

                  const workingDayClass = (day: number) =>
                     originalData.workDays?.[day] ? "bold" : "thin";

                  return StringHelper.xss`
                  <div class='gantt-project-tooltip'>
                     <div class='gantt-project-tooltip__header'>
                           <span class="gantt-project-tooltip-color-icon" style="color: ${
                              originalData.jobTitleColor
                           }"></span>
                           <b style='font-style: ${italicize ? "italic" : "normal"}'>${
                     taskRecord.name
                  }</b>
                           <span class='job-title'>${originalData.jobTitleName}</span>
                     </div>
                     <hr>
                     <div class='gantt-project-tooltip__footer'>
                        <div>
                           <b>${originalData.projectName} (${originalData.projectJobNumber})</b>
                        </div>
                        <div class='flex'>
                           <div class='gantt-project-tooltip-date'>
                              <b>Dates</b><br>
                              ${dateFormat(new Date(taskRecord.startDate))} - ${dateFormat(
                     new Date(taskRecord.endDate),
                  )}
                           </div>
                           <div class='gantt-project-tooltip-daily'>
                              <b>${startTime && endTime ? "Times" : "Assignment Allocation"}</b><br>
                              ${
                                 isTaskWithTime
                                    ? taskTimeAllocation
                                    : `${taskTimeAllocation}% (${
                                         (paidShiftHours * Number(taskTimeAllocation)) / 100
                                      } hours)`
                              }
                           </div>
                        </div>
                        <div>
                           <div class='gantt-project-tooltip-date'>
                              <b>Work Days</b><br>
                              <div class='work-days'>
                                 <div class='${workingDayClass(0)}'>Su</div>
                                 <div class='${workingDayClass(1)}'>M</div>
                                 <div class='${workingDayClass(2)}'>Tu</div>
                                 <div class='${workingDayClass(3)}'>W</div>
                                 <div class='${workingDayClass(4)}'>Th</div>
                                 <div class='${workingDayClass(5)}'>F</div>
                                 <div class='${workingDayClass(6)}'>Sa</div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               `;
               } else if (originalData.type === "category" || originalData.type === "subcategory") {
                  return StringHelper.xss`
                  <div class='gantt-project-tooltip'>
                     <div class='gantt-project-tooltip__header'>
                        <span class="gantt-project-tooltip-color-icon" style="color: ${
                           originalData.projectColor
                        }"></span>
                        <b>${originalData.projectName} (${originalData.projectJobNumber})</b>
                     </div>
                     <hr>
                     <div class='gantt-project-tooltip__footer'>
                        <div>
                           <b>${originalData.categoryName ? originalData.categoryName + ", " : ""}${
                     taskRecord.name
                  }</b>
                        </div>
                        <div class='gantt-project-tooltip-date'>
                           <b>Dates</b><br>
                           ${dateFormat(new Date(taskRecord.startDate))} - ${dateFormat(
                     new Date(taskRecord.endDate),
                  )}
                        </div>
                     </div>
                  </div>
               `;
               } else {
                  return "";
               }
            },
         },
         treeFeature: true,
         taskDragFeature: {
            tooltipTemplate({ startDate, endDate, taskRecord }) {
               return tooltipTemplateCallback(taskRecord, startDate, endDate);
            },
            tip: {
               htmlCls: "taskDragTooltipWrapper",
               bodyCls: "taskDragTooltip",
            },
         },
         taskResizeFeature: {
            tooltipTemplate({ startDate, endDate, record }) {
               // For somereason the taskDragFeature respects the fact that our endDate is set to 11:59 PM of it's proper day, but this taskResizeFeature
               // is rounding up to midnight of the next day! To safely correct this, we're simply subtracting one minute from whatever the endDate
               // received here is. Now if Bryntum happens to fix this bug it will just decrement to 11:58 PM of the proper day and that's no big deal at all.
               // As long as it's at the end of the proper day, we're good!
               endDate.setMinutes(endDate.getMinutes() - 1);

               return tooltipTemplateCallback(record, startDate, endDate);
            },
            tip: {
               htmlCls: "taskDragTooltipWrapper resize",
               bodyCls: "taskDragTooltip",
            },
         },
         percentBarFeature: false,
         projectLinesFeature: false,
         dependenciesFeature: false,
         indicatorsFeature: false,
         progressLineFeature: false,
         baselinesFeature: false,
         //#endregion Features
         //#region Events
         onRenderRows: (event) => {
            event.source.element.style.setProperty(
               "--task-border-radius",
               `${getGanttConfigurePanelValues().taskBorderRadius}px`,
            );
            event.source.element.style.setProperty(
               "--row-height",
               `${getGanttConfigurePanelValues().rowHeight}px`,
            );
            hideWeekends(event.source as Gantt, getGanttConfigurePanelValues().hideWeekends);

            // The purpose of this beforeSort listener is to prevent sorting when the column header is clicked,
            // and only allow sorting when the actual sorting icon is clicked in the header.
            ((event.source as Gantt).store as Store).on("beforeSort", () => {
               if (lastSortEventTarget?.nodeName == "path") {
                  lastSortEventTarget = lastSortEventTarget.parentElement as HTMLElement;
               }

               // If the target is an svg, then we would need to check the className.baseVal to get the string, otherwise className returns the string
               const targetClass =
                  (lastSortEventTarget?.className as any)?.baseVal ??
                  lastSortEventTarget?.className;
               if (
                  targetClass?.includes("b-sort-icon") == false &&
                  lastSortEventTarget?.tagName != "SPAN"
               ) {
                  return false;
               }
            });
         },
         onRenderRow: (event) => {
            const originalData = (event.record as any).originalData;

            // jobTitleColor is available as a custom property because we explicitly added it when we were building the assignments and requests data
            // for the tasksData object in our ProjectModel
            const jobTitleColor = originalData.jobTitleColor;

            // This query selector is using the meta ID value that associates all cells of a row together.
            // Since it is just querySelector and not querySelectorAll, it will only grab the first cell of the row, which is the one that
            // contains the i tag with class ".b-icon.b-tree-icon.b-icon-tree-leaf" that we want to style. So this chained selector should
            // look inside of the first element that has our row ID and then grab the icon element that's inside of it.
            const leafIcon = document.querySelector(
               `[data-id='${event.row.id}'] i.b-icon.b-tree-icon.b-icon-tree-leaf`,
            );

            if (jobTitleColor && leafIcon) {
               leafIcon.setAttribute("style", `color: ${jobTitleColor}`);
            }

            if (originalData.type === TaskType.REQUEST) {
               // disabling italic styling until we can resolve WFP-3278
               // leafIcon?.nextElementSibling?.setAttribute("style", "font-style: italic;");
            }

            // Add horizontal rule below the projectName row cells. The way that Bryntum rows are rendered are a bit weird, so in order to work properly,
            // javascript needs to be paired with the CSS rules for "hr.display = 'none'" that are found in gantt.styl
            if (
               originalData.type === TaskType.PROJECT &&
               event.row.element.querySelector("hr") == null
            ) {
               const hr = document.createElement("hr");
               hr.setAttribute(
                  "style",
                  "width: 95%; opacity: 0.5; margin: 0 0 0 5%; position: absolute; bottom: -1px;",
               );
               event.row.element.appendChild(hr);
            }
         },
         taskRenderer: ({ taskRecord, renderData }) => {
            const originalData = (taskRecord as TaskModel & { originalData: any }).originalData;
            const entityType = originalData.type;
            const projectColor = originalData.projectColor;
            const isProjectEntity = entityType === TaskType.PROJECT;
            const isCategoryEntity =
               entityType === TaskType.CATEGORY || entityType === TaskType.SUBCATEGORY;
            const isTaskEntity =
               entityType === TaskType.ASSIGNMENT || entityType === TaskType.REQUEST;
            const { startTime, endTime } = originalData;

            const [baseColor, lightColor, lightestColor] = [
               projectColor,
               lightenHexColor(projectColor, 0.4) ?? projectColor,
               lightenHexColor(projectColor, 0.8) ?? projectColor,
            ];

            // Adjusts task bar colors based on type of task:
            //   - projects will be base
            //   - categories/subcategories will be lighter
            //   - assignments will be lightest
            //   - requests will be white
            if (isProjectEntity) {
               renderData.style += `background-color: ${baseColor};`;
            } else if (isCategoryEntity) {
               renderData.style += `background-color: ${lightColor};`;
            } else if (isTaskEntity) {
               renderData.style += `text-align: right; color: #667280;`;

               if (entityType === TaskType.ASSIGNMENT) {
                  renderData.style += `border-width: 2px; border-color: ${lightColor}; --event-background-color: ${lightestColor}; background-color: ${lightestColor};`;
               } else if (entityType === TaskType.REQUEST) {
                  renderData.style += `border-width: 2px; border-color: ${baseColor}; background-color: white; border-style: dashed;`;
               }
            }
            // Takes care of rendering the task info on the task bar
            let textContent = "";

            if (isCategoryEntity) {
               textContent = StringHelper.xss`${taskRecord.name} | ${dateFormat(
                  new Date(taskRecord.startDate),
               )} - ${dateFormat(new Date(taskRecord.endDate))}`;
            } else if (isProjectEntity) {
               const projectNumberStr = originalData.projectJobNumber
                  ? `(${originalData.projectJobNumber})`
                  : "";

               textContent =
                  StringHelper.xss`${taskRecord.name} | ${projectNumberStr} ${dateFormat(
                     new Date(taskRecord.startDate),
                  )} - ${dateFormat(new Date(taskRecord.endDate))}` +
                  requestsNumberPill(originalData.requestsNumber);
            } else if (isTaskEntity) {
               textContent = StringHelper.xss`<span class="task-allocation-information">${getTaskTimeAllocation(
                  originalData,
               )}${!startTime && !endTime ? "%" : ""}</span>`;
            }

            if (isProjectEntity || isCategoryEntity)
               if (getGanttConfigurePanelValues().showProjectInformation === false) {
                  ganttRef.current?.instance?.element.classList.add("b-hide-project-information");
               }

            if (getGanttConfigurePanelValues().showAllocationInformation === false) {
               ganttRef.current?.instance?.element.classList.add("hide-allocation-information");
            }

            return textContent;
         },
         // This event is not what's preventing us from drag-and-dropping tasks on their non-working days.
         // I believe that functionality was enabled by us properly defining "calendars" and setting the config value
         // of "taskNonWorkingTime" to true. However, if the Gantt DID allow us to drag-and-drop a task on a non-working day,
         // then this event callback would handle it. Although it's not needed, I chose to leave this onBeforeTaskDropFinalize
         // callback here to serve as an example for how we would do something like this manually, and how we could use
         // `event.context.finalize(false)` to return a task to it's original position and ignore any changes.
         onBeforeTaskDropFinalize: (event: any) => {
            const type = event.context.taskRecords[0].originalData.type;
            // Prevent dragging a project to a date before today
            if (type === "project") {
               const todayDate = new Date(new Date().setHours(0, 0, 0, 0));

               if (event.context.startDate.setHours(0, 0, 0, 0) < todayDate) {
                  event.context.startDate = todayDate;

                  return event.context.finalize(true);
               }
            }
            // Only assignments/requests can have working-days defined, so if this is a project/category/subcategory, go ahead and
            // return here and proceed with the onAfterTaskDrop event.
            if (
               type === TaskType.PROJECT ||
               type === TaskType.CATEGORY ||
               type === TaskType.SUBCATEGORY
            )
               return;

            const workingDays = event.context.taskRecords[0].originalData.workDays;
            const newStartDay = event.context.startDate.getDay();
            const newEndDay = event.context.endDate.getDay();

            if (!workingDays?.[newStartDay] || !workingDays?.[newEndDay]) {
               event.context.finalize(false); // causes `event.valid` to equal false in the onAfterTaskDrop event
               console.info("New dates invalid. New start day and end day must be working days.");
            }
         },
         // We want to temporarily disable double-clicking on a task until we finalize the menu options and designs
         onTaskDblClick: () => false,
         // Only support dbl-click renaming for category name cells
         onCellDblClick: ({ record }: any) => {
            if (record.data.type !== TaskType.CATEGORY) return false;
         },
         onPresetChange: (preset) => {
            if (preset.from == undefined) return;
            localStorage.setItem("gantt-view-preset", String(preset.to.base));
         },
         onDateRangeChange: (e) => {
            setCurrentGanttRange({
               startDay: getDetachedDay(e.new.startDate),
               endDay: getDetachedDay(e.new.endDate),
            });
         },
         //#endregion Events
      };
   }, [isInitialLoad]);

   const isExpanded = localStorage.getItem("gantt-expanded") !== "false";
   const tasksData = useMemo(() => {
      return (
         ganttData?.projects.map((project: GanttProject) => {
            const projectChildren = groupTasks(
               project,
               ganttData.people,
               ganttData.jobTitles,
               isExpanded,
            );
            const hasProjectStarted = new Date(project.start_date) < new Date();

            return {
               id: project.id,
               name: project.name,
               startDate: new Date(project.start_date),
               endDate: new Date(project.est_end_date),
               children: projectChildren,
               cls: projectChildren.length === 0 ? "project-empty" : "",
               // Adding prefix of "project" to "projectColor" to make it consistent with assignments & requests
               projectColor: project.color,
               projectJobNumber: project.job_number,
               dailyStartTime: project.daily_start_time,
               dailyEndTime: project.daily_end_time,
               type: TaskType.PROJECT,
               expanded: isExpanded,
               // User cannot drag a project if it has already begun
               draggable: !hasProjectStarted,
               resizable: false,
               requestsNumber: project.requests?.length ?? 0,
               manuallyScheduled: false,
               constraintType: "startnoearlierthan",
            } as Partial<TaskModelConfig>;
         }) ?? []
      );
   }, [ganttData]);

   // This is a new method, created to support this syncUrl feature. We typically use a store method to communicate between client and server,
   // but this syncUrl param only takes the URI and handles the request to the server internally. We could have just provided the raw
   // string, but I'm unsure if there's special logic in the store.core.ts request methods that change the basePath whenever we're in non-local
   // environments. To be safe, I opted to put the URI construction logic into it's own abstract method, this way we know we're always providing
   // the correct path to our lc-core-api server.
   const completeSyncUrl = Assignment2Store.getFullRequestUrl({
      path: "/api/v3/gantt/sync",
   });

   // We're extending the task model so we can specify that we want our "type" field to always write/appear in the task record data
   // whenever the tasks are added/updated/deleted and sent to the backend through our syncUrl.
   class MyTaskModel extends TaskModel {
      static get fields() {
         return [
            { name: "type", type: "string", alwaysWrite: true },
            { name: "categoryId", type: "string", alwaysWrite: true },
            { name: "subcategoryId", type: "string", alwaysWrite: true },
         ];
      }
   }

   const project = new ProjectModel({
      useRawData: false, // v6 defaults this to true, so we're explicitly setting back to false to maintain prior behavior for now
      tasksData,
      taskModelClass: MyTaskModel,
      calendars: calendarsData,
      syncUrl: completeSyncUrl,
      autoSync: true,
   });

   // The BryntumGantt is a wrapper for the actually underlying gantt instance. If you want to,
   // you can use ganttRef.current.instance to access the actual underlying gantt instance.
   const ganttRef = useRef<BryntumGantt>(null);
   (window as any).ganttRef = ganttRef;

   // EXAMPLES of how you can access different internal stores of the ProjectModel (https://bryntum.com/products/gantt/docs/guide/Gantt/data/project_data#updating-the-project-data):
   // ganttRef.current?.instance.project.changes
   // ganttRef.current?.instance.project.taskStore.changes
   // ganttRef.current?.instance.project.timeRangeStore.changes
   // ganttRef.current?.instance.widgetMap.right.toggleCollapsed()
   // ganttRef.current?.instance.element.

   return (
      <DetailPage width="block">
         <DetailPage.Main className="grandchildren-border-box">
            <DetailPage.Body>
               <DetailPage.Card style={{ display: "flex", overflow: "hidden" }}>
                  <GanttFilterPanel
                     ganttFilter={ganttFilter}
                     setGanttFilter={setGanttFilter}
                     onClose={() => {
                        document.body.querySelector(".filterPanel")?.classList.remove("visible");
                        document.body
                           .querySelector(".gantt-filter-toggle-button")
                           ?.classList.remove("controlPanelButtonActive");
                     }}
                     jobTitles={ganttData?.jobTitles ?? []}
                  />
                  <DetailPage.Section className="detailPageSection">
                     <Box>
                        <Spinner loading={isInitialLoad}>
                           <GanttControlPanel
                              search={search}
                              setSearch={setSearch}
                              toggleFilterPanel={() => {
                                 document.body
                                    .querySelector(".filterPanel")!
                                    .classList.toggle("visible");
                                 document.body
                                    .querySelector(".gantt-filter-toggle-button")
                                    ?.classList.toggle("controlPanelButtonActive");
                              }}
                              toggleConfigPanel={() =>
                                 (
                                    ganttRef.current?.instance.widgetMap.right as Panel
                                 ).toggleCollapsed()
                              }
                              ganttFilter={ganttFilter}
                              setGanttFilter={setGanttFilter}
                           />
                           {ganttData && (
                              <BryntumGantt ref={ganttRef} {...ganttConfig} project={project} />
                           )}
                        </Spinner>
                     </Box>
                  </DetailPage.Section>
               </DetailPage.Card>
            </DetailPage.Body>
         </DetailPage.Main>
      </DetailPage>
   );
};

function groupTasks(project: any, people: any, jobTitles: any, isExpanded: boolean): GroupedTasks {
   const dateParse = timeParse("%Y-%m-%d");
   const categories: Category[] = project.cost_codes;
   const assignments: GroupableTask[] = project.assignments;
   const requests: GroupableTask[] = project.requests;

   // Step 1: Create a nested lookup table for tasks
   const taskLookup: TaskLookup = {};
   const noCategoryTasks: Task[] = [];
   const noSubcategoryTasks: noSubcategoryTasks = {};

   const group = (categoryId: string, subcategoryId: string, task: Task) => {
      if (categoryId === null) {
         noCategoryTasks.push(task);
      } else {
         if (!taskLookup[categoryId]) {
            taskLookup[categoryId] = {};
         }
         if (subcategoryId === null) {
            if (!noSubcategoryTasks[categoryId]) {
               noSubcategoryTasks[categoryId] = [];
            }
            noSubcategoryTasks[categoryId].push(task);
         } else {
            if (!taskLookup[categoryId][subcategoryId]) {
               taskLookup[categoryId][subcategoryId] = [];
            }
            taskLookup[categoryId][subcategoryId].push(task);
         }
      }
   };

   assignments.forEach((assignment: any) => {
      const person = people.find((person: any) => person.id == assignment.resource_id) as any;

      if (person == null) {
         console.error("person not found for assignment:", assignment);
      } else {
         const jobTitle = jobTitles.find((jobTitle: any) => jobTitle.id == person.job_title_id);
         const startDate = dateParse(assignment.start_day)!;
         const endDate = new Date(dateParse(assignment.end_day)!.setHours(23, 59, 59)); // assignment bar should end at the end of the day

         const assignmentTask: Task = {
            id: assignment.id,
            type: TaskType.ASSIGNMENT,
            startDate,
            endDate,
            startTime: assignment.start_time,
            endTime: assignment.end_time,
            name: `${person.first_name} ${person.last_name}`,
            projectColor: project.color,
            projectName: project.name,
            projectJobNumber: project.job_number,
            jobTitleColor: jobTitle?.color,
            jobTitleName: jobTitle?.name,
            workDays: assignment.work_days,
            calendar: getCalendarName(assignment.work_days) as any,
            manuallyScheduled: false,
            constraintType: "startnoearlierthan",
         };

         group(assignment.cost_code_id, assignment.label_id, assignmentTask);
      }
   });

   requests.forEach((request: any) => {
      const jobTitle = jobTitles.find((jobTitle: any) => jobTitle.id == request.job_title_id);
      const startDate = dateParse(request.start_day)!;
      const endDate = new Date(dateParse(request.end_day)!.setHours(23, 59, 59)); // request bar should end at the end of the day

      const requestTask: Task = {
         id: request.id,
         type: TaskType.REQUEST,
         startDate,
         endDate,
         startTime: request.start_time,
         endTime: request.end_time,
         name: "unassigned",
         projectColor: project.color,
         projectName: project.name,
         projectJobNumber: project.job_number,
         jobTitleColor: jobTitle?.color,
         jobTitleName: jobTitle?.name,
         workDays: request.work_days,
         calendar: getCalendarName(request.work_days) as any,
         manuallyScheduled: false,
         constraintType: "startnoearlierthan",
      };

      group(request.cost_code_id, request.label_id, requestTask);
   });

   // Step 2: Organize tasks into the desired structure
   const groupedCategories = categories
      .map((category) => {
         const groupedSubcategories = category.labels
            .map((subcategory) => {
               if (subcategory == null) {
                  console.error("subcateogry is null. supposed to belong to category:", category);
               }
               const tasks =
                  (taskLookup[category.id] && taskLookup[category.id][subcategory.id]) || [];
               return {
                  categoryId: category.id,
                  subcategoryId: subcategory?.id,
                  name: subcategory?.name ?? null,
                  categoryName: category.name,
                  projectColor: project.color,
                  projectName: project.name,
                  projectJobNumber: project.job_number,
                  type: TaskType.SUBCATEGORY,
                  children: tasks,
                  expanded: isExpanded,
               };
            })
            .filter((subcategory) => subcategory.children.length > 0);

         return {
            categoryId: category.id,
            name: category.name,
            projectColor: project.color,
            projectName: project.name,
            projectJobNumber: project.job_number,
            type: TaskType.CATEGORY,
            children: (noSubcategoryTasks[category.id] ?? []).concat(groupedSubcategories as any),
            expanded: isExpanded,
         };
      })
      .filter((category) => category.children.length > 0);

   return noCategoryTasks.concat(groupedCategories as any);
}
