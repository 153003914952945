const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

// Gets all the permutations of the truthy work-days in the format [[], ['Su'], ['Mo'], ['Su', 'Mo'], etc...]
const workdayPermutations = (() => {
   const n = DAYS.length;
   const permutations = [];
   const totalPermutations = 1 << n; // 2^n permutations

   for (let i = 0; i < totalPermutations; i++) {
      const permutation = [];
      for (let j = 0; j < n; j++) {
         // Check if the j-th bit in the integer i is set
         const isWorkday = (i & (1 << j)) !== 0;
         const day = DAYS[j % n];
         if (isWorkday) permutation.push(day);
      }
      permutations.push(permutation);
   }

   return permutations;
})();

// Give an object like {"0": boolean, "1": boolean, "2": boolean, "3": boolean, "4": boolean, "5": boolean, "6": boolean}
// return a string like "Su,M,Tu,W,Th,F,Sa" for the true values
export function getCalendarName(workDaysObject: { [key: string]: boolean }) {
   return Object.entries(workDaysObject)
      .filter(([_, isWorkday]) => isWorkday)
      .map(([index]) => DAYS[Number(index)])
      .join(",");
}

// Gets used by the bryntum gantt so we can add custom styling to non-working days.
// For an assignment/request task to use one of these calendars, they must use the calendar id
// as a value for the 'calendar' property on the task. To get the proper calendar id value,
// the task will call the getCalendarName that's defined above.
export const calendarsData = workdayPermutations.map((permutation) => {
   const daysString = permutation.join(",");
   return {
      id: daysString,
      name: daysString,
      cls: "non-working-day",
      unspecifiedTimeIsWorking: false,
      // map the days to generate our intervals
      intervals: permutation.map((day) => ({
         recurrentStartDate: `on ${day} at 0:00`,
         recurrentEndDate: `on ${day} at 23:59`,
         isWorking: true,
      })),
   };
});

// OPTIONAL FORMAT 1
// intervals: [
//    {
//       recurrentStartDate: 'on Sat at 0:00',
//       recurrentEndDate: 'on Mon at 0:00',
//       isWorking: false
//    }
// ]

// OPTIONAL FORMAT 2
// intervals: [
//    {
//       startDate: '2022-08-07',
//       endDate: '2022-08-11',
//       isWorking: false
//    },
//    {
//       startDate: '2022-08-18',
//       endDate: '2022-08-20',
//       isWorking: false
//    }
// ]
