import { Order } from "@laborchart-modules/common/dist/reql-builder/query-definitions";

export const DEFAULT_START_TIME = 7;
export const DEFAULT_END_TIME = 15.5;

export const daysOfWeekAbbreviations = [
   "sunday_abbrev",
   "monday_abbrev",
   "tuesday_abbrev",
   "wednesday_abbrev",
   "thursday_abbrev",
   "friday_abbrev",
   "saturday_abbrev",
];

export const dateSortOptions = [
   { id: "1", label: "Date - Ascending", value: Order.ASCENDING },
   { id: "2", label: "Date - Descending", value: Order.DESCENDING },
];

export const months = [
   "Jan",
   "Feb",
   "Mar",
   "Apr",
   "May",
   "Jun",
   "Jul",
   "Aug",
   "Sep",
   "Oct",
   "Nov",
   "Dec",
];

export const workDays = {
   "0": false,
   "1": true,
   "2": true,
   "3": true,
   "4": true,
   "5": true,
   "6": false,
};
