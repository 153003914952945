import type { NotificationManager } from "@/lib/managers/notification-manager";
import {
   Icons,
   Notification,
   notificationManagerInstance,
} from "@/lib/managers/notification-manager";
import { DateUtils } from "@/lib/utils/date";
import { Format } from "@/lib/utils/format";
import type { CustomFieldMeta, SubPropertyData } from "@/models/column-header";
import { ColumnHeader } from "@/models/column-header";
import { CustomFieldStore } from "@/stores/custom-field-store.core";
import { defaultStore } from "@/stores/default-store";
import type { GetProjectAppliedRoleOptionsData, GroupStore } from "@/stores/group-store";
import { groupStore as groupStoreInstance } from "@/stores/group-store";
import type { PeopleStore, PeopleStoreListViewType } from "@/stores/people-store";
import { peopleStore as peopleStoreInstance } from "@/stores/people-store";
import type { ObservableArray, Subscription } from "knockout";
import { observable, observableArray } from "knockout";
import { ArrayDropDownPane } from "../drop-downs/panes/array-drop-down-pane";
import { ColorPickerEditor } from "@/lib/components/editors/color-picker-editor/color-picker-editor";
import { CurrencyEditor } from "@/lib/components/editors/currency-editor/currency-editor";
import { DetachedDayEditor } from "@/lib/components/editors/detached-day-editor/detached-day-editor";
import type { DropDownEditorParams } from "@/lib/components/editors/drop-down-editor/drop-down-editor";
import { DropDownEditor } from "@/lib/components/editors/drop-down-editor/drop-down-editor";
import { NumberEditor } from "@/lib/components/editors/number-editor/number-editor";
import { TextEditor } from "@/lib/components/editors/text-editor/text-editor";
import { ParagraphEditor } from "@/lib/components/editors/paragraph-editor/paragraph-editor";
import type {
   ProjectRolesEditorProject,
   ProjectRolesEditorUpdate,
} from "@/lib/components/editors/project-roles-editor/project-roles-editor";
import { ProjectRolesEditor } from "@/lib/components/editors/project-roles-editor/project-roles-editor";
import type { SelectableColumnHeader } from "../popup/custom-column-headers-pane";
import { CustomColumnHeadersPane } from "../popup/custom-column-headers-pane";
import { ColorCell } from "./cells/color-cell";
import { MultilineLinkedTextCell } from "./cells/multiline-linked-text-cell";
import { TextCell } from "./cells/text-cell";
import { ParagraphCell } from "./cells/paragraph-cell";
import type { GridCellFactory, GridColumn, GridEditorFactoryParams } from "./grid-column";
import { GridCursorState } from "./grid-column";
import type { GridColumnGroup } from "./grid-column-group";
import type { RowBase } from "./grid-store";
import type { MultiSelectItem } from "../drop-downs/panes/multi-select-drop-down-pane";
import { MultiSelectDropDownPane } from "../drop-downs/panes/multi-select-drop-down-pane";
import type { BatchEditor } from "../batch-edit/batch-edit";
import type { EditorComponentFactory } from "@/lib/components/editors/common/editor-component";
import { CustomFieldType } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import { ColumnEntityType } from "@laborchart-modules/common/dist/rethink/schemas/column-headers/column-header";
import {
   CustomFieldEntity,
   CustomFieldSortBy,
} from "@laborchart-modules/common/dist/rethink/schemas/enums/custom-fields";
import type { SerializedCustomField } from "@laborchart-modules/common/dist/rethink/serializers/custom-field-serializer";
import type { ComponentArgs } from "../common";
import type { DisabledEditorParams } from "@/lib/components/editors/disabled-editor/disabled-editor";
import { authManager } from "@/lib/managers/auth-manager";
import { CheckboxEditor } from "../editors/checkbox-editor/checkbox-editor";
import { PermissionLevel } from "@/models/permission-level";
import { ProjectStore } from "@/stores/project-store.core";
import type { NotifiableError } from "@bugsnag/js";
import Bugsnag from "@bugsnag/js";
import { BUGSNAG_META_TAB, buildUserData } from "@/lib/utils/bugsnag-content-helper";

const SORTABLE_CUSTOM_FIELDS = new Set([
   CustomFieldType.CURRENCY,
   CustomFieldType.DATE,
   CustomFieldType.NUMBER,
   CustomFieldType.SELECT,
   CustomFieldType.TEXT,
]);

export type GroupedColumnTemplate<
   TRow extends RowBase,
   THeaderParams = unknown,
   TCellParams = unknown,
   TValue = unknown,
   TSaveValue = TValue,
> = GridColumn<TRow, THeaderParams, TCellParams, TValue, TSaveValue> & {
   isEnabled: boolean;
   isDefault?: boolean;
};

export type ColumnTemplate<
   TRow extends RowBase,
   THeaderParams = unknown,
   TCellParams = unknown,
   TValue = unknown,
   TSaveValue = TValue,
> = GridColumn<TRow, THeaderParams, TCellParams, TValue, TSaveValue> & {
   isBatchEditable?: () => boolean;
   isDefault?: boolean;
   isFixed?: boolean;
   groupedColumns?: Array<
      GroupedColumnTemplate<TRow, THeaderParams, TCellParams, TValue, TSaveValue>
   >;
   columnHeaderBuilder?: () => ColumnHeader;
};

export type CustomFieldValueConfig<TRow> = {
   isVisibleProvider: (columnHeader: ColumnHeader) => boolean;
   valueExtractor: CustomFieldValueExtractor<TRow> | null;
};

export type IsEditableProvider<TRowRecord> = ({
   columnName,
   rowRecord,
}: {
   columnName: string;
   rowRecord: TRowRecord;
}) => boolean;

export type CustomFieldEditorConfig<TRow> = CustomFieldValueConfig<TRow> & {
   fieldEntity: ColumnEntityType;
   saveProvider?: CustomFieldSaveProvider<TRow>;

   /** Used to determine if a cell can be edited. */
   isEditableProvider: (meta: CustomFieldMeta, row: TRow | null) => boolean;

   /** Used to generate disabled editors when isEditableProvider is false. */
   disabledEditorProvider?: (
      property: string,
      row: TRow,
   ) => ComponentArgs<DisabledEditorParams> | null;

   batchEditValidator?: BatchEditor<TRow>["validator"];
};

export type CustomFieldConfig<TRow> = CustomFieldValueConfig<TRow> & CustomFieldEditorConfig<TRow>;

export type ProjectRolesValueConfig<TRow> = {
   valueExtractor: ProjectRolesExtractor<TRow>;
};
export type ProjectRolesEditorConfig<TRow> = ProjectRolesValueConfig<TRow> & {
   groupIdsProvider: (row: TRow) => Set<string>;
   saveProvider: (
      projects: ObservableArray<ProjectRolesEditorProject>,
      update: ProjectRolesEditorUpdate,
   ) => Promise<void>;
};
export type ProjectRolesConfig<TRow> =
   | ProjectRolesValueConfig<TRow>
   | ProjectRolesEditorConfig<TRow>;

export type CustomFieldValueExtractor<TRow> = (
   row: TRow,
   columnHeader: ColumnHeader,
) => string | string[] | number | boolean | unknown;

export type CustomFieldSaveProvider<TRow> = (
   rows: TRow[],
   columnHeader: ColumnHeader,
   value: string | number | boolean | string[] | null,
) => Promise<void>;

export type ProjectRole = {
   id: string;
   personId: string;
   personName: string;
};

export type ProjectRolesExtractor<TRow> = (row: TRow, positionId: string) => ProjectRole[];

export enum LoadingState {
   NONE = "none",
   LOADING = "loading",
   LOADED = "loaded",
   ERROR = "error",
}

export type GridColumnManagerParams<TRow extends RowBase> = {
   customFieldConfig: CustomFieldConfig<TRow> | null;
   groupStore?: GroupStore;
   hasSortableCustomColumns?: boolean;
   listViewType: PeopleStoreListViewType;
   notificationManager?: NotificationManager;
   peopleStore?: PeopleStore;
   projectRolesConfig: ProjectRolesConfig<TRow> | null;
   projectStore?: ProjectStore;
   templates: Array<ColumnTemplate<TRow>>;
};

const PROJECT_ROLE_WIDTH = 155;

export class GridColumnManager<TRow extends RowBase> {
   /** Active column groups. Changes to this observable will trigger the column headers to be saved. */
   readonly columnGroups = observableArray<GridColumnGroup<TRow>>();

   /** The current loading state. */
   readonly loadingState = observable(LoadingState.NONE);

   readonly entityCustomFields: ObservableArray<SerializedCustomField> =
      observableArray<SerializedCustomField>([]);

   private readonly groupStore: GroupStore;
   private readonly peopleStore: PeopleStore;
   private readonly projectStore: ProjectStore;

   private readonly notificationManager: NotificationManager;
   private readonly listViewType: PeopleStoreListViewType;
   private readonly templates: Array<ColumnTemplate<TRow>>;
   private readonly customFieldConfig: CustomFieldConfig<TRow> | null;
   private readonly projectRolesConfig: ProjectRolesConfig<TRow> | null;
   private readonly hasSortableCustomColumns: boolean;
   private readonly customFieldTemplates = observableArray<ColumnTemplate<TRow>>([]);
   private roleTemplates: Array<ColumnTemplate<TRow>> = [];
   private activeTemplates: Array<ColumnTemplate<TRow>> = [];
   private readonly subscriptions: Subscription[] = [];

   constructor({
      customFieldConfig = null,
      groupStore = groupStoreInstance,
      hasSortableCustomColumns = false,
      listViewType,
      notificationManager = notificationManagerInstance,
      peopleStore = peopleStoreInstance,
      projectRolesConfig = null,
      projectStore = ProjectStore,
      templates,
   }: GridColumnManagerParams<TRow>) {
      this.groupStore = groupStore;
      this.peopleStore = peopleStore;
      this.projectStore = projectStore;

      this.notificationManager = notificationManager;
      this.listViewType = listViewType;
      this.templates = templates;
      this.activeTemplates = this.templates.filter((t) => t.isDefault && !t.isFixed).concat();
      this.customFieldConfig = customFieldConfig;
      this.projectRolesConfig = projectRolesConfig;
      this.hasSortableCustomColumns = hasSortableCustomColumns;
      this.subscriptions.push(this.columnGroups.subscribe(this.onColumnGroupsChanged, this));
   }

   async load(skipSettingActiveHeaders: boolean = false): Promise<void> {
      if (this.loadingState() != LoadingState.NONE) {
         return;
      }

      let isLoadingColumns = true;
      let isLoadingCustomFields = Boolean(this.customFieldConfig?.fieldEntity);
      let isLoadingProjectRoles = Boolean(this.projectRolesConfig);

      const updateLoadingState = () => {
         if (
            this.loadingState() != LoadingState.ERROR &&
            !isLoadingColumns &&
            !isLoadingCustomFields &&
            !isLoadingProjectRoles
         ) {
            this.loadingState(LoadingState.LOADED);
         }
      };
      const handleError = () => {
         if (this.loadingState() != LoadingState.ERROR) {
            this.loadingState(LoadingState.ERROR);
            return this.notificationManager.show(
               new Notification({
                  icon: Icons.WARNING,
                  text: "An unexpected error prevented the grid from loading.",
               }),
            );
         }
      };
      this.loadingState(LoadingState.LOADING);

      const preloaders: Array<() => Promise<void>> = [];

      // Load custom fields if the configuration exists.
      if (isLoadingCustomFields) {
         preloaders.push(async () => {
            const fields = await this.loadCustomFields(this.customFieldConfig!.fieldEntity);
            this.entityCustomFields(fields);
            isLoadingCustomFields = false;
            updateLoadingState();
         });
      }

      // Load project roles if the configuration exists.
      if (isLoadingProjectRoles) {
         preloaders.push(async () => {
            await this.loadProjectRoles();
            isLoadingProjectRoles = false;
            updateLoadingState();
         });
      }

      try {
         await Promise.all(preloaders.map((preload) => preload()));
         await this.loadUsersColumnHeaders(skipSettingActiveHeaders);
         isLoadingColumns = false;
         updateLoadingState();
      } catch (error) {
         Bugsnag.notify(error as NotifiableError, (event) => {
            event.context = "grid-column-manager_load";
            event.addMetadata(
               BUGSNAG_META_TAB.USER_DATA,
               buildUserData(authManager.authedUser()!, authManager.activePermission),
            );
            event.addMetadata("preloaders", preloaders);
         });
         handleError();
      }
   }

   private loadUsersColumnHeaders = async (
      skipSettingActiveHeaders: boolean = false,
   ): Promise<void> => {
      return new Promise<void>((resolve) => {
         this.peopleStore.getColumnHeaders(this.listViewType, (err, headers) => {
            if (err) throw err;

            // Create the the custom columns templates if custom fields have been
            // configured.
            // TODO: Move this logic to the backend. The backend should not be returning custom
            // fields that cannot be viewed by the user.
            if (this.customFieldConfig) {
               this.setCustomFieldColumns(headers.customColumns);
            }
            if (skipSettingActiveHeaders == false) {
               if (headers.savedColumns.length) {
                  this.setActiveColumnHeaders(headers.savedColumns);
               }
            }
            this.columnGroups(this.createColumnGroups());
            resolve();
         });
      });
   };

   /**
    * Exposed for testing purposes only.
    */
   async loadCustomFields(entity: ColumnEntityType): Promise<SerializedCustomField[]> {
      const fields = [];
      const stream = await CustomFieldStore.findCustomFieldsStream({
         is_on_entities: [this.convertToCustomFieldEntity(entity)],
      }).stream;
      for await (const field of stream) {
         fields.push(field);
      }
      return fields;
   }

   private convertToCustomFieldEntity(entity: ColumnEntityType): CustomFieldEntity {
      switch (entity) {
         case ColumnEntityType.ASSIGNMENTS:
            return CustomFieldEntity.ASSIGNMENT;
         case ColumnEntityType.PEOPLE:
            return CustomFieldEntity.PERSON;
         case ColumnEntityType.PROJECTS:
            return CustomFieldEntity.PROJECT;
         case ColumnEntityType.REQUESTS:
            return CustomFieldEntity.REQUEST;
      }
   }

   loadProjectRoles = async (): Promise<void> => {
      const results = await ProjectStore.getProjectAppliedRoleOptions(authManager.selectedGroupId())
         .payload;
      this.setRoleTemplates(results.data);
   };

   getActiveColumnHeaders(): ColumnHeader[] {
      return this.activeTemplates.map((template, index): ColumnHeader => {
         return this.createColumnHeader(template, index);
      });
   }

   createCustomColumnHeaderPane = (): CustomColumnHeadersPane => {
      const activeColumnHeaders = observableArray(
         this.createSelectableColumnHeaders(this.getActiveColumnHeaders(), true),
      );
      const inactiveColumnHeaders = this.createSelectableColumnHeaders(
         this.getInactiveColumnHeaders(),
         false,
      );
      const allColumnHeaders = activeColumnHeaders().concat(inactiveColumnHeaders);
      return new CustomColumnHeadersPane(activeColumnHeaders, allColumnHeaders);
   };

   updateColumnHeaders = (columnHeaders: ColumnHeader[], skipSave: boolean = false): void => {
      this.setActiveColumnHeaders(columnHeaders);
      this.columnGroups(this.createColumnGroups());
      if (skipSave == false) {
         this.saveColumnHeaders();
      }
   };

   resetColumnHeaders = (): void => {
      this.activeTemplates = this.templates.filter((t) => t.isDefault && !t.isFixed).concat();
      this.columnGroups(this.createColumnGroups());
      this.peopleStore.resetColumnHeaders(this.listViewType, (err) => {
         if (err) {
            return this.notificationManager.show(
               new Notification({
                  icon: Icons.WARNING,
                  text: "Failed to save resetting the column headers.",
               }),
            );
         }
      });
   };

   getCustomColumnBatchEditors(): Array<BatchEditor<TRow>> {
      return this.customFieldTemplates()
         .filter((template) => template.isBatchEditable != null && template.isBatchEditable())
         .map((template) => ({
            // Lie to the compiler that the factory is non-nullable then filter out
            // the null factories in the next step.
            factory: this.createEditorFactoryForCustomType(template.columnHeaderBuilder!())!,
            validator: this.customFieldConfig?.batchEditValidator,
         }))
         .filter((editor) => editor.factory != null);
   }

   dispose(): void {
      this.subscriptions.forEach((s) => s.dispose());
   }

   private onColumnGroupsChanged() {
      const allTemplates = this.getAllTemplates();
      const newActiveTemplates = this.columnGroups()
         .map((columnGroup) => {
            const template = allTemplates.find((t) => t.key == columnGroup.columns[0].key)!;
            return {
               ...template,
               width: columnGroup.columns[0].width,
               groupedColumns: template.groupedColumns?.map((column) => {
                  const subColumn = columnGroup.columns.find((c) => {
                     return c.key == column.key || c.key == `${template.key}|${column.key}`;
                  });
                  return {
                     ...column,
                     isEnabled: subColumn != null,
                     width: subColumn?.width || column.width,
                  };
               }),
            };
         })
         .filter((template) => !template.isFixed);

      // Save the changes if the active templates have changed. This prevents the initial
      // load from triggering a save.
      if (
         this.activeTemplates.some((template, index) => {
            return (
               newActiveTemplates[index]?.key != template.key ||
               newActiveTemplates[index].width != template.width ||
               newActiveTemplates[index].groupedColumns?.some((column) => {
                  const groupedTemplate = template.groupedColumns?.find((t) => t.key == column.key);
                  return groupedTemplate ? column.width != groupedTemplate?.width : false;
               })
            );
         })
      ) {
         this.activeTemplates = newActiveTemplates;
         this.saveColumnHeaders();
      }
   }

   private getAllTemplates() {
      return authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_ROLES)
         ? this.templates.concat(this.customFieldTemplates(), this.roleTemplates)
         : this.templates.concat(this.customFieldTemplates());
   }

   private getInactiveColumnHeaders(): ColumnHeader[] {
      return this.getAllTemplates()
         .filter(
            (template) =>
               !template.isFixed && !this.activeTemplates.find((t) => t.key == template.key),
         )
         .map((template, index): ColumnHeader => {
            const columnIndex = this.activeTemplates.some((t) => t.key == template.key)
               ? index
               : 10000;
            return this.createColumnHeader(template, columnIndex);
         });
   }

   private createColumnGroups(): Array<GridColumnGroup<TRow>> {
      const templates = this.templates
         .filter((template) => template.isFixed)
         .concat(this.activeTemplates);
      return templates.map((template): GridColumnGroup<TRow> => {
         const columns: Array<GridColumn<TRow>> = [
            {
               key: template.key,
               header: template.header,
               width: template.width,
               cellFactory: template.cellFactory,
               cursorStateProvider: template.cursorStateProvider,
               actionProvider: template.actionProvider,
               editorFactory: template.editorFactory,
               copyProvider: template.copyProvider,
               urlProvider: template.urlProvider,
               isSortable: template.isSortable,
               isResizable: template.isResizable,
               minWidth: template.minWidth || template.width,
               autoResizable: template.autoResizable,
            },
            ...(template.groupedColumns || [])
               .filter((groupedColumn) => groupedColumn.isEnabled)
               .map(
                  (groupedColumn): GridColumn<TRow> => ({
                     key: `${template.key}|${groupedColumn.key}`,
                     header: groupedColumn.header,
                     width: groupedColumn.width,
                     cellFactory: groupedColumn.cellFactory,
                     cursorStateProvider: groupedColumn.cursorStateProvider,
                     actionProvider: groupedColumn.actionProvider,
                     editorFactory: groupedColumn.editorFactory,
                     copyProvider: groupedColumn.copyProvider,
                     urlProvider: groupedColumn.urlProvider,
                     isSortable: groupedColumn.isSortable,
                     isResizable: groupedColumn.isResizable,
                     minWidth: groupedColumn.minWidth || groupedColumn.width,
                     autoResizable: groupedColumn.autoResizable,
                  }),
               ),
         ];
         return {
            columns,
            isDraggable: !template.isFixed,
         };
      });
   }

   private saveColumnHeaders(): void {
      this.peopleStore.updateColumnHeaders(
         this.listViewType,
         this.getActiveColumnHeaders(),
         (err) => {
            if (err) {
               return this.notificationManager.show(
                  new Notification({
                     icon: Icons.WARNING,
                     text: "Failed to update the column headers.",
                  }),
               );
            }
         },
      );
   }

   private setActiveColumnHeaders(columnHeaders: ColumnHeader[]) {
      if (this.customFieldTemplates().length == 0) {
         const customHeaders = columnHeaders.filter((header) => {
            const allTemplateKeys = this.getAllTemplates().map((t) => t.key);
            return !allTemplateKeys.includes(header.key());
         });
         this.setCustomFieldColumns(customHeaders); // Updates this.customFieldTemplates
      }
      columnHeaders.sort((a, b) => a.sequence() - b.sequence());
      const templates = this.getAllTemplates();
      this.activeTemplates = columnHeaders
         .map((columnHeader): ColumnTemplate<TRow> | null => {
            const key = this.createKey(columnHeader);
            const template = templates.find((template) => template.key == key);
            if (!template) return null;
            const minWidth = template.minWidth || template.width || 1;
            return {
               ...template,
               // Set the column width properties.
               minWidth,
               width: Math.max(columnHeader.width() || template.width || 1, minWidth),
               // Update the enabled state of the grouped columns.
               groupedColumns: (template.groupedColumns || []).map((groupedColumn) => {
                  const subProperty = columnHeader
                     .subProperties()
                     .find((s) => s.key == groupedColumn.key);
                  const minWidth = groupedColumn.minWidth || groupedColumn.width || 1;
                  return {
                     ...groupedColumn,
                     isEnabled: subProperty?.enabled() || false,
                     width: Math.max(subProperty?.width() || groupedColumn.width || 1, minWidth),
                     minWidth: groupedColumn.minWidth || groupedColumn.width,
                  };
               }),
            };
         })
         .filter((template) => template != null) as Array<ColumnTemplate<TRow>>;
   }

   private setCustomFieldColumns(columnHeaders: ColumnHeader[]) {
      if (this.customFieldConfig == null) {
         this.customFieldTemplates([]);
         return;
      }
      this.customFieldTemplates(
         columnHeaders
            .filter((h) => h.meta() != null)
            .filter(this.customFieldConfig.isVisibleProvider)
            .map((columnHeader): ColumnTemplate<TRow> => {
               const meta = columnHeader.meta()!;
               const duplicateFieldCount = columnHeaders.filter(
                  (c) => meta.field_id == c.meta()?.field_id,
               ).length;
               const baseName = columnHeader.name() || meta.field_property;
               const headerName =
                  meta.field_entity != this.customFieldConfig?.fieldEntity ||
                  duplicateFieldCount > 1
                     ? `${meta.field_entity[0].toUpperCase() + meta.field_entity.substring(1)}'${
                          meta.field_entity.substr(-1) == "s" ? "" : "s"
                       } ${baseName}`
                     : baseName;

               columnHeader.name(headerName);
               columnHeader.baseName(baseName);
               return {
                  key: this.createKey(columnHeader),
                  isBatchEditable: () =>
                     meta.field_entity === this.customFieldConfig?.fieldEntity &&
                     this.isCustomFieldEditable(meta, null),
                  header: headerName,
                  width: 100,
                  minWidth: 80,
                  isSortable:
                     this.hasSortableCustomColumns && SORTABLE_CUSTOM_FIELDS.has(meta.field_type),
                  isResizable: true,
                  columnHeaderBuilder: () => {
                     // Update the width to match the active template.
                     // NOTE: This is only relevant for saving column updates.
                     const activeTemplate = this.activeTemplates.find(
                        (t) => t.key == this.createKey(columnHeader),
                     );
                     if (activeTemplate?.width) columnHeader.width(activeTemplate.width);
                     return columnHeader;
                  },
                  // NOTE: Don't just trust the state of the editor because the isEditableProvider
                  // may change after the columns have been created.
                  cursorStateProvider: (row: TRow) => {
                     if (meta.field_type === CustomFieldType.PARAGRAPH) {
                        return this.isCustomFieldEditable(meta, row)
                           ? GridCursorState.ACTIONABLE
                           : GridCursorState.ACTIONABLE_DISABLED;
                     }
                     if (this.isCustomFieldEditable(meta, row)) {
                        return GridCursorState.ACTIONABLE;
                     }
                     const disabledEditorProvider = this.customFieldConfig?.disabledEditorProvider;
                     return disabledEditorProvider != null &&
                        disabledEditorProvider(meta.field_property, row) != null
                        ? GridCursorState.ACTIONABLE_DISABLED
                        : GridCursorState.NON_ACTIONABLE;
                  },
                  ...this.createCellProvidersForCustomType(columnHeader),
               };
            }),
      );
   }

   /**
    * Hackaround Method:
    * Only works in the scenario that the normal project role options have not had
    * the chance to load yet.
    * This allows for loading saved views, even if roleTemplates have not been
    * loaded yet via the normal means.
    */
   maybeSetRoleTemplates(positions: GetProjectAppliedRoleOptionsData[]): void {
      if (this.roleTemplates.length === 0) {
         this.setRoleTemplates(positions);
      }
   }

   private setRoleTemplates(positions: GetProjectAppliedRoleOptionsData[]): void {
      if (this.projectRolesConfig == null) {
         this.roleTemplates = [];
         return;
      }
      const valueExtractor = this.projectRolesConfig.valueExtractor;
      const saveProvider =
         "saveProvider" in this.projectRolesConfig ? this.projectRolesConfig.saveProvider : null;
      const groupIdsProvider =
         "groupIdsProvider" in this.projectRolesConfig
            ? this.projectRolesConfig.groupIdsProvider
            : null;
      this.roleTemplates = positions.map(
         (position): ColumnTemplate<TRow, unknown, unknown, any, any> => ({
            // TODO: Remove any.
            header: position.position_name,
            key: `positions:${position.position_id}`,
            width: PROJECT_ROLE_WIDTH,
            minWidth: PROJECT_ROLE_WIDTH,
            isResizable: true,
            ...MultilineLinkedTextCell.columnProviders<TRow>((row: TRow) => {
               const roles = valueExtractor(row, position.position_id);
               return {
                  values: roles.map((role) => ({
                     text: role.personName,
                     href: `/groups/${authManager.selectedGroupId()}/people/${role.personId}`,
                  })),
               };
            }),
            columnHeaderBuilder: () => {
               const activeTemplate = this.activeTemplates.find(
                  (t) => t.key == `positions:${position.position_id}`,
               );
               return new ColumnHeader({
                  name: position.position_name,
                  key: "positions",
                  key_id: position.position_id,
                  sequence: 0,
                  sortable: false,
                  width: activeTemplate?.width || PROJECT_ROLE_WIDTH,
               });
            },
            editorFactory: saveProvider
               ? ({ row }) => {
                    const roles = valueExtractor(row, position.position_id);
                    console.info("editorFactory:", roles);
                    const projects = observableArray([
                       {
                          id: row.id,
                          groupIds: groupIdsProvider!(row),
                          roles: roles.map((role) => ({
                             id: role.id,
                             assignee_id: role.personId,
                             assignee_name: role.personName,
                             position_id: position.position_id,
                          })),
                       },
                    ]);
                    return ProjectRolesEditor.factory<TRow>(() => ({
                       jobTitleId: position.position_id,
                       title: position.position_name,
                       value: projects,
                       saveProvider: (update) => saveProvider(projects, update),
                    }))([row]);
                 }
               : undefined,
         }),
      );
   }

   private createColumnHeader(template: ColumnTemplate<TRow>, index: number) {
      if (template.columnHeaderBuilder) {
         const header = template.columnHeaderBuilder();
         header.sequence(index);
         return header;
      }
      const name = (() => {
         if (typeof template.header === "string") return template.header;
         if (template.header.textName) return template.header.textName;
         return null;
      })();
      return new ColumnHeader({
         name,
         key: template.key,
         sequence: index,
         sortable: template.isSortable || false,
         width: template.width,
         sub_properties: template.groupedColumns?.map<SubPropertyData>((column) => ({
            enabled: column.isEnabled,
            key: column.key,
            name: column.header as string,
            width: column.width,
         })),
      });
   }

   private createCellProvidersForCustomType(columnHeader: ColumnHeader): {
      cellFactory: GridCellFactory<TRow>;
      copyProvider: (data: TRow) => string;
      editorFactory?: (row: GridEditorFactoryParams<TRow>) => any;
   } {
      const extractor = (row: TRow) => this.customFieldConfig!.valueExtractor!(row, columnHeader);
      const getInputValue = <T>(data: TRow[]) => {
         return data.length === 1 ? (extractor(data[0]) as unknown as T) : null;
      };
      const saveProvider = this.customFieldConfig?.saveProvider ?? (async () => {});
      const editorFactory = this.createEditorFactoryForCustomType(columnHeader);
      const editorOrEmpty = {
         editorFactory: ({ row, cursorState }: { row: TRow; cursorState: GridCursorState }) => {
            if (cursorState == GridCursorState.ACTIONABLE) {
               return editorFactory ? editorFactory([row]) : null;
            } else {
               const fieldName = columnHeader.meta()!.field_property;
               const disabledEditorProvider = this.customFieldConfig?.disabledEditorProvider;
               return disabledEditorProvider != null
                  ? disabledEditorProvider(fieldName, row)
                  : null;
            }
         },
      };
      switch (columnHeader.meta()!.field_type) {
         case CustomFieldType.TEXT:
            return {
               ...TextCell.columnProviders<TRow>((row: TRow) => {
                  const val = extractor(row) as string | null;
                  return val?.toString() ?? "";
               }),
               ...editorOrEmpty,
            };
         case CustomFieldType.NUMBER:
            return {
               ...TextCell.columnProviders<TRow>((row: TRow) => {
                  const value = extractor(row) as number | null;
                  return value != null ? Format.formatNumber(value) : "";
               }),
               ...editorOrEmpty,
            };

         case CustomFieldType.CURRENCY:
            return {
               ...TextCell.columnProviders<TRow>((row: TRow) => {
                  const value = extractor(row) as number | null;
                  return value != null ? Format.formatCurrency(value) : "";
               }),
               ...editorOrEmpty,
            };
         case CustomFieldType.HEX_COLOR:
            return {
               ...ColorCell.columnProviders<TRow>((row) => {
                  return extractor(row) as string | null;
               }),
               ...editorOrEmpty,
            };
         case CustomFieldType.BOOL:
            return {
               ...TextCell.columnProviders<TRow>((row: TRow) => {
                  const value = extractor(row) as boolean | null;
                  return value != null ? value.toString().toUpperCase() : "";
               }),
               ...editorOrEmpty,
            };
         case CustomFieldType.DATE:
            return {
               ...TextCell.columnProviders<TRow>((row: TRow) => {
                  const value = extractor(row);
                  return DateUtils.formatDetachedDay(value as number, defaultStore.getDateFormat());
               }),
               ...editorOrEmpty,
            };
         case CustomFieldType.SELECT:
            return {
               ...TextCell.columnProviders<TRow>((row: TRow) => {
                  const val = extractor(row) as string | null;
                  return val?.toString() ?? "";
               }),
               ...editorOrEmpty,
            };
         case CustomFieldType.MULTI_SELECT:
            return {
               ...TextCell.columnProviders<TRow>((row: TRow) => {
                  const value = extractor(row) as string[] | null;
                  return value != null ? value.join(", ") : "";
               }),
               ...editorOrEmpty,
            };
         case CustomFieldType.PARAGRAPH:
            return {
               ...ParagraphCell.columnProviders<TRow>((row: TRow) => {
                  const val = extractor(row) as string | null;
                  return {
                     text: val?.toString() ?? "",
                     maxNumberOfLines: 3,
                  };
               }),
               // NOTE: Paragraph editor is always set, even if the field is not editable.
               editorFactory: ({
                  row,
                  cursorState,
               }: {
                  row: TRow;
                  cursorState: GridCursorState;
               }) => {
                  return ParagraphEditor.factory<TRow>((rows) => ({
                     title: columnHeader.name() as string,
                     value: getInputValue<string | null>(rows),
                     isRequired: false,
                     isDisabled: cursorState != GridCursorState.ACTIONABLE,
                     saveProvider: (value) => saveProvider(rows, columnHeader, value),
                  }))([row]);
               },
            };
         default:
            return null as never;
      }
   }

   private createEditorFactoryForCustomType(
      columnHeader: ColumnHeader,
   ): EditorComponentFactory<TRow, any, any, any> | null {
      const extractor = (row: TRow) => this.customFieldConfig!.valueExtractor!(row, columnHeader);
      const getInputValue = <T>(data: TRow[]) => {
         return data.length === 1 ? (extractor(data[0]) as unknown as T) : null;
      };
      const saveProvider = this.customFieldConfig?.saveProvider ?? (async () => {});
      switch (columnHeader.meta()!.field_type) {
         case CustomFieldType.TEXT:
            return TextEditor.factory((rows) => ({
               title: columnHeader.baseName() as string,
               width: 250,
               value: getInputValue<string | null>(rows),
               isRequired: false,
               saveProvider: (value) => saveProvider(rows, columnHeader, value),
            }));
         case CustomFieldType.NUMBER:
            return NumberEditor.factory((rows) => ({
               title: columnHeader.baseName() as string,
               value: getInputValue<number | null>(rows),
               isRequired: false,
               saveProvider: (value) => saveProvider(rows, columnHeader, value),
            }));
         case CustomFieldType.CURRENCY:
            return CurrencyEditor.factory((rows) => ({
               title: columnHeader.baseName() as string,
               value: getInputValue<number | null>(rows),
               isRequired: false,
               saveProvider: (value) => saveProvider(rows, columnHeader, value),
            }));
         case CustomFieldType.HEX_COLOR:
            return ColorPickerEditor.factory((rows) => ({
               title: columnHeader.baseName() as string,
               value: getInputValue<string | null>(rows),
               isRequired: false,
               saveProvider: (value) => saveProvider(rows, columnHeader, value),
            }));
         case CustomFieldType.BOOL:
            return CheckboxEditor.factory((rows) => {
               const value = (getInputValue(rows) as boolean | null) ?? null;
               return {
                  title: columnHeader.baseName() as string,
                  label: columnHeader.baseName() as string,
                  value: value,
                  isClearable: rows.length > 1 || value != null,
                  saveProvider: (value) => saveProvider(rows, columnHeader, value),
               };
            });
         case CustomFieldType.DATE:
            return DetachedDayEditor.factory((rows) => {
               const value = getInputValue(rows) as number | null;
               return {
                  title: columnHeader.baseName(),
                  value,
                  isClearable: rows.length > 1 || value != null,
                  saveProvider: (value) => saveProvider(rows, columnHeader, value),
               };
            });
         case CustomFieldType.SELECT:
            return DropDownEditor.factory((rows) => {
               const value = getInputValue(rows) as string | null;
               const params: DropDownEditorParams<{ id: string; name: string }> = {
                  title: columnHeader.baseName(),
                  value: value ? new Set([value]) : new Set(),
                  cellFactory: TextCell.factory<{ id: string; name: string }>((data) => data.name),
                  pane: new ArrayDropDownPane({
                     items: this.getCustomFieldOptions(columnHeader.meta()!.field_id),
                     searchTextProvider: (item) => item.name,
                  }),
                  isClearable: rows.length > 1 || value != null,
                  saveProvider: ([value]) => saveProvider(rows, columnHeader, value?.id ?? null),
               };
               return params;
            });
         case CustomFieldType.MULTI_SELECT:
            return DropDownEditor.factory((rows) => {
               const value = getInputValue(rows) as string[] | null;
               const selectedIds = observable(value ? new Set(value) : new Set<string>());
               const pane = new MultiSelectDropDownPane({
                  items: this.getCustomFieldOptions(columnHeader.meta()!.field_id),
                  selectedIds,
                  textProvider: (item) => item.name,
               });
               const params: DropDownEditorParams<MultiSelectItem<{ id: string; name: string }>> = {
                  title: columnHeader.baseName(),
                  pane,
                  cellFactory: pane.cellFactory,
                  selectedItemCellFactory: TextCell.factory(
                     (item: MultiSelectItem<{ id: string; name: string }>) => {
                        return (item.item as { id: string; name: string }).name;
                     },
                  ),
                  actionInterceptor: pane.actionInterceptor,
                  saveProvider: (value) =>
                     saveProvider(
                        rows,
                        columnHeader,
                        Array.from(value).map((v) => v.id),
                     ),
                  value: selectedIds,
                  isClearable: rows.length > 1 || selectedIds().size > 0,
               };
               return params;
            });
         case CustomFieldType.PARAGRAPH:
            // This method does not support paragraph.
            return null;
         default:
            return null;
      }
   }

   private isCustomFieldEditable(meta: CustomFieldMeta | null, row: TRow | null): boolean {
      if (!meta || !this.customFieldConfig) return false;

      const saveProvider =
         "saveProvider" in this.customFieldConfig ? this.customFieldConfig?.saveProvider : null;
      if (!saveProvider) return false;

      const field = this.entityCustomFields().find((field) => field.id == meta.field_id);
      if (!field || field.integration_only) return false;

      const isEditableProvider =
         "isEditableProvider" in this.customFieldConfig
            ? this.customFieldConfig?.isEditableProvider
            : null;

      return isEditableProvider != null ? isEditableProvider(meta, row) : true;
   }

   private getCustomFieldOptions(customFieldId: string): Array<{ id: string; name: string }> {
      const customField = this.entityCustomFields().find((c) => c.id === customFieldId);
      if (!customField) return [];
      const values = (customField.values || []).map((value) => ({
         id: value,
         name: value,
      }));
      return customField.sort_by == CustomFieldSortBy.ALPHA
         ? values.sort((a, b) => a.name.localeCompare(b.name))
         : values;
   }

   private createKey(columnHeader: ColumnHeader) {
      const key = columnHeader.key();
      // NOTE: columnHeader.meta only exists for custom fields and keyId only exists for
      // 'positions' columns (project roles). The key_id represents the ID of the entity
      // stored in the DB. In the case of positions, this is the ID of a position.
      const keyIdOrFieldId = columnHeader.meta()?.field_id || columnHeader.keyId() || null;
      const fieldEntity = columnHeader.meta()?.field_entity || null;
      const parts = [key];
      if (keyIdOrFieldId) parts.push(keyIdOrFieldId);
      if (fieldEntity) parts.push(fieldEntity);
      return parts.join(":");
   }

   private createSelectableColumnHeaders(
      columnHeaders: ColumnHeader[],
      selected: boolean,
   ): SelectableColumnHeader[] {
      return columnHeaders.map((columnHeader) => {
         const selectableColumnHeader = columnHeader as SelectableColumnHeader;
         selectableColumnHeader.selected = observable(selected);
         return selectableColumnHeader;
      });
   }
}
