import type { TimeZoneOption, TimeZonesMapKeys } from "@/lib/utils/timezones";
import type {
   CustomFieldProjectType,
   CustomField,
   TagInstances,
   JobTitle,
   TagInstance,
   WageOverride,
   ActionMode,
   FormSelectOption,
   TagOption,
   ResourceName,
} from "@/react/prop-types";
import type { SerializedAttachment } from "@laborchart-modules/common/dist/rethink/serializers/attachment-serializer";
import type { ProjectStatus } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import type { PillColor } from "@procore/core-react";
import type { GetIntegratedFieldsResponse } from "@laborchart-modules/lc-core-api/dist/api/company/get-integrated-fields";
import type { SerializedProjectDetailProject } from "@laborchart-modules/lc-core-api/dist/api/projects/get-project";
import type { ResponsePayload } from "@laborchart-modules/common/dist/api";
import type { SerializedPosition } from "@laborchart-modules/common/dist/rethink/serializers";
import type { CreateNotePayload } from "@laborchart-modules/lc-core-api/dist/api/notes/create-note";
import type { UpdateSingleProjectResponse } from "@laborchart-modules/lc-core-api";
import type { FormikHandlers } from "formik";

type ProjectFormValues = {
   name: string;
   job_number: string | null;
   status: FormPillSelectOption<ProjectStatus>;
   group_ids: Array<FormSelectOption<string>>;
   start_date: Date | null;
   est_end_date: Date | null;
   daily_start_time: FormSelectOption<number>;
   daily_end_time: FormSelectOption<number>;
   timezone: TimeZoneOption<TimeZonesMapKeys>;
   address_1: string | null;
   address_2: string | null;
   city_town: string | null;
   state_province: string | null;
   country: string | null;
   zipcode: string | null;
   bid_rate: number | null;
   color: string;
};

export type CreateProjectFormValues = ProjectFormValues;
export type ProjectDetailFormValues = ProjectFormValues & {
   customer_name: string | null;
   percent_complete: number | null;
   project_type: string | null;
   custom_fields?: CustomFieldProjectType[] | null;
};

export type ProjectSpecificationsFormValues = {
   default_recipient_ids: string[] | null;
};

export type FormPillSelectOption<T> = FormSelectOption<T> & { color: PillColor };

export type ProjectDetailTearsheetProps = {
   projectId: string;
   handleCreateLaborPlanClick: any;
   onUpdateProject: () => void;
   integratedFields: GetIntegratedFieldsResponse;
   customFields: CustomField[];
   showActiveTab?: string;
};

// Create an extended type that includes procore_id
export type ExtendedProjectDetail = SerializedProjectDetailProject & {
   procore_id?: string; // Optional property
};

export type ExtendedGetProjectDetailResponse = ResponsePayload<ExtendedProjectDetail>;

export enum ProjectDetailTearsheetTabs {
   OVERVIEW = "Overview",
   SPECIFICATIONS = "Specifications",
   NOTES = "Notes",
   ACTIVITY = "Activity",
}

export type ProjectDetailsCardProps = {
   projectData: ExtendedGetProjectDetailResponse | null;
   refreshTable?: () => void;
   refetchData: () => void;
   updateProjectData: (payload: any) => Promise<UpdateSingleProjectResponse>;
   loading: boolean;
};

export type ProjectTagData = {
   tag_instances: TagInstances;
};

export type EmptyTagsProps = {
   openAddTagsModal: () => void;
   isAddTagsOpen: boolean;
};

export type ListTagsCardProps = {
   tags: TagInstance;
   onUpdateProject: (payload: any, mode: string) => Promise<void>;
   selectedTagId: string;
   setSelectedTagId: (tagId: string) => void;
   openAddTagsModal: () => void;
   isAddTagsOpen: boolean;
   canEditTags: boolean;
};

export type AddTagsModalProps = {
   open: boolean;
   onClose: () => void;
   handleCreate: (payload: ProjectTagData, mode: string) => Promise<void>;
   availableTags: TagOption[];
   showLoader?: boolean;
};

export type EmptyWageOverridesProps = {
   openAddWageOverridesModal: () => void;
   isAddWageOverridesOpen: boolean;
};

export type ListWageOverridesCardProps = {
   wageOverrides: WageOverrideData[];
   editOverrides: boolean;
   openAddWageOverridesModal: () => void;
   isAddWageOverridesOpen: boolean;
   selectedOverrideId: string;
   setSelectedOverrideId: (overrideId: string) => void;
   jobTitles: JobTitle[];
   onUpdateProject: (payload: ProjectWageOverrideData) => Promise<void>;
};

export type WageOverrideData = {
   id: string;
   rate: number;
   position: SerializedPosition;
   job_title_id: string;
};

export type ProjectWageOverrideData = {
   wage_overrides: WageOverride;
};

export type WageOverrideFormValues = {
   job_title: {
      id: string;
      label: string;
   };
   wage: number;
};

export type AddWageOverridesModalProps = {
   open: boolean;
   onClose: () => void;
   handleCreate: (payload: ProjectWageOverrideData) => Promise<void>;
   mode?: ActionMode;
   initialValues?: any;
   availableJobTitles: JobTitle[];
   wageOverrides: WageOverrideData[] | null;
};

export type ProjectDetailsNotesCardProps = {
   projectId: string;
   projectData: ExtendedGetProjectDetailResponse | null;
   refetchData: () => void;
   loading: boolean;
};

export type AddNotesModalProps = {
   onClose: () => void;
   handleCreateUpdate: (payload: CreateNotePayload, noteId?: string) => Promise<void>;
   showLoader?: boolean;
   mode?: ActionMode;
   initialValues?: any;
   showToolBar?: boolean;
   canEditNotes: boolean;
   handleDelete?: (noteId: string) => Promise<void>;
};

export type EmptyNotesProps = {
   openAddNotesModal: () => void;
   isAddNotesOpen: boolean;
   canEditNotes: boolean;
};

export type ProjectDetailsActivitiesProps = {
   projectData: ExtendedGetProjectDetailResponse | null;
   refetchData: () => void;
   loading: boolean;
};

export enum AssignmentType {
   GANTT = "gantt",
   BOARD = "board",
}
export type ProjectDetailsFields = CustomField & {
   id?: string;
   type?: string;
   required?: boolean;
};
export type EmptyAttachmentProps = {
   openAddAttachmentModal: () => void;
   isAddAttachmentOpen: boolean;
};

export type ListAttachmentsCardProps = {
   attachments: SerializedAttachment[];
   openAddAttachmentItemModal: () => void;
   isAddAttachmentOpen: boolean;
   canEditAttachments: boolean;
};

export type ProjectAttachmentsData = {
   attachment_ids: string[];
};

export type EmptyRolesProps = {
   editRoles: boolean;
   openAddRolesModal: () => void;
   isAddRolesOpen: boolean;
};

export type ListRolesCardProps = {
   roles: Role[];
   editRoles: boolean;
   openAddRolesModal: () => void;
   isAddRolesOpen: boolean;
   selectedRoleId: string;
   setSelectedRoleId: (roleId: string) => void;
   jobTitles: JobTitle[];
   resourceName: ResourceName[] | null;
   onUpdateProject: (
      jobTitleValue: string,
      personIdValue: string,
      archived: boolean,
   ) => Promise<void>;
};

export type RoleFormValues = {
   job_title: {
      id: string;
      label: string;
   };
   resource_title: {
      id: string;
      label: string;
   };
};

export type ProjectRoleData = {
   roles: ProjectRolePayloads[];
};

export type ProjectRolePayloads = {
   id: string;
   position_id: string;
   person_id: string;
   archived: boolean;
};

export type Assignee = {
   name: {
      first: string;
      last: string;
   };
};

export type Role = {
   id: string;
   person_id: string;
   job_title_id: string;
   assignee: Assignee;
   position: SerializedPosition;
};

export type AddRolesModalProps = {
   onClose: () => void;
   handleCreate: (jobTitleValue: string, personIdValue: string, archived: boolean) => Promise<void>;
   mode?: ActionMode;
   initialValues?: any;
   availableJobTitles: JobTitle[];
   availableResourceName: ResourceName[] | null;
   roles: Role[] | null;
};

export type CustomAssignmentAlertsProps = {
   projectData: ExtendedGetProjectDetailResponse | null;
   editMode?: boolean;
   setEditMode: () => void;
   loading: boolean;
   handleChange: FormikHandlers["handleChange"];
};
export type DefaultRecipient = {
   id: string;
   name?: string;
   label?: string;
};
export type AddAttachmentsProps = {
   availableAttachments?: SerializedAttachment[];
   open: boolean;
   onClose: () => void;
   handleCreate: (payload: ProjectAttachmentsData, mode: string) => Promise<void>;
   showLoader?: boolean;
};
