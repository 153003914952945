import { DateUtils } from "@/lib/utils/date";
import type {
   ColumnMap,
   IntegratedField,
   ProjectListServerFilter,
   SortModel,
   StreamResponseConversion,
} from "@/react/prop-types";
import { convertDateFilters } from "@/react/shared/custom-field-utils";
import { Order } from "@laborchart-modules/common/dist/reql-builder/query-definitions";
import type { Filter } from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/common";
import type { FilterClassifier } from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/enums/common";
import { FilterFieldType } from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/enums/common";
import type { GetIntegratedFieldsResponse } from "@laborchart-modules/lc-core-api/dist/api/company/get-integrated-fields";
import type { DataTableConfig } from "@procore/data-table";
import { FindProjectsSortBy } from "./project-list-enums";
import type { CoreApiSort } from "./project-list-prop-types";
import MultiSelectPillFilter from "../data-table/custom-filters/multiselect-pill-filter";
import { NumericValueFilter } from "../data-table/custom-filters/numeric-value-filter";
import { DateFilter } from "../data-table/custom-filters/date-filter";
import { StatusFilter } from "../data-table/custom-filters/status-filter";
import { convertNestedCustomFilters } from "../data-table/helpers";

const NUMERIC_FIELDS: any[] = ["bid_rate", "percent_complete"];
const NESTED_FIELDS_TYPE: any[] = [FilterFieldType.BOOL, FilterFieldType.MULTI_SELECT];
const NUMERIC_FIELDS_TYPE: any[] = [FilterFieldType.NUMBER, FilterFieldType.CURRENCY];

export function convertDataTableSort(sort: SortModel[]): CoreApiSort {
   if (sort.length == 0) return {};
   const sortObj: CoreApiSort = {
      sort_by: sortFieldMaps[sort[0].field],
      direction: sortOrderMap[sort[0].sort],
   };
   // If the sort field is custom_field, then add the custom_field_id
   // fieldId will only be there in case of custom_field, it has been added explicitly to identify the custom_field
   if (sort[0].fieldId) {
      sortObj["sort_by"] = sortFieldMaps["custom_field"];
      sortObj["custom_field_id"] = sort[0].fieldId;
   }
   return sortObj;
}

export const defaultProjectListTableConfig: DataTableConfig = {
   columnState: [
      {
         field: "project_name",
         hidden: false,
         pinned: null,
         width: 140,
         sort: "asc",
         sortIndex: 0,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "color",
         hidden: false,
         pinned: null,
         width: 140,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "project_hash",
         hidden: false,
         pinned: null,
         width: 230,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "address_1",
         hidden: false,
         pinned: null,
         width: 368,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "address_2",
         hidden: false,
         pinned: null,
         width: 368,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "city_town",
         hidden: false,
         pinned: null,
         width: 140,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "state_province",
         hidden: false,
         pinned: null,
         width: 140,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "zipcode",
         hidden: false,
         pinned: null,
         width: 140,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "status",
         hidden: false,
         pinned: null,
         width: 140,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "groups",
         hidden: true,
         pinned: null,
         width: 140,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "tag_instances",
         hidden: true,
         pinned: null,
         width: 140,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "bid_rate",
         hidden: true,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "percent_complete",
         hidden: true,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "wage_overrides",
         hidden: true,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "start_date",
         hidden: true,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "est_end_date",
         hidden: true,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "project_type",
         hidden: true,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "customer_name",
         hidden: true,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "created_by",
         hidden: true,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "created_at",
         hidden: true,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "country",
         hidden: true,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "daily_start_time",
         hidden: true,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "daily_end_time",
         hidden: true,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "timezone",
         hidden: true,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "procore_id",
         hidden: true,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
   ],
   columnGroupState: [],
   filters: {},
   rowHeight: 49,
   serverFilters: [],
};

export function filterGroupDetails({
   groupOptions,
   projectGroupList,
}: {
   groupOptions: StreamResponseConversion | null;
   projectGroupList: string[];
}) {
   // Filter the groupOptions based on the projectGroupList
   const filtered = groupOptions?.filter((groupOption) =>
      projectGroupList.includes(groupOption.id),
   );
   return filtered;
}

export function convertDataTableFilters(filters: ProjectListServerFilter[]): Filter[] {
   const coreApiFilters: Filter[] = [];

   //filter.fieldId and filter.fieldType are added to identify the custom_field
   for (const filter of filters) {
      if (
         filter.field === "start_date" ||
         filter.field === "est_end_date" ||
         (filter.fieldId && filter.fieldType === FilterFieldType.DATE)
      ) {
         const dateFilters = convertDateFilters(filter, filterNameMaps, filterTypeMaps);
         coreApiFilters.push(...dateFilters);
         continue;
      }

      if (
         NUMERIC_FIELDS.includes(filter.field) ||
         (filter.fieldId && NUMERIC_FIELDS_TYPE.includes(filter.fieldType!))
      ) {
         const numericFilters = convertNumericFilters(filter);
         coreApiFilters.push(...numericFilters);
         continue;
      }

      if (filter.fieldId && NESTED_FIELDS_TYPE.includes(filter.fieldType!)) {
         const nestedFilters = convertNestedCustomFilters(filter, filterTypeMaps);
         coreApiFilters.push(...nestedFilters);
         continue;
      }

      //default custom filter method for field type text, select
      if (filter.fieldId) {
         const customFilters = convertCustomFilters(filter);
         coreApiFilters.push(...customFilters);
         continue;
      }
      // if none of the conditions met then default creation of filter
      coreApiFilters.push({
         name: filterNameMaps[filter.field],
         property: filter.field,
         type: filterTypeMaps[filter.field],
         value_sets:
            filterTypeMaps[filter.field] !== "multi-select"
               ? filter.selected.map((x: any) => ({ negation: false, value: x.value ?? x.id }))
               : [
                    {
                       negation: false,
                       value: filter.selected.map((x: any) => x.value ?? x.id) as string[],
                    },
                 ],
      });
   }

   return coreApiFilters;
}

function convertNumericFilters(filter: ProjectListServerFilter): Filter[] {
   const numericFilters: Filter[] = [];
   filter.selected.forEach(
      (x: { value: number; classifier: { label: string; value: FilterClassifier } }) => {
         const filterObject: Filter = {
            name: filter.fieldId ? filter.fieldId : filterNameMaps[filter.field],
            property: filter.fieldId ? "custom_fields" : filter.field,
            type: filterTypeMaps[filter.fieldType ? filter.fieldType : filter.field],
            value_sets: [
               {
                  negation: false,
                  value: x.value,
                  classifier: x.classifier.value,
               },
            ],
         };
         if (filter.fieldId) {
            filterObject.custom_field_id = filter.fieldId;
         }
         numericFilters.push(filterObject);
      },
   );
   return numericFilters;
}

function convertCustomFilters(filter: ProjectListServerFilter): Filter[] {
   const customFilters: Filter[] = [];
   filter.selected.forEach((x: { label: string; value: boolean } | any) => {
      customFilters.push({
         name: filter.fieldId!,
         property: "custom_fields",
         type: filterTypeMaps[filter.fieldType!],
         value_sets: [
            {
               negation: false,
               value: x.value ?? x,
            },
         ],
         custom_field_id: filter.fieldId,
      });
   });
   return customFilters;
}

// Filter Name Maps
export const filterNameMaps: { [key: string]: string } = {
   start_date: "Project Start Date",
   est_end_date: "Project End Date",
   status: "Project Status",
   bid_rate: "Project Estimated Average Rate",
   percent_complete: "Project Percent Complete",
   tag_instances: "Project Tags",
};
// Filter Type Maps
const filterTypeMaps: { [key: string]: FilterFieldType } = {
   start_date: FilterFieldType.DATE,
   est_end_date: FilterFieldType.DATE,
   status: FilterFieldType.SELECT,
   bid_rate: FilterFieldType.NUMBER,
   percent_complete: FilterFieldType.NUMBER,
   tag_instances: FilterFieldType.MULTI_SELECT,
   date: FilterFieldType.DATE,
   bool: FilterFieldType.BOOL,
   currency: FilterFieldType.CURRENCY,
   "multi-select": FilterFieldType.MULTI_SELECT,
   number: FilterFieldType.NUMBER,
   select: FilterFieldType.SELECT,
   text: FilterFieldType.TEXT,
};

// Sort Field Maps
export const sortFieldMaps: { [key: string]: FindProjectsSortBy } = {
   project_name: FindProjectsSortBy.NAME,
   start_date: FindProjectsSortBy.START_DATE,
   est_end_date: FindProjectsSortBy.EST_END_DATE,
   project_hash: FindProjectsSortBy.NUMBER,
   address_1: FindProjectsSortBy.ADDRESS_1,
   address_2: FindProjectsSortBy.ADDRESS_2,
   city: FindProjectsSortBy.CITY_TOWN,
   state: FindProjectsSortBy.STATE_PROVINCE,
   postal: FindProjectsSortBy.ZIP_CODE,
   project_type: FindProjectsSortBy.TYPE,
   status: FindProjectsSortBy.STATUS,
   customer_name: FindProjectsSortBy.CUSTOMER_NAME,
   created_at: FindProjectsSortBy.CREATED_AT,
   country: FindProjectsSortBy.COUNTRY,
   daily_start_time: FindProjectsSortBy.DAILY_START_TIME,
   daily_end_time: FindProjectsSortBy.DAILY_END_TIME,
   timezone: FindProjectsSortBy.TIMEZONE,
   percent_complete: FindProjectsSortBy.PERCENT_COMPLETE,
   wage_overrides: FindProjectsSortBy.WAGE_OVERRIDES,
   bid_rate: FindProjectsSortBy.BID_RATE,
   custom_field: FindProjectsSortBy.CUSTOM_FIELD,
   tag_instances: FindProjectsSortBy.TAG_INSTANCES,
};

// Sort Order Maps
const sortOrderMap: { [key: string]: Order } = {
   asc: Order.ASCENDING,
   desc: Order.DESCENDING,
};

//Function to get date filter token text
export function getDateFilterTokenText(item: any): string {
   let tokenText = "";
   if (item.value.length > 0) {
      const selectedDate = DateUtils.getShortNumericDate(new Date(item.value[0].date));
      const label = item.value[0].classifier.label;
      tokenText = `${item.headerName} ${label} ${selectedDate}`;
   }
   return tokenText;
}
//Function to get custom filter token text
export function customFilterTokenText(item: any, field: string, I18n: any): string {
   return `${I18n.t("views.company.workforce_planning.projects." + field)} ${
      item.value.length > 0 ? item.value[0].classifier.value : ""
   } ${item.value.length > 0 ? item.value[0].stringValue : ""}`;
}

export function editableFields(integratedFields: GetIntegratedFieldsResponse | null) {
   return integratedFields?.data.projects_integrated_fields
      .filter((field) => field.locked)
      .reduce((acc, field) => {
         acc[`${field.property}_editable`] = field.locked ? false : true;
         return acc;
      }, {} as { [key: string]: any });
}

export function filterGroupIds({
   groupOptions,
   editedGroupIds,
}: {
   groupOptions: StreamResponseConversion | null;
   editedGroupIds: StreamResponseConversion;
}) {
   const set1 = new Set(editedGroupIds?.map((item) => item.id));
   const resultObject = {} as any;
   groupOptions?.forEach((item) => {
      resultObject[item.id] = set1.has(item.id);
   });
   return resultObject;
}

export function isFieldLocked(integratedField: IntegratedField[], field: string) {
   return integratedField.some(
      (integratedField: IntegratedField) =>
         integratedField.property === field && integratedField.locked,
   );
}
export const columnHeadersMap: ColumnMap = {
   project_name: "project_name",
   project_hash: "project_hash",
   color: "color",
   address_1: "address_1",
   address_2: "address_2",
   city: "city_town",
   state: "state_province",
   postal: "zipcode",
   project_type: "project_type",
   status: "status",
   customer_name: "customer_name",
   created_by: "created_by",
   created_at: "created_at",
   country: "country",
   start_date: "start_date",
   est_end_date: "est_end_date",
   daily_start_time: "daily_start_time",
   daily_end_time: "daily_end_time",
   timezone: "timezone",
   bid_rate: "bid_rate",
   percent_complete: "percent_complete",
   wage_overrides: "wage_overrides",
   groups: "groups",
   tag_instances: "tag_instances",
   procore_id: "procore_id",
};
export const filterRendererMap: ColumnMap = {
   tag_instances: MultiSelectPillFilter,
   status: StatusFilter,
   bid_rate: NumericValueFilter,
   percent_complete: NumericValueFilter,
   start_date: DateFilter,
   est_end_date: DateFilter,
};

export const filterFieldMap: ColumnMap = {
   tag_instances: "tag_instances",
   status: "status",
   bid_rate: "bid_rate",
   percent_complete: "percent_complete",
   start_date: "start_date",
   est_end_date: "est_end_date",
};

export const fieldTranslationKeyMap: ColumnMap = {
   project_name: "views.company.workforce_planning.projects.name",
   color: "views.company.workforce_planning.projects.color",
   start_date: "views.company.workforce_planning.projects.start_date",
   est_end_date: "views.company.workforce_planning.projects.est_end_date",
   project_hash: "views.company.workforce_planning.projects.project_number",
   address_1: "views.company.workforce_planning.projects.address",
   address_2: "views.company.workforce_planning.projects.address_2",
   city_town: "views.company.workforce_planning.projects.city",
   state_province: "views.company.workforce_planning.projects.state",
   zipcode: "views.company.workforce_planning.projects.postal",
   tag_instances: "views.company.workforce_planning.projects.tags",
   project_type: "views.company.workforce_planning.projects.type",
   status: "views.company.workforce_planning.projects.status",
   groups: "views.company.workforce_planning.projects.groups",
   customer_name: "views.company.workforce_planning.projects.customer",
   created_by: "views.company.workforce_planning.projects.created_by",
   created_at: "views.company.workforce_planning.projects.created",
   country: "views.company.workforce_planning.projects.country",
   daily_start_time: "views.company.workforce_planning.projects.daily_start_time",
   daily_end_time: "views.company.workforce_planning.projects.daily_end_time",
   timezone: "views.company.workforce_planning.projects.timezone",
   bid_rate: "views.company.workforce_planning.projects.est_avg_rate",
   percent_complete: "views.company.workforce_planning.projects.percent_complete",
   wage_overrides: "views.company.workforce_planning.projects.wage_overrides",
   procore_id: "views.company.workforce_planning.projects.linked_to_procore",
};
