import type { StoreJsonResponse, StoreStreamResponse } from "@/stores/common/store.core";
import { Store } from "@/stores/common/store.core";
import type { AuthType, StreamedUpdate } from "@laborchart-modules/lc-core-api";
import type {
   FindProjectsPaginatedQueryParams,
   FindProjectsPaginatedResponse,
   FindProjectsStreamQueryParams,
   StreamProjectUpdatesPayload,
   UpdateProjectPayload,
   UpdateSingleProjectResponse,
} from "@laborchart-modules/lc-core-api/dist/api/projects";
import type {
   GetProjectDetailResponse,
   GetProjectResponse,
} from "@laborchart-modules/lc-core-api/dist/api/projects/get-project";
import type {
   FindProjectsListPaginatedResponse,
   SerializedProjectListProject,
} from "@laborchart-modules/lc-core-api/dist/api/projects/find-projects";
import type {
   DeleteProjectResponse,
   BatchDeleteProjectsPayload,
} from "@laborchart-modules/lc-core-api/dist/api/projects/delete-project";
import type {
   CreateProjectPayload,
   CreateProjectResponse,
} from "@laborchart-modules/lc-core-api/dist/api/projects/create-project";
import type {
   ReorderCostCodesPayload,
   ReorderCostCodeResponse,
} from "@laborchart-modules/lc-core-api/dist/api/projects/reorder-cost-codes";
import type {
   AddCostCodePayload,
   AddCostCodeResponse,
} from "@laborchart-modules/lc-core-api/dist/api/projects/add-cost-code";
import type {
   UpdateCostCodePayload,
   UpdateCostCodeResponse,
} from "@laborchart-modules/lc-core-api/dist/api/projects/update-cost-code";
import type { DeleteCostCodeResponse } from "@laborchart-modules/lc-core-api/dist/api/projects/delete-cost-code";
import type { DeleteCostCodeLabelResponse } from "@laborchart-modules/lc-core-api/dist/api/projects/delete-cost-code-label";
import type { GetProjectTagInstancesResponse } from "@laborchart-modules/lc-core-api/dist/api/projects/get-project-tag-instances";
import type { ColumnHeader } from "@/models/column-header";
import type { LegacyQueryFilters } from "./common/legacy-filters";
import type { SerializedProject } from "@laborchart-modules/common/dist/rethink/serializers/project-serializer";
import type { GetAppliedRoleOptionsResponse } from "@laborchart-modules/lc-core-api/dist/api/projects/get-applied-role-options";
import type { ResponsePayload } from "@laborchart-modules/common/dist/api/response-payload";
import type { SerializedBatchRequest } from "@laborchart-modules/lc-core-api/dist/api/requests/batch-request";
import type { SerializedBatchAssignment } from "@laborchart-modules/lc-core-api/dist/api/assignments/batch-assignments";
import type { GetProjectQrInfoResponse } from "@laborchart-modules/lc-core-api/dist/api/projects/get-project-qr-info";
import { authManager } from "@/lib/managers/auth-manager";

export abstract class ProjectStore extends Store {
   static deleteProject(projectId: string): StoreJsonResponse<DeleteProjectResponse> {
      return ProjectStore.requestJson({
         url: `/api/v3/projects/${projectId}`,
         method: "DELETE",
      });
   }

   static batchDelete(updates: BatchDeleteProjectsPayload): StoreStreamResponse<StreamedUpdate> {
      return ProjectStore.requestStream({
         url: "/api/v3/projects/batch-delete",
         method: "POST",
         body: updates,
      });
   }

   static createProject(project: CreateProjectPayload): StoreJsonResponse<CreateProjectResponse> {
      return this.requestJson({
         url: "/api/v3/projects",
         method: "POST",
         body: project,
      });
   }

   static findProjects(
      query: FindProjectsPaginatedQueryParams,
   ): StoreJsonResponse<FindProjectsPaginatedResponse> {
      return this.requestJson({
         url: "/api/v3/projects",
         method: "GET",
         query,
      });
   }

   static getProjectList(
      query: FindProjectsPaginatedQueryParams,
   ): StoreJsonResponse<FindProjectsListPaginatedResponse> {
      return this.requestJson({
         url: "/api/v3/project-list",
         method: "GET",
         query,
      });
   }

   static findProjectsStream(
      query: FindProjectsStreamQueryParams,
   ): StoreStreamResponse<SerializedProject> {
      return this.requestStream({
         method: "GET",
         query,
         url: "/api/v3/projects/stream",
      });
   }

   static findProjectListStream(
      query: FindProjectsStreamQueryParams,
   ): StoreStreamResponse<SerializedProjectListProject> {
      return this.requestStream({
         url: "/api/v3/project-list/stream",
         query,
         method: "GET",
      });
   }

   static getProject(projectId: string): StoreJsonResponse<GetProjectResponse> {
      return this.requestJson({ url: `/api/v3/projects/${projectId}` });
   }

   static getProjectDetails(projectId: string): StoreJsonResponse<GetProjectDetailResponse> {
      return this.requestJson({ url: `/api/v3/project-details/${projectId}` });
   }

   static getProjectTagInstances(
      projectId: string,
   ): StoreJsonResponse<GetProjectTagInstancesResponse> {
      return this.requestJson({ url: `/api/v3/projects/${projectId}/tag_instances` });
   }

   static updateProjectsStream(
      updates: StreamProjectUpdatesPayload<AuthType.SESSION>,
   ): StoreStreamResponse<StreamedUpdate> {
      return this.requestStream({
         url: "/api/v3/projects/stream",
         method: "PATCH",
         body: updates,
      });
   }

   static updateProject(
      projectId: string,
      update: UpdateProjectPayload<AuthType.SESSION>,
   ): StoreJsonResponse<UpdateSingleProjectResponse> {
      return this.requestJson({
         url: `/api/v3/projects/${projectId}`,
         method: "PATCH",
         body: update,
      });
   }

   static getProjectAppliedRoleOptions(
      groupId: string,
   ): StoreJsonResponse<GetAppliedRoleOptionsResponse> {
      if (groupId.trim().length === 0) groupId = "my-groups";

      return this.requestJson({
         url: `/api/v3/groups/${groupId}/projects/role-options`,
      });
   }

   static batchProjectAssignments(params: {
      projectIds: string[];
      showResourcesNotInGroup: boolean;
      dayFilter?: number;
   }): StoreJsonResponse<ResponsePayload<SerializedBatchAssignment[]>> {
      return this.requestJson({
         url: `/api/v3/projects/batch-assignments`,
         method: "POST",
         body: {
            project_ids: params.projectIds,
            day_filter: params.dayFilter,
            show_resources_not_in_group: params.showResourcesNotInGroup,
            ...(authManager.selectedGroupId() == "my-groups"
               ? {}
               : { group_id: authManager.selectedGroupId() }),
         },
      });
   }

   static batchProjectRequests(params: {
      projectIds: string[];
      showResourcesNotInGroup: boolean;
      requestPerDay: boolean;
      dayFilter?: number;
   }): StoreJsonResponse<ResponsePayload<SerializedBatchRequest[]>> {
      return this.requestJson({
         url: `/api/v3/projects/batch-requests`,
         method: "POST",
         body: {
            project_ids: params.projectIds,
            day_filter: params.dayFilter,
            show_resources_not_in_group: params.showResourcesNotInGroup,
            request_per_day: params.requestPerDay,
            ...(authManager.selectedGroupId() == "my-groups"
               ? {}
               : { group_id: authManager.selectedGroupId() }),
         },
      });
   }

   static shiftProject(
      projectId: string,
      startDate: string,
   ): StoreJsonResponse<ResponsePayload<any[]>> {
      return this.requestJson({
         url: `/api/v3/projects/${projectId}/shift`,
         method: "PATCH",
         body: { start_date: startDate },
      });
   }

   static getProjectAlertInfo(
      projectId: string,
      alertContext: string,
   ): StoreJsonResponse<ResponsePayload<any[]>> {
      return this.requestJson({
         url: `/api/v3/projects/${projectId}/alert-template?alert_context=${alertContext}`,
         method: "GET",
      });
   }

   static reorderCategories(
      projectId: string,
      payload: ReorderCostCodesPayload,
   ): StoreJsonResponse<ReorderCostCodeResponse> {
      return this.requestJson({
         url: `/api/v3/projects/${projectId}/categories/reorder`,
         method: "PATCH",
         body: payload,
      });
   }

   static addCategory(
      projectId: string,
      payload: AddCostCodePayload,
   ): StoreJsonResponse<AddCostCodeResponse> {
      return this.requestJson({
         url: `/api/v3/projects/${projectId}/categories`,
         method: "POST",
         body: payload,
      });
   }

   static updateCategory(
      projectId: string,
      categoryId: string,
      payload: UpdateCostCodePayload,
   ): StoreJsonResponse<UpdateCostCodeResponse> {
      return this.requestJson({
         url: `/api/v3/projects/${projectId}/categories/${categoryId}`,
         method: "PATCH",
         body: payload,
      });
   }

   static deleteCategory(
      projectId: string,
      categoryId: string,
   ): StoreJsonResponse<DeleteCostCodeResponse> {
      return this.requestJson({
         url: `/api/v3/projects/${projectId}/categories/${categoryId}`,
         method: "DELETE",
      });
   }

   static deleteSubcategory(
      projectId: string,
      categoryId: string,
      subcategoryId: string,
   ): StoreJsonResponse<DeleteCostCodeLabelResponse> {
      return this.requestJson({
         url: `/api/v3/projects/${projectId}/categories/${categoryId}/subcategories/${subcategoryId}`,
         method: "DELETE",
      });
   }

   static getProjectInfoForAssignmentNotification(
      projectId: string,
      resourceId: string,
      batchId: string,
      messageContext: string,
      requestId?: string,
   ): StoreJsonResponse<any> {
      let url = `/api/v3/projects/${projectId}/resources/${resourceId}/assignment-notification-info?batch_id=${batchId}&message_context=${messageContext}`;
      if (requestId) {
         url += `&request_id=${requestId}`;
      }
      return this.requestJson({
         url: url,
         method: "GET",
      });
   }

   static checkCostCodeForAssignments(
      projectId: string,
      costCodeId: string,
      dayFilter: string,
   ): StoreJsonResponse<ResponsePayload<any[]>> {
      return this.requestJson({
         url: `/api/v3/projects/${projectId}/cost-codes/${costCodeId}/assignments-check?day_filter=${dayFilter}`,
         method: "GET",
      });
   }

   // Used for refreshing project on RT.

   // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
   static getProjectSingleBoards(projectId: string, options: any): StoreJsonResponse<any> {
      const { dayFilter, ...newOptions } = options;
      newOptions.day_filter = dayFilter;
      return this.requestJson({
         url: `/api/v3/projects/boards-projects/${projectId}`,
         method: "GET",
         query: newOptions,
      });
   }

   static getProjectQrInfo(
      projectQrId: string,
      companyQrId: string,
   ): StoreJsonResponse<GetProjectQrInfoResponse> {
      return this.requestJson({
         url: `/api/v3/qr-companies/${companyQrId}/qr-projects/${projectQrId}`,
         method: "GET",
      });
   }
}

export type GetFilteredProjectsReportCsv = {
   timezone: string;
   sortBy?: string;
   sortAscending?: boolean;
   filters?: LegacyQueryFilters;
   search?: string | null;
   timeString: string;
   columnHeaders: ColumnHeader[];
   dateFormat: string;
};

export type GetFilteredProjectsReportPdf = {
   timezone: string;
   sortBy?: string;
   sortAscending?: boolean;
   filters?: LegacyQueryFilters;
   search?: string | null;
   timeString: string;
   columnHeaders: ColumnHeader[];
   paper_size: { width: number; height: number };
   dateFormat: string;
};
