export enum FindPeopleSortBy {
   ADDRESS_1 = "address_1",
   ADDRESS_2 = "address_2",
   CAN_RECIEVE_EMAIL = "can_recieve_email",
   CAN_RECIEVE_MOBILE = "can_recieve_mobile",
   CAN_RECIEVE_SMS = "can_recieve_sms",
   CITY_TOWN = "city_town",
   COUNTRY = "country",
   CUSTOM_FIELD = "custom_field",
   DOB = "dob",
   EMAIL = "email",
   EMPLOYEE_NUMBER = "employee_number",
   HAS_PROCORE_MAPPING = "has_procore_mapping",
   HIRED_DATE = "hired_date",
   HOURLY_WAGE = "hourly_wage",
   JOB_TITLE = "position",
   LANGUAGE = "language",
   PERMISSION = "permission",
   PERSON_NAME_FIRST = "person_name_first",
   PERSON_NAME_LAST = "person_name_last",
   PERSON_STATUS = "status",
   PHONE = "phone",
   STATE_PROVINCE = "state_province",
   ZIPCODE = "zipcode",
}
