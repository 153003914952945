import type { SortModel, StreamResponseConversion } from "@/react/prop-types";
import { convertDateFilters } from "@/react/shared/custom-field-utils";
import type { Filter } from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/common";
import type { FilterClassifier } from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/enums/common";
import { FilterFieldType } from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/enums/common";
import type { DataTableConfig } from "@procore/data-table";
import {
   convertNestedCustomFilters,
   createDefaultColumnState,
   sortOrderMap,
} from "../data-table/helpers";
import { FindPeopleSortBy } from "./people-list-enums";
import type { CoreApiSort, PeopleListServerFilter } from "./people-list-prop-types";

const NUMERIC_FIELDS: any[] = [];
const NESTED_FIELDS_TYPE: any[] = [FilterFieldType.BOOL, FilterFieldType.MULTI_SELECT];
const NUMERIC_FIELDS_TYPE: any[] = [FilterFieldType.NUMBER, FilterFieldType.CURRENCY];

export function convertDataTableSort(sort: SortModel[]): CoreApiSort {
   if (sort.length == 0) return {};
   const sortObj: CoreApiSort = {
      sort_by: sortFieldMaps[sort[0].field],
      direction: sortOrderMap[sort[0].sort],
   };
   // If the sort field is custom_field, then add the custom_field_id
   // fieldId will only be there in case of custom_field, it has been added explicitly to identify the custom_field
   if (sort[0].fieldId) {
      sortObj["sort_by"] = sortFieldMaps["custom_field"];
      sortObj["custom_field_id"] = sort[0].fieldId;
   }
   return sortObj;
}

export const defaultPeopleListTableConfig: DataTableConfig = {
   columnState: [
      {
         ...createDefaultColumnState(),
         field: "person",
         sort: "asc",
         sortIndex: 0,
      },
      {
         ...createDefaultColumnState(),
         field: "employee_number",
         width: 240,
      },
      {
         ...createDefaultColumnState(),
         field: "permission",
         width: 230,
      },
      {
         ...createDefaultColumnState(),
         field: "job_title",
         width: 250,
      },
      {
         ...createDefaultColumnState(),
         field: "address_1",
         width: 368,
      },
      {
         ...createDefaultColumnState(),
         field: "address_2",
         width: 368,
         hidden: true,
      },
      {
         ...createDefaultColumnState(),
         field: "city_town",
         width: 140,
      },
      {
         ...createDefaultColumnState(),
         field: "state_province",
         width: 140,
      },
      {
         ...createDefaultColumnState(),
         field: "zipcode",
         width: 140,
      },
      {
         ...createDefaultColumnState(),
         field: "country",
         width: 200,
      },
      {
         ...createDefaultColumnState(),
         field: "tag_instances",
         width: 140,
      },
      {
         ...createDefaultColumnState(),
         field: "status",
         width: 140,
      },
      {
         ...createDefaultColumnState(),
         field: "email",
         width: 140,
      },
      {
         ...createDefaultColumnState(),
         field: "hourly_wage",
         width: 140,
      },
      {
         ...createDefaultColumnState(),
         field: "notification_profile",
      },
      {
         ...createDefaultColumnState(),
         field: "phone",
         width: 200,
      },
      {
         ...createDefaultColumnState(),
         field: "gender",
         width: 200,
      },
      {
         ...createDefaultColumnState(),
         field: "language",
         width: 230,
      },
      {
         ...createDefaultColumnState(),
         field: "hired_date",
         width: 200,
      },
      {
         ...createDefaultColumnState(),
         field: "dob",
         width: 200,
      },
      {
         ...createDefaultColumnState(),
         field: "receive_email",
         width: 100,
      },
      {
         ...createDefaultColumnState(),
         field: "receive_mobile",
         width: 100,
         hidden: true,
      },
      {
         ...createDefaultColumnState(),
         field: "receive_sms",
         width: 100,
      },
      {
         ...createDefaultColumnState(),
         field: "groups",
         width: 140,
      },
      {
         ...createDefaultColumnState(),
         field: "linked_to_procore",
         width: 50,
         hidden: true,
      },
   ],
   columnGroupState: [],
   filters: {},
   rowHeight: 49,
   serverFilters: [],
};

export function filterGroupDetails({
   groupOptions,
   personGroupList,
}: {
   groupOptions: StreamResponseConversion | null;
   personGroupList: string[];
}) {
   // Filter the groupOptions based on the personGroupList
   const filtered = groupOptions?.filter((groupOption) => personGroupList.includes(groupOption.id));
   return filtered;
}

export function convertDataTableFilters(filters: PeopleListServerFilter[]): Filter[] {
   const coreApiFilters: Filter[] = [];

   //filter.fieldId and filter.fieldType are added to identify the custom_field
   for (const filter of filters) {
      if (
         filter.field === "hired_date" ||
         (filter.fieldId && filter.fieldType === FilterFieldType.DATE)
      ) {
         const dateFilters = convertDateFilters(filter, filterNameMaps, filterTypeMaps);
         coreApiFilters.push(...dateFilters);
         continue;
      }

      if (
         (NUMERIC_FIELDS.length && NUMERIC_FIELDS.includes(filter.field)) ||
         (filter.fieldId && NUMERIC_FIELDS_TYPE.includes(filter.fieldType!))
      ) {
         const numericFilters = convertNumericFilters(filter);
         coreApiFilters.push(...numericFilters);
         continue;
      }

      if (filter.fieldId && NESTED_FIELDS_TYPE.includes(filter.fieldType!)) {
         const nestedFilters = convertNestedCustomFilters(filter, filterTypeMaps);
         coreApiFilters.push(...nestedFilters);
         continue;
      }

      //default custom filter method for field type text, select
      if (filter.fieldId) {
         const customFilters = convertCustomFilters(filter);
         coreApiFilters.push(...customFilters);
         continue;
      }
      // if none of the conditions met then default creation of filter
      coreApiFilters.push({
         name: filterNameMaps[filter.field],
         property: filter.field,
         type: filterTypeMaps[filter.field],
         value_sets:
            filterTypeMaps[filter.field] !== "multi-select"
               ? filter.selected.map((x: any) => ({ negation: false, value: x.value ?? x.id }))
               : [
                    {
                       negation: false,
                       value: filter.selected.map((x: any) => x.value ?? x.id) as string[],
                    },
                 ],
      });
   }

   return coreApiFilters;
}

function convertNumericFilters(filter: PeopleListServerFilter): Filter[] {
   const numericFilters: Filter[] = [];
   filter.selected.forEach(
      (x: { value: number; classifier: { label: string; value: FilterClassifier } }) => {
         const filterObject: Filter = {
            name: filter.fieldId ? filter.fieldId : filterNameMaps[filter.field],
            property: filter.fieldId ? "custom_fields" : filter.field,
            type: filterTypeMaps[filter.fieldType ? filter.fieldType : filter.field],
            value_sets: [
               {
                  negation: false,
                  value: x.value,
                  classifier: x.classifier.value,
               },
            ],
         };
         if (filter.fieldId) {
            filterObject.custom_field_id = filter.fieldId;
         }
         numericFilters.push(filterObject);
      },
   );
   return numericFilters;
}

function convertCustomFilters(filter: PeopleListServerFilter): Filter[] {
   const customFilters: Filter[] = [];
   filter.selected.forEach((x: { label: string; value: boolean }) => {
      customFilters.push({
         name: filter.fieldId!,
         property: "custom_fields",
         type: filterTypeMaps[filter.fieldType!],
         value_sets: [
            {
               negation: false,
               value: x.value ?? x,
            },
         ],
         custom_field_id: filter.fieldId,
      });
   });
   return customFilters;
}

// Filter Name Maps
export const filterNameMaps: { [key: string]: string } = {
   start_date: "People Start Date",
   end_date: "People End Date",
   hired_date: "People Hired Date",
   status: "People Status",
   tag_instances: "People Tags",
};

// Filter Type Maps
const filterTypeMaps: { [key: string]: FilterFieldType } = {
   start_date: FilterFieldType.DATE,
   end_date: FilterFieldType.DATE,
   hired_date: FilterFieldType.DATE,
   status: FilterFieldType.SELECT,
   tag_instances: FilterFieldType.MULTI_SELECT,
   date: FilterFieldType.DATE,
   bool: FilterFieldType.BOOL,
   currency: FilterFieldType.CURRENCY,
   "multi-select": FilterFieldType.MULTI_SELECT,
   number: FilterFieldType.NUMBER,
   select: FilterFieldType.SELECT,
   text: FilterFieldType.TEXT,
};

// Sort Field Maps
const sortFieldMaps: { [key: string]: FindPeopleSortBy } = {
   person_name: FindPeopleSortBy.PERSON_NAME_FIRST,
   permission: FindPeopleSortBy.PERMISSION,
   job_title: FindPeopleSortBy.JOB_TITLE,
   status: FindPeopleSortBy.PERSON_STATUS,
   email: FindPeopleSortBy.EMAIL,
   hourly_wage: FindPeopleSortBy.HOURLY_WAGE,
   address_1: FindPeopleSortBy.ADDRESS_1,
   address_2: FindPeopleSortBy.ADDRESS_2,
   city: FindPeopleSortBy.CITY_TOWN,
   state: FindPeopleSortBy.STATE_PROVINCE,
   postal: FindPeopleSortBy.ZIPCODE,
   hired_date: FindPeopleSortBy.HIRED_DATE,
   language: FindPeopleSortBy.LANGUAGE,
   employee_number: FindPeopleSortBy.EMPLOYEE_NUMBER,
   phone: FindPeopleSortBy.PHONE,
   // gender: FindPeopleSortBy.GENDER,
   birth_date: FindPeopleSortBy.DOB,
   receive_email: FindPeopleSortBy.CAN_RECIEVE_EMAIL,
   receive_mobile: FindPeopleSortBy.CAN_RECIEVE_MOBILE,
   receive_sms: FindPeopleSortBy.CAN_RECIEVE_SMS,
   custom_field: FindPeopleSortBy.CUSTOM_FIELD,
};
