import { getDetachedDay } from "@laborchart-modules/common/dist/datetime";
import type { Filter } from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/common";
import type { FilterClassifier } from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/enums/common";
import { FilterFieldType } from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/enums/common";
import { MultiSelectFilterRenderer } from "@procore/data-table";
import type { IServerFilter, DataTableConfig } from "@procore/data-table";
import { FindTimeOffSortBy } from "@laborchart-modules/common/dist/reql-builder/procedures/enums/find-time-off";
import { Order } from "@laborchart-modules/common/dist/reql-builder/query-definitions";
import type { CoreApiSort } from "./time-off-list-prop-types";
import type { SortModel } from "@/react/prop-types";
import { HidePastTimeOffFilter } from "./custom-filters/hide-past-time-off-filter";
import { StatusFilter } from "../data-table/custom-filters/status-filter";
import { DateFilter } from "../data-table/custom-filters/date-filter";

export function convertDataTableSort(
   sort: SortModel[],
   isLastNameFirst: boolean = false,
): CoreApiSort {
   sortFieldMaps.workers_name = isLastNameFirst
      ? FindTimeOffSortBy.NAME_LAST
      : FindTimeOffSortBy.NAME_FIRST;
   if (sort.length == 0) return {};
   return {
      sort_by: sortFieldMaps[sort[0].field],
      direction: sortOrderMap[sort[0].sort],
   };
}

export function convertDataTableFilters(filters: IServerFilter[]): Filter[] {
   const coreApiFilters: Filter[] = [];
   filters.forEach((filter: IServerFilter) => {
      if (filter.field == "start_date" || filter.field == "end_date") {
         const dateFilters = convertDateFilters(filter);
         coreApiFilters.push(...dateFilters);
      } else {
         coreApiFilters.push({
            name: filterNameMaps[filter.field],
            property: filter.field,
            type: filterTypeMaps[filter.field],
            value_sets: filter.selected.map((x: any) => {
               return {
                  negation: false,
                  value: x.value ?? x.id,
               };
            }),
         });
      }
   });

   return coreApiFilters;
}

export const defaultTimeOffTableConfig: DataTableConfig = {
   columnGroupState: [],
   columnState: [
      {
         field: "workers_name",
         hidden: false,
         rowGroup: false,
         sort: "asc",
         sortIndex: 0,
         width: 140,
         pinned: false,
         rowGroupIndex: null,
      },
      {
         field: "job_titles",
         hidden: false,
         rowGroup: false,
         width: 200,
         sort: null,
         sortIndex: null,
         pinned: false,
         rowGroupIndex: null,
      },
      {
         field: "start_date",
         hidden: false,
         rowGroup: false,
         width: 140,
         sort: null,
         sortIndex: null,
         pinned: false,
         rowGroupIndex: null,
      },
      {
         field: "end_date",
         hidden: false,
         rowGroup: false,
         width: 140,
         sort: null,
         sortIndex: null,
         pinned: false,
         rowGroupIndex: null,
      },
      {
         field: "start_time",
         hidden: false,
         rowGroup: false,
         width: 140,
         sort: null,
         sortIndex: null,
         pinned: false,
         rowGroupIndex: null,
      },
      {
         field: "end_time",
         hidden: false,
         rowGroup: false,
         width: 140,
         sort: null,
         sortIndex: null,
         pinned: false,
         rowGroupIndex: null,
      },
      {
         field: "reason",
         hidden: false,
         rowGroup: false,
         width: 140,
         sort: null,
         sortIndex: null,
         pinned: false,
         rowGroupIndex: null,
      },
      {
         field: "repeat",
         hidden: false,
         rowGroup: false,
         width: 140,
         sort: null,
         sortIndex: null,
         pinned: false,
         rowGroupIndex: null,
      },
      {
         field: "occurrences",
         hidden: false,
         rowGroup: false,
         width: 140,
         sort: null,
         sortIndex: null,
         pinned: false,
         rowGroupIndex: null,
      },
      {
         field: "submitted_on",
         hidden: false,
         rowGroup: false,
         width: 200,
         sort: null,
         sortIndex: null,
         pinned: false,
         rowGroupIndex: null,
      },
      {
         field: "hide_past_time_off",
         hidden: true,
         rowGroup: false,
         width: 200,
         sort: null,
         sortIndex: null,
         pinned: false,
         rowGroupIndex: null,
      },
   ],
   filters: {},
   rowHeight: 49,
   serverFilters: [
      {
         field: "hide_past_time_off",
         filterRenderer: HidePastTimeOffFilter,
         selected: [
            {
               value: true,
            },
         ],
         value: [
            {
               value: true,
            },
         ] as any,
      },
      {
         field: "person_stauts",
         filterRenderer: StatusFilter as any,
         selected: [
            {
               id: 1,
               label: "Active",
               value: "active",
            },
         ],
         value: [1],
      },
   ],
};

function convertDateFilters(filter: IServerFilter): Filter[] {
   const dateFilters: Filter[] = [];
   filter.selected.forEach(
      (x: { date: string; classifier: { label: string; value: FilterClassifier } }) => {
         dateFilters.push({
            name: filterNameMaps[filter.field],
            property: filter.field,
            type: filterTypeMaps[filter.field],
            value_sets: [
               {
                  negation: false,
                  value: getDetachedDay(new Date(x.date)),
                  classifier: x.classifier.value,
               },
            ],
         });
      },
   );
   return dateFilters;
}

export const filterNameMaps: { [key: string]: string } = {
   reason: "Reason",
   start_date: "Time Off Start Date",
   end_date: "Time Off End Date",
   hide_past_time_off: "Hide Past Time Off",
   person_status: "Person Status",
   job_title: "Person Job Title",
};

const filterTypeMaps: { [key: string]: FilterFieldType } = {
   reason: FilterFieldType.SELECT,
   start_date: FilterFieldType.DATE,
   end_date: FilterFieldType.DATE,
   hide_past_time_off: FilterFieldType.SELECT,
   person_status: FilterFieldType.SELECT,
   job_title: FilterFieldType.MULTI_SELECT,
};

const sortFieldMaps: { [key: string]: FindTimeOffSortBy } = {
   workers_name: FindTimeOffSortBy.NAME_FIRST,
   job_title: FindTimeOffSortBy.JOB_TITLE,
   start_date: FindTimeOffSortBy.START_DATE,
   end_date: FindTimeOffSortBy.END_DATE,
   daily_start_time: FindTimeOffSortBy.START_TIME,
   daily_end_time: FindTimeOffSortBy.END_TIME,
   reason: FindTimeOffSortBy.REASON,
   type: FindTimeOffSortBy.TYPE,
   repeat: FindTimeOffSortBy.REPEAT,
   occurences: FindTimeOffSortBy.OCCURRENCES,
   submitted_on: FindTimeOffSortBy.SUBMITTED,
};

const sortOrderMap: { [key: string]: Order } = {
   asc: Order.ASCENDING,
   desc: Order.DESCENDING,
};

export const columnHeadersMap: { [key: string]: string } = {
   person_name: "workers_name",
   time_off_start_day: "start_date",
   time_off_end_day: "end_date",
   batch_start_time: "daily_start_time",
   batch_end_time: "daily_end_time",
   reason: "reason",
   is_paid: "type",
   instance_count: "occurences",
   created_at: "submitted_on",
   position: "job_title",
   repeat: "repeat",
   person_status: "person_status",
};

export const filterRendererMap: { [key: string]: any } = {
   hide_past_time_off: HidePastTimeOffFilter,
   person_status: StatusFilter,
   reason: MultiSelectFilterRenderer,
   job_title: MultiSelectFilterRenderer,
   start_date: DateFilter,
   end_date: DateFilter,
};

export const filterFieldMap: { [key: string]: string } = {
   hide_past_time_off: "hide_past_time_off",
   person_status: "person_status",
   reason: "reason",
   job_title: "job_title",
   start_date: "start_date",
   end_date: "end_date",
};
