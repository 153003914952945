import React, { useState } from "react";
import type {
   CustomField,
   TagOption,
   StreamResponseConversion,
   JobTitle,
} from "@/react/prop-types";
import { TagStore } from "@/stores/tag-store.core";
import { CustomFieldStore } from "@/stores/custom-field-store.core";
import type { CustomFieldEntity } from "@laborchart-modules/common/dist/rethink/schemas/enums/custom-fields";
import { CompanyStore } from "@/stores/company-store.core";
import type { GetIntegratedFieldsResponse } from "@laborchart-modules/lc-core-api/dist/api/company/get-integrated-fields";
import { PersonStore } from "@/stores/person-store.core";
import { PersonStatus } from "@laborchart-modules/common/dist/rethink/schemas/enums/people";
import { SavedViewStore } from "@/stores/saved-view-store.core";
import type { GetSavedViewResponse } from "@laborchart-modules/lc-core-api/dist/api/saved-views/get-saved-view";
import type { GetCompanyResponse } from "@laborchart-modules/lc-core-api/dist/api/company/get-company";
import { PositionStore } from "@/stores/position-store.core";
import { PermissionStore } from "@/stores/permission-store.core";

export function useGetTagOptionsQuery() {
   const [data, setData] = useState<TagOption[] | null>(null);
   const [isLoading, setIsLoading] = useState(true);

   React.useEffect(() => {
      async function getTags() {
         setIsLoading(true);
         setData(null);

         const tags = [];
         const stream = await TagStore.findTagsStream({}).stream;
         if (stream) {
            for await (const {
               id,
               abbreviation,
               name,
               color,
               categories,
               group_ids,
               globally_accessible,
            } of stream) {
               tags.push({
                  id,
                  label: abbreviation,
                  name,
                  color,
                  categories,
                  group_ids,
                  globally_accessible,
               });
            }

            setData(tags);
            setIsLoading(false);
         }
      }
      getTags();
   }, []);

   return { data, isLoading };
}

export function useGetCustomFields(entityType: CustomFieldEntity) {
   const [data, setData] = React.useState<CustomField[] | null>(null);
   const [isLoading, setIsLoading] = React.useState(false);

   React.useEffect(() => {
      async function getCustomFields() {
         setIsLoading(true);
         setData(null);

         const customField: CustomField[] = [];
         const stream = await CustomFieldStore.findCustomFieldsStream({
            is_on_entities: [entityType],
         }).stream;
         for await (const field of stream) {
            customField.push(field);
         }
         setData(customField);
         setIsLoading(false);
      }
      getCustomFields();
   }, []);

   return { data, isLoading };
}

export function useGetIntegratedFields() {
   const [data, setData] = React.useState<GetIntegratedFieldsResponse | null>(null);
   const [isLoading, setIsLoading] = React.useState(true);

   React.useEffect(() => {
      async function getIntegratedFields() {
         setIsLoading(true);
         setData(null);

         const data = await CompanyStore.getIntegratedFields().payload;

         setData(data);
         setIsLoading(false);
      }
      getIntegratedFields();
   }, []);

   return { data, isLoading };
}
export function useGetPeopleStream(group_id?: string) {
   const [data, setData] = React.useState<StreamResponseConversion | null>(null);
   const [isLoading, setIsLoading] = React.useState(true);
   const params = { group_id, status: PersonStatus.ACTIVE };

   React.useEffect(() => {
      async function getPeopleStream() {
         setIsLoading(true);
         setData(null);

         const stream = await PersonStore.findPeopleStream(params).stream;

         const data = [];

         for await (const item of stream) {
            data.push({
               id: item.id,
               label: `${item.name.first} ${item.name.last}`,
            });
         }
         setData(data);
         setIsLoading(false);
      }
      getPeopleStream();
   }, []);

   return { data, isLoading };
}

export function useGetSavedView(viewId: string | null) {
   const [data, setData] = React.useState<GetSavedViewResponse | null>(null);
   const [isLoading, setIsLoading] = React.useState(true);

   React.useEffect(() => {
      async function getGetSavedView() {
         if (!viewId) {
            setIsLoading(false);
            return;
         }
         setIsLoading(true);
         setData(null);

         const data = await SavedViewStore.getSavedView(viewId).payload;

         setData(data);
         setIsLoading(false);
      }
      getGetSavedView();
   }, [viewId]);
   return { data, isLoading };
}
export function useGetCompany() {
   const [data, setData] = React.useState<GetCompanyResponse | null>(null);
   const [isLoading, setIsLoading] = React.useState(true);

   React.useEffect(() => {
      async function getCompany() {
         setIsLoading(true);
         setData(null);

         const data = await CompanyStore.getCompany().payload;

         setData(data);
         setIsLoading(false);
      }
      getCompany();
   }, []);
   return { data, isLoading };
}

export function useGetJobTitles(groupId?: string) {
   const params: { group_id?: string } = {};
   if (groupId != "my-groups") {
      params.group_id = groupId;
   }
   const [data, setData] = React.useState<JobTitle[]>([]);
   const [isLoading, setIsLoading] = React.useState(true);

   React.useEffect(() => {
      async function getPositions() {
         setIsLoading(true);
         setData([]);

         const stream = await PositionStore.findPositionsStream(params).stream;

         const jobTitles: JobTitle[] = [];

         for await (const jobTitle of stream) {
            jobTitles.push({
               id: jobTitle.id,
               label: jobTitle.name,
            });
         }
         setData(jobTitles);
         setIsLoading(false);
      }
      getPositions();
   }, []);

   return { data, isLoading };
}

export function useGetPermissionLevelOptions() {
   const [data, setData] = React.useState<Array<{ id: string; label: string; is_admin: boolean }>>(
      [],
   );
   const [isLoading, setIsLoading] = React.useState(true);

   React.useEffect(() => {
      async function streamPermissionLevels() {
         const permissions: Array<{ id: string; label: string; is_admin: boolean }> = [];
         const stream = await PermissionStore.findPermissionLevelsStream({}).stream;
         for await (const permissionLevel of stream) {
            permissions.push({
               id: permissionLevel.id,
               label: permissionLevel.name,
               is_admin: permissionLevel.is_admin,
            });
         }
         setData(permissions);
         setIsLoading(false);
      }
      streamPermissionLevels();
   }, []);

   return { data, isLoading };
}
