import React, { useEffect, useState } from "react";
import {
   Box,
   Button,
   Card,
   EmptyState,
   FlexList,
   H2,
   Spinner,
   useI18nContext,
} from "@procore/core-react";
import type { SerializedAttachment } from "@laborchart-modules/common/dist/rethink/serializers/attachment-serializer";
import { AttachmentLink } from "@/react/shared/components/attachment-links";
import { usePermissionContext, AuthAction } from "@/react/providers/permission-context-provider";
import { useModal } from "@/react/shared/hooks/useModal";
import { useToastAlertContext } from "@procore/toast-alert";
import { AddAttachments } from "./project-details-add-attachments";
import type {
   EmptyAttachmentProps,
   ListAttachmentsCardProps,
   ProjectAttachmentsData,
   ProjectDetailsCardProps,
} from "../types";
import { ActionMode } from "@/react/prop-types";
import { FileDownloadModal } from "@/react/shared/modals/file-download-modal";
import { getDateString } from "../helpers";
import { DetailsCardHeader } from "@/react/shared/components/details-card-header";

const EmptyAttachments: React.FC<EmptyAttachmentProps> = ({
   openAddAttachmentModal,
   isAddAttachmentOpen,
}) => {
   const I18n = useI18nContext();
   if (isAddAttachmentOpen) {
      return null;
   }
   return (
      <React.Fragment>
         <Box padding="md">
            <FlexList justifyContent="space-between">
               <H2>
                  {I18n.t("views.company.workforce_planning.project_detail_attachments.title")}
               </H2>
            </FlexList>
         </Box>
         <Box>
            <EmptyState compact>
               <EmptyState.Title>
                  {I18n.t(
                     "views.company.workforce_planning.project_detail_attachments.attachments_empty_state_title",
                  )}
               </EmptyState.Title>
               <EmptyState.Description className="empty-state-description">
                  {I18n.t(
                     "views.company.workforce_planning.project_detail_attachments.attachments_empty_state_description",
                  )}
               </EmptyState.Description>
               <EmptyState.Actions className="empty-state-actions">
                  <Button variant="secondary" onClick={openAddAttachmentModal}>
                     {I18n.t(
                        "views.company.workforce_planning.project_detail_attachments.add_attachment",
                     )}
                  </Button>
               </EmptyState.Actions>
            </EmptyState>
         </Box>
      </React.Fragment>
   );
};

const ListAttachmentsCard: React.FC<ListAttachmentsCardProps> = ({
   attachments,
   openAddAttachmentItemModal,
   isAddAttachmentOpen,
   canEditAttachments,
}) => {
   const [selectedAttachment, setSelectedAttachment] = useState<any>(null);
   const { isOpen, openModal, closeModal } = useModal();

   const handleModalClose = () => {
      setSelectedAttachment(null);
      closeModal();
   };

   const handleDownloadLinkClick = (attachment: SerializedAttachment) => {
      setSelectedAttachment(attachment);
      openModal();
   };

   if (isAddAttachmentOpen) {
      return null;
   }

   return (
      <React.Fragment>
         <DetailsCardHeader
            titleKey="views.company.workforce_planning.project_detail_attachments.title"
            helpTextKey="views.company.workforce_planning.project_detail_attachments.subtitle"
            buttonTextKey="views.company.workforce_planning.edit"
            openAddItemModal={openAddAttachmentItemModal}
            btnDisabled={false}
            permission={canEditAttachments && !isAddAttachmentOpen}
            icon={null}
         />
         {!isAddAttachmentOpen && (
            <Box padding="md">
               {attachments && attachments.length > 0 && (
                  <AttachmentLink attachments={attachments} onLinkClick={handleDownloadLinkClick} />
               )}
            </Box>
         )}
         <Box padding="xxs" />
         {selectedAttachment && (
            <FileDownloadModal
               open={isOpen}
               onClose={handleModalClose}
               fileName={selectedAttachment.name}
               uploadedOn={getDateString(new Date(selectedAttachment.created_at))}
               attachmentId={selectedAttachment.id}
               mimeType={selectedAttachment.mimetype}
            />
         )}
      </React.Fragment>
   );
};

export const ProjectDetailsAttachmentsCard: React.FC<ProjectDetailsCardProps> = ({
   projectData,
   refetchData,
   updateProjectData,
   loading: projectDataLoading,
}) => {
   const I18n = useI18nContext();
   const { showToast } = useToastAlertContext();
   const { checkAuthAction } = usePermissionContext();
   const [canEditProjectAttachments, setCanEditProjectAttachments] = useState<boolean>(false);
   const [loading, setLoading] = useState<boolean>(false);
   const { openModal, closeModal, isOpen } = useModal();

   useEffect(() => {
      const canEditProjectAttachments = checkAuthAction(AuthAction.EDIT_PROJECT_ATTACHMENTS);
      setCanEditProjectAttachments(canEditProjectAttachments);
   }, [checkAuthAction]);

   const handleAttachmentCreation = async (payload: ProjectAttachmentsData, mode: string) => {
      setLoading(true);
      try {
         const updatedProject = await updateProjectData(payload);
         if (updatedProject.data && mode === ActionMode.SAVE) {
            refetchData();
         }
         closeModal();
      } catch (error) {
         showToast.error(I18n.t("views.company.workforce_planning.error"));
      } finally {
         setLoading(false);
      }
   };
   return (
      <React.Fragment>
         {(canEditProjectAttachments ||
            (projectData && projectData.data?.attachments.length > 0)) && (
            <Card style={{ marginBottom: "5px" }}>
               <Spinner loading={projectDataLoading}>
                  {canEditProjectAttachments &&
                     projectData &&
                     projectData?.data?.attachments.length === 0 && (
                        <EmptyAttachments
                           openAddAttachmentModal={openModal}
                           isAddAttachmentOpen={isOpen}
                        />
                     )}
                  {projectData && projectData?.data?.attachments.length > 0 && (
                     <ListAttachmentsCard
                        attachments={projectData?.data?.attachments}
                        openAddAttachmentItemModal={openModal}
                        isAddAttachmentOpen={isOpen}
                        canEditAttachments={canEditProjectAttachments}
                     />
                  )}
                  {canEditProjectAttachments && (
                     <AddAttachments
                        availableAttachments={projectData?.data?.attachments}
                        open={isOpen}
                        onClose={closeModal}
                        handleCreate={handleAttachmentCreation}
                        showLoader={loading}
                     />
                  )}
               </Spinner>
            </Card>
         )}
      </React.Fragment>
   );
};
