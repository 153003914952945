import { DateUtils } from "@/lib/utils/date";
import { defaultStore } from "@/stores/default-store";
import type { SerializedFindAssignment } from "@laborchart-modules/lc-core-api/dist/api/assignments";
export function getCurrentAssignmentsFormatted(currentAssignments: SerializedFindAssignment[]) {
   return currentAssignments?.map((assignmentDetails) => ({
      assignmentId: assignmentDetails.id,
      formattedAssignemntDetails: `${assignmentDetails.project.name}, ${DateUtils.formatDetachedDay(
         assignmentDetails.start_day,
         defaultStore.getDateFormat(),
      )} - ${DateUtils.formatDetachedDay(assignmentDetails.end_day, defaultStore.getDateFormat())}`,
   }));
}
