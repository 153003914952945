import { timeOptions } from "@/lib/utils/timezones";
import { type FormikHelpers, type FormikProps } from "formik";
import type {
   CreateAssignmentTearsheetProps,
   CustomField,
   FormattedOption,
} from "@/react/prop-types";
import { ActionMode } from "@/react/prop-types";
import {
   Page,
   H1,
   Box,
   Flex,
   Required,
   FlexList,
   Button,
   Form,
   Card,
   Title,
   useI18nContext,
   Spinner,
   H2,
} from "@procore/core-react";
import type { FC } from "react";
import React, { useEffect } from "react";

import { assignmentSchema } from "./validation-schemas";
import { useGroupContext } from "@/react/providers/group-context-provider";
import { useGetAssignmentCreationSupportDataQuery } from "./queries";
import { CustomFieldEntity } from "@laborchart-modules/common/dist/rethink/schemas/enums/custom-fields";
import { useGetCustomFields } from "../../common/queries/queries";
import { WorkDaysCheckboxes } from "../../labor-plans/work-days-checkboxes";
import type { WorkdayOptions } from "../../labor-plans/prop-types";
import { AssignmentSwitch } from "./assignment-switch";
import {
   AuthAction,
   usePermissionContext,
   useViewPreferenceContext,
} from "@/react/providers/permission-context-provider";
import { formatOptions } from "@/react/shared/helper";
import {
   DropDownType,
   type Assignment,
   type AssignmentFormValues,
   type BaggageTimeKey,
   type ProjectOptions,
} from "./types";
import { getDetachedDay } from "@laborchart-modules/common/dist/datetime";
import { Assignment2Store } from "@/stores/assignment-2-store.core";
import { useToastAlertContext } from "@procore/toast-alert";
import { DEFAULT_END_TIME, DEFAULT_START_TIME, workDays } from "@/react/shared/constants";
import { CurrentAssignmentsBanner } from "./current-assignments-banner";
import type { BannerVariant } from "@procore/core-react/dist/Banner/Banner.types";
import {
   processCustomFieldsFormValues,
   processCustomFieldValue,
   renderCustomFields,
} from "@/react/shared/custom-field-utils";
import { formInitialValues, PLACEHOLDER_KEYS } from "./constants";
import type { View } from "@procore/core-react/dist/Form/Form.types";
import type { GetAssignmentDetailReponse } from "@laborchart-modules/lc-core-api/dist/api/assignments";
import { DateUtils } from "@/lib/utils/date";

export const AssignmentTearsheetContent: FC<CreateAssignmentTearsheetProps> = ({
   onClose,
   showProjectDetail, // its an optional property so it can be undefined will be defined when create Assignment button is clicked from project detail page
   projectId, // its an optional property so it can be undefined will be defined when create Assignment button is clicked from project detail page
   assignmentId, // its an optional property so it can be undefined will be defined in the edit Assignment flow
}) => {
   const { groupId } = useGroupContext();
   const { showToast } = useToastAlertContext();
   const I18n = useI18nContext();
   const { checkAuthAction } = usePermissionContext();
   const { data: assignmentCreationSupportData, isLoading: supportDataLoading } =
      useGetAssignmentCreationSupportDataQuery(groupId);
   const { data: customFields } = useGetCustomFields(CustomFieldEntity.ASSIGNMENT);
   const [categoryOptions, setCategoryOptions] = React.useState<FormattedOption[]>([]);
   const [subCategoryOptions, setSubCategoryOptions] = React.useState<FormattedOption[]>([]);
   const [categoryPlaceHolder, setCategoryPlaceHolder] = React.useState<string>(
      PLACEHOLDER_KEYS.category.project_selected_first,
   );
   const [subCategoryPlaceHolder, setSubCategoryPlaceHolder] = React.useState<string>(
      PLACEHOLDER_KEYS.subCategory.category_selected_first,
   );

   const [selectedWorkDays, setSelectedWorkDays] = React.useState<WorkdayOptions>(workDays);

   const [assignmentByTime, setAssignmentByTime] = React.useState(true);
   const [assignmentByAllocation, setAssignmentByAllocation] = React.useState(false);
   const [canViewAllStatuses, setCanViewAllStatuses] = React.useState<boolean>(false);
   const [loading, setLoading] = React.useState<boolean>(false);
   const [filterIntegrationOnly, setFilterIntegrationOnly] = React.useState<boolean>(true);
   const [formViewMode, setFormViewMode] = React.useState<ActionMode>(ActionMode.CREATE);
   const [initialValues, setInitialValues] =
      React.useState<AssignmentFormValues>(formInitialValues);

   const { getViewPreference } = useViewPreferenceContext();

   const isLastNameFirst = getViewPreference()?.displayLastNamesFirst() ?? false;

   const getResourceName = (personName: { first?: string; last?: string }): string => {
      const { first = "", last = "" } = personName;
      return isLastNameFirst ? `${last}, ${first}`.trim() : `${first} ${last}`.trim();
   };

   // Helper function to map data to initial values for the assignment form, this will be triggered in edit mode
   const mapDataToInitialValues = (assignmentDetails: GetAssignmentDetailReponse) => {
      const { data } = assignmentDetails;
      // Extract initial values
      const baseFormValues = {
         resource: {
            id: data.person?.id,
            label: getResourceName(data.person.name),
         },
         project: {
            id: data.project?.id,
            label: data.project?.name,
         },
         status: data.status
            ? {
                 id: data.status.id,
                 label: data.status.name,
              }
            : null,
         category: data.category
            ? {
                 id: data.category.id,
                 label: data.category.name,
              }
            : null,
         subcategory: data.subcategory
            ? {
                 id: data.subcategory.id,
                 label: data.subcategory.name,
              }
            : null,
         start_date: DateUtils.getAttachedDate(data.start_day),
         end_date: DateUtils.getAttachedDate(data.end_day),
         start_time: {
            id: data.start_time ?? DEFAULT_START_TIME,
            label: data.start_time
               ? timeOptions.find(({ id }) => id === data.start_time)!.label
               : timeOptions.find(({ id }) => id === DEFAULT_START_TIME)!.label,
         },
         end_time: {
            id: data.end_time ?? DEFAULT_END_TIME,
            label: data.end_time
               ? timeOptions.find(({ id }) => id === data.end_time)!.label
               : timeOptions.find(({ id }) => id === DEFAULT_END_TIME)!.label,
         },
         assignment_by_time: Boolean(data.start_time && data.end_time),
         assignment_by_allocation: Boolean(data.percent_allocated),
         work_days: data.work_days,
         percent_allocated: data.percent_allocated ?? 100,
      };
      // Initialize custom fields in form values
      const customFieldValues = processCustomFieldsFormValues(data.custom_fields);
      // setting local states
      setAssignmentByAllocation(baseFormValues.assignment_by_allocation);
      setAssignmentByTime(baseFormValues.assignment_by_time);
      setSelectedWorkDays(baseFormValues.work_days);
      setFormViewMode(ActionMode.READ);
      setFilterIntegrationOnly(false);
      return { ...baseFormValues, ...customFieldValues };
   };

   useEffect(() => {
      const fetchAssignmentDetails = async () => {
         if (assignmentId) {
            try {
               const assignmentDetails: GetAssignmentDetailReponse =
                  await Assignment2Store.getAssignmentDetails(assignmentId).payload;
               setInitialValues(mapDataToInitialValues(assignmentDetails));
            } catch (error) {
               showToast.error(I18n.t("views.company.workforce_planning.error"));
            }
         }
      };
      fetchAssignmentDetails();
   }, [assignmentId]);

   // this is needed to toggle the category and subcategory options/placeholders based on the project/category selection
   useEffect(() => {
      if (initialValues.project) {
         handleSelection(initialValues.project, DropDownType.PROJECT);
      }

      if (initialValues.category) {
         handleSelection(initialValues.category, DropDownType.CATEGORY);
      }
   }, [initialValues]);

   const [currentAssignments, setCurrentAssignments] = React.useState<JSX.Element | null>(null);

   const resetSubCategory = () => {
      setSubCategoryOptions([]);
      setSubCategoryPlaceHolder(PLACEHOLDER_KEYS.subCategory.category_selected_first);
   };

   const getTimeOptionsFromProject = (
      selectedItem: ProjectOptions | undefined,
      defaultTime: number,
      timeKey: BaggageTimeKey,
   ) => {
      if (selectedItem) {
         const timeId = selectedItem.baggage[timeKey] ?? defaultTime;
         const timeLabel = timeOptions.find(({ id }) => id === timeId)?.label;
         return { id: timeId, label: timeLabel };
      }
   };

   const handleClose = () => {
      // this will navigate to project details page on cancel click
      if (projectId && showProjectDetail) {
         showProjectDetail(projectId);
      }
      // this will simply close the tearsheet
      else {
         onClose();
      }
   };

   useEffect(() => {
      const canViewAllStatuses = checkAuthAction(AuthAction.CAN_VIEW_ALL_STATUSES);
      setCanViewAllStatuses(canViewAllStatuses);
   }, [checkAuthAction]);

   const handleSelection = (option: FormattedOption, type: string) => {
      const { id, label } = option;
      const { data } = assignmentCreationSupportData ?? {};
      if (!data) return;
      switch (type) {
         case DropDownType.PROJECT:
            setCategoryOptions([]);
            resetSubCategory();
            if (data?.grouped_cost_code_options?.[id]) {
               const categoryOptions = formatOptions(data.grouped_cost_code_options[id]);
               setCategoryOptions(categoryOptions);
               setCategoryPlaceHolder(
                  categoryOptions.length
                     ? PLACEHOLDER_KEYS.category.select_category
                     : PLACEHOLDER_KEYS.category.project_no_options,
               );
            } else {
               setCategoryPlaceHolder(PLACEHOLDER_KEYS.category.project_no_options);
            }
            break;
         case DropDownType.CATEGORY:
            setSubCategoryOptions([]);
            if (data?.grouped_label_options?.[id]) {
               const subCategoryOptions = formatOptions(data.grouped_label_options[id]);
               setSubCategoryOptions(subCategoryOptions);
               setSubCategoryPlaceHolder(
                  subCategoryOptions.length
                     ? PLACEHOLDER_KEYS.subCategory.select_subcategory
                     : PLACEHOLDER_KEYS.subCategory.category_no_options,
               );
            } else {
               setSubCategoryPlaceHolder(PLACEHOLDER_KEYS.subCategory.category_no_options);
            }
            break;
         case DropDownType.RESOURCE:
            {
               const props = {
                  variant: "info" as BannerVariant,
                  personId: id,
                  icon: "info",
                  personName: label,
               };
               const currentAssignmentsBanner = <CurrentAssignmentsBanner {...props} />;
               setCurrentAssignments(currentAssignmentsBanner);
            }
            break;
         default:
            break;
      }
   };

   const validationSchema = assignmentSchema(
      I18n,
      !canViewAllStatuses,
      selectedWorkDays,
      assignmentByAllocation,
      assignmentByTime,
   );

   const processCustomFields = (
      customFields: CustomField[],
      values: Record<string, any>,
   ): { [key: string]: any } => {
      const resultantCustomField: { [key: string]: any } = {};

      customFields.forEach((customField: CustomField) => {
         // Process the custom field if it is not integration-only
         if (!customField.integration_only) {
            const processedValue = processCustomFieldValue(customField, values);
            if (processedValue) {
               resultantCustomField[customField.id] = processedValue;
            }
         }
      });
      return resultantCustomField;
   };

   async function handleSubmit(
      values: AssignmentFormValues,
      { resetForm }: FormikHelpers<AssignmentFormValues>,
   ) {
      const assignment: Assignment = {
         resource_id: values.resource!.id,
         project_id: values.project!.id,
         category_id: values.category?.id ?? null,
         subcategory_id: values.subcategory?.id ?? null,
         start_day: getDetachedDay(new Date(values.start_date!)),
         end_day: getDetachedDay(new Date(values.end_date!)),
         start_time: values.assignment_by_time ? values.start_time.id : null,
         end_time: values.assignment_by_time ? values.end_time.id : null,
         percent_allocated: values.assignment_by_allocation ? values.percent_allocated : null,
         status_id: values.status?.id ?? null,
         work_days: values.work_days,
         custom_fields: customFields ? processCustomFields(customFields, values) : {},
      };
      try {
         setLoading(true);
         if (assignmentId) {
            const updatedAssignment = await Assignment2Store.updateSingleAssignment({
               assignmentId,
               update: {
                  ...assignment,
               },
            }).payload;

            if (updatedAssignment.data) {
               setFormViewMode(ActionMode.READ);
            }
         } else {
            await Assignment2Store.createAssignment(assignment).payload;
         }
      } catch (error) {
         if (assignmentId) {
            showToast.error(I18n.t("views.company.workforce_planning.assignment.edit_error"));
            resetForm();
         } else {
            showToast.error(I18n.t("views.company.workforce_planning.assignment.create_error"));
         }
      } finally {
         setLoading(false);
         // made this conditional because in edit flow we don't want to close the tearsheet we simply make it read only or reset the form in case of error
         if (!assignmentId) {
            handleClose();
         }
      }
   }
   return (
      <Spinner loading={(supportDataLoading || loading) && assignmentCreationSupportData === null}>
         <Page>
            <Page.Main
               style={{
                  width: "950px",
                  display: "flex",
                  flexDirection: "column",
               }}
            >
               <Page.Header>
                  <Page.Title>
                     <H1>
                        {assignmentId
                           ? I18n.t("views.company.workforce_planning.assignment.edit_title")
                           : I18n.t("views.company.workforce_planning.assignment.create_title")}
                     </H1>
                  </Page.Title>
               </Page.Header>
               <Form
                  view={formViewMode as View}
                  onSubmit={handleSubmit}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  enableReinitialize
               >
                  {/* @ts-expect-error Core React Form uses Formik under the hood; this is valid implementation */}
                  {({
                     submitForm,
                     isSubmitting,
                     setFieldValue,
                  }: FormikProps<AssignmentFormValues>) => (
                     <React.Fragment>
                        <Page.Body style={{ width: "100%", marginBottom: "auto" }}>
                           <Card style={{ marginBottom: "10px" }}>
                              <Box padding="md">
                                 <FlexList justifyContent="space-between" marginBottom="sm">
                                    <Title>
                                       <Title.Text>
                                          <H2>
                                             {I18n.t(
                                                "views.company.workforce_planning.specifications",
                                             )}
                                          </H2>
                                       </Title.Text>
                                    </Title>
                                    {formViewMode === ActionMode.READ && (
                                       <Button
                                          variant="secondary"
                                          onClick={() => setFormViewMode(ActionMode.UPDATE)}
                                          data-testid="edit-assignment"
                                       >
                                          {I18n.t("views.company.workforce_planning.edit")}
                                       </Button>
                                    )}
                                 </FlexList>
                                 <Form.Form>
                                    <Form.Row>
                                       <Form.Select
                                          colStart={1}
                                          colWidth={6}
                                          name="resource"
                                          label={I18n.t(
                                             "views.company.workforce_planning.resource",
                                          )}
                                          required
                                          options={formatOptions(
                                             assignmentCreationSupportData?.data.resource_options ??
                                                [],
                                          )}
                                          placeholder={I18n.t(
                                             "views.company.workforce_planning.select_option",
                                          )}
                                          onClear={false}
                                          onSelect={({ item }) => {
                                             handleSelection(item, DropDownType.RESOURCE);
                                          }}
                                       />
                                       <Form.Select
                                          colStart={7}
                                          colWidth={6}
                                          name="project"
                                          label={I18n.t("views.company.workforce_planning.project")}
                                          required
                                          options={formatOptions(
                                             assignmentCreationSupportData?.data.project_options ??
                                                [],
                                          )}
                                          onSelect={({ item }) => {
                                             const selectedItem =
                                                assignmentCreationSupportData?.data.project_options.find(
                                                   (project) => project.id === item.id,
                                                ) as ProjectOptions;
                                             handleSelection(item, DropDownType.PROJECT);
                                             setFieldValue("category", null);
                                             setFieldValue("subcategory", null);
                                             setFieldValue(
                                                "start_time",
                                                getTimeOptionsFromProject(
                                                   selectedItem,
                                                   DEFAULT_START_TIME,
                                                   "daily_start_time",
                                                ),
                                             );
                                             setFieldValue(
                                                "end_time",
                                                getTimeOptionsFromProject(
                                                   selectedItem,
                                                   DEFAULT_END_TIME,
                                                   "daily_end_time",
                                                ),
                                             );
                                          }}
                                          placeholder={I18n.t(
                                             "views.company.workforce_planning.select_option",
                                          )}
                                          onClear={false}
                                       />
                                    </Form.Row>
                                    <Form.Row>
                                       <Form.Select
                                          colStart={1}
                                          colWidth={6}
                                          name="category"
                                          label={I18n.t(
                                             "views.company.workforce_planning.category",
                                          )}
                                          options={categoryOptions}
                                          onSelect={({ item }) => {
                                             handleSelection(item, DropDownType.CATEGORY);
                                             setFieldValue("subcategory", null);
                                          }}
                                          placeholder={I18n.t(categoryPlaceHolder)}
                                          disabled={categoryOptions.length === 0}
                                          onClear={() => {
                                             resetSubCategory();
                                             setFieldValue("subcategory", null);
                                          }}
                                       />
                                       <Form.Select
                                          colStart={7}
                                          colWidth={6}
                                          name="subcategory"
                                          label={I18n.t(
                                             "views.company.workforce_planning.subcategory",
                                          )}
                                          options={subCategoryOptions}
                                          placeholder={I18n.t(subCategoryPlaceHolder)}
                                          disabled={subCategoryOptions.length === 0}
                                       />
                                    </Form.Row>
                                 </Form.Form>
                              </Box>
                           </Card>
                           <Card style={{ marginBottom: "10px" }}>
                              <Box padding="md">
                                 {currentAssignments}
                                 <FlexList justifyContent="space-between" marginBottom="sm">
                                    <Title>
                                       <Title.Text>
                                          <H2>
                                             {I18n.t("views.company.workforce_planning.schedule")}
                                          </H2>
                                       </Title.Text>
                                    </Title>
                                 </FlexList>
                                 <Form.Form>
                                    <Form.Row>
                                       <Form.DateSelect
                                          colStart={1}
                                          colWidth={3}
                                          name="start_date"
                                          label={I18n.t(
                                             "views.company.workforce_planning.assignment.start_date",
                                          )}
                                          required
                                       />
                                       <Form.DateSelect
                                          colStart={4}
                                          colWidth={3}
                                          name="end_date"
                                          label={I18n.t(
                                             "views.company.workforce_planning.assignment.end_date",
                                          )}
                                          required
                                       />
                                       {assignmentByTime && (
                                          <>
                                             <Form.Select
                                                colStart={7}
                                                colWidth={3}
                                                name="start_time"
                                                label={I18n.t(
                                                   "views.company.workforce_planning.assignment.start_time",
                                                )}
                                                options={timeOptions}
                                                onSearch={false}
                                                required
                                                placeholder={I18n.t(
                                                   "views.company.workforce_planning.select_option",
                                                )}
                                             />
                                             <Form.Select
                                                colStart={10}
                                                colWidth={3}
                                                name="end_time"
                                                label={I18n.t(
                                                   "views.company.workforce_planning.assignment.end_time",
                                                )}
                                                options={timeOptions}
                                                onSearch={false}
                                                required
                                                placeholder={I18n.t(
                                                   "views.company.workforce_planning.select_option",
                                                )}
                                             />
                                          </>
                                       )}
                                       {assignmentByAllocation && (
                                          <Form.Number
                                             colStart={7}
                                             colWidth={3}
                                             name="percent_allocated"
                                             label={I18n.t(
                                                "views.company.workforce_planning.assignment.percent_allocated",
                                             )}
                                             required
                                             placeholder={I18n.t(
                                                "views.company.workforce_planning.enter_value",
                                             )}
                                          />
                                       )}
                                    </Form.Row>
                                    <Form.Row>
                                       <Form.Field
                                          name="assignment_by_time"
                                          as={AssignmentSwitch}
                                          colStart={1}
                                          colWidth={6}
                                          inlineLabel={I18n.t(
                                             "views.company.workforce_planning.assignment.assignment_by_time",
                                          )}
                                          setValues={setFieldValue}
                                          value={assignmentByTime}
                                          toggleAssignmentByAllocation={setAssignmentByAllocation}
                                          toggleAssignmentByTime={setAssignmentByTime}
                                       />
                                       <Form.Field
                                          name="assignment_by_allocation"
                                          as={AssignmentSwitch}
                                          colStart={7}
                                          colWidth={6}
                                          inlineLabel={I18n.t(
                                             "views.company.workforce_planning.assignment.assignment_by_allocation",
                                          )}
                                          setValues={setFieldValue}
                                          value={assignmentByAllocation}
                                          toggleAssignmentByAllocation={setAssignmentByAllocation}
                                          toggleAssignmentByTime={setAssignmentByTime}
                                       />
                                    </Form.Row>
                                    <Form.Row>
                                       <Form.Field
                                          colStart={1}
                                          colWidth={12}
                                          as={WorkDaysCheckboxes}
                                          label={I18n.t(
                                             "views.company.workforce_planning.work_days",
                                          )}
                                          required
                                          name="work_days"
                                          setSelectedWorkDays={setSelectedWorkDays}
                                          value={selectedWorkDays}
                                          setValues={setFieldValue}
                                       />
                                    </Form.Row>
                                    {!canViewAllStatuses && (
                                       <Form.Row>
                                          <Form.Select
                                             colStart={1}
                                             colWidth={6}
                                             name="status"
                                             label={I18n.t(
                                                "views.company.workforce_planning.projects.status",
                                             )}
                                             placeholder={I18n.t(
                                                "views.company.workforce_planning.select_option",
                                             )}
                                             options={formatOptions(
                                                assignmentCreationSupportData?.data
                                                   .status_options ?? [],
                                             )}
                                             required
                                          />
                                       </Form.Row>
                                    )}
                                 </Form.Form>
                              </Box>
                           </Card>
                           {customFields && customFields.length > 0 && (
                              <Card style={{ marginBottom: "10px" }}>
                                 <Box padding="md">
                                    <FlexList justifyContent="space-between" marginBottom="sm">
                                       <Title>
                                          <Title.Text>
                                             <H2>
                                                {I18n.t(
                                                   "views.company.workforce_planning.additional_information",
                                                )}
                                             </H2>
                                          </Title.Text>
                                       </Title>
                                    </FlexList>
                                    <>
                                       {renderCustomFields(
                                          customFields,
                                          I18n,
                                          filterIntegrationOnly,
                                       )}
                                    </>
                                 </Box>
                              </Card>
                           )}
                        </Page.Body>
                        {formViewMode !== ActionMode.READ && (
                           <Page.Footer>
                              <Box padding="md">
                                 <Flex justifyContent="space-between" alignItems="center">
                                    <Required showLabel />
                                    <FlexList space="sm">
                                       <Button
                                          variant="tertiary"
                                          disabled={isSubmitting}
                                          onClick={handleClose}
                                       >
                                          {I18n.t("views.company.workforce_planning.cancel")}
                                       </Button>
                                       <Button
                                          type="submit"
                                          disabled={isSubmitting}
                                          onClick={submitForm}
                                       >
                                          {assignmentId
                                             ? I18n.t("views.company.workforce_planning.publish")
                                             : I18n.t("views.company.workforce_planning.create")}
                                       </Button>
                                    </FlexList>
                                 </Flex>
                              </Box>
                           </Page.Footer>
                        )}
                     </React.Fragment>
                  )}
               </Form>
            </Page.Main>
         </Page>
      </Spinner>
   );
};
