import type { IntegratedField, ProjectListServerFilter } from "@/react/prop-types";
import { Order } from "@laborchart-modules/common/dist/reql-builder/query-definitions";
import type { Filter } from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/common";
import type { PeopleListServerFilter } from "../people-list/people-list-prop-types";
import { FilterFieldType } from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/enums/common";

const GROUP_LIMIT = 3;

// Sort Order Maps
export const sortOrderMap: { [key: string]: Order } = {
   asc: Order.ASCENDING,
   desc: Order.DESCENDING,
};

export function isFieldLocked(integratedField: IntegratedField[], field: string) {
   return integratedField.some(
      (integratedField: IntegratedField) =>
         integratedField.property === field && integratedField.locked,
   );
}

export function getFormattedGroupName(
   cellValue: Array<{ label: string }>,
   groupOptions: any,
   I18nObject: any,
) {
   // If the cellValue is more than GROUP_LIMIT, then show only GROUP_LIMIT and add a +more count
   if (cellValue.length > GROUP_LIMIT && cellValue.length !== groupOptions?.length) {
      const formattedValue = cellValue.slice(0, GROUP_LIMIT);
      const moreCount = cellValue.length - GROUP_LIMIT;
      formattedValue.push({
         label: I18nObject.t("views.company.workforce_planning.projects.group_count", {
            count: moreCount,
         }),
      });
      return formattedValue;
   }
   // If the cellValue is equal to the groupOptions length, then show "All Groups"
   if (cellValue.length === groupOptions?.length) {
      return [{ label: I18nObject.t("views.company.workforce_planning.projects.all_groups") }];
   }
   // If the cellValue is less than 3, then show all the values
   return cellValue.map((item) => item.label);
}

export function convertNestedCustomFilters(
   filter: ProjectListServerFilter | PeopleListServerFilter,
   filterMapper: { [key: string]: FilterFieldType },
): Filter[] {
   const customFilters: Filter[] = [];
   let valueSets: any[] = [];
   if (filter.fieldType === FilterFieldType.BOOL) {
      valueSets = filter.selected.map((selection: any) => ({
         negation: false,
         value: selection.value,
      }));
   } else {
      // Handling non-bool type here
      valueSets = [
         {
            negation: false,
            value: filter.selected.map((x: any) => x) as string[],
         },
      ];
   }
   customFilters.push({
      name: filter.fieldId!,
      property: "custom_fields",
      type: filterMapper[filter.fieldType!],
      value_sets: valueSets,
      custom_field_id: filter.fieldId,
   });
   return customFilters;
}

export const createDefaultColumnState = () => ({
   hidden: false,
   pinned: null,
   width: 140,
   sort: null,
   sortIndex: null,
   rowGroup: false,
   rowGroupIndex: null,
});
