import type { Filter } from "@/lib/components/chip-filter/chip-filter";
import type { AssignmentData, AssignmentWorkDays } from "@/models/assignment";
import type { TaskModel } from "@bryntum/gantt";
import type { Person, Project } from "@laborchart-modules/common";
import type { JobTitle } from "@laborchart-modules/common/dist/postgres/schemas/types/job_titles";

export type GanttContainerPropTypes = {
   groupId: string;
};

type RequestData = {
   id: string;
   end_day: string;
   tag_ids: string[];
   archived: boolean;
   comments: string[];
   end_time: string;
   label_id: string | null;
   start_day: string;
   status_id: string | null;
   work_days: AssignmentWorkDays;
   company_id: string;
   created_at: string;
   creator_id: string | null;
   editor_ids: string[];
   project_id: string;
   start_time: string;
   archived_at: string | null;
   cost_code_id: string;
   job_title_id: string | null;
   custom_fields: any[];
   work_scope_text: string | null;
   instruction_text: string | null;
   percent_allocated: null | number;
};

export type GanttProject = Omit<Project, "start_date" | "est_end_date"> & {
   start_date: string;
   est_end_date: string;
   assignments: AssignmentData[];
   requests: RequestData[];
   tbd_weeks: number;
};

export type GanttOptions = {
   skip: number;
   limit?: number;
   projectSort: string;
   group_id: string;
   startDay: number;
   endDay: number;
   filters?: {
      [key: string]: Filter[];
   };
   search?: string;
};

export type RawGanttData = {
   jobTitles: JobTitle[];
   people: Person[];
   projects: GanttProject[];
};

export type Category = {
   id: string;
   name: string;
   labels: Subcategory[];
};

export type Subcategory = {
   id: string;
   name: string;
};

export type GroupableTask = {
   cost_code_id: string;
   label_id: string;
};

export enum TaskType {
   ASSIGNMENT = "assignment",
   REQUEST = "request",
   PROJECT = "project",
   CATEGORY = "category",
   SUBCATEGORY = "subcategory",
}

export type Task = Partial<TaskModel> & {
   type: TaskType;
   calendar?: string;
   projectName: string;
   projectColor: string;
   projectJobNumber: string;
   jobTitleName: string;
   jobTitleColor: string;
   startTime?: string;
   endTime?: string;
   workDays: AssignmentWorkDays;
};

export type GroupedTasks = Array<
   | {
        name: string; // category
        children: Array<{
           name: string; // subcategory
           children: Task[]; // assignments or requests
        }>;
     }
   | Task
>;

export type TaskLookup = {
   [categoryId: string]: {
      [subcategoryId: string]: Task[];
   };
};

export type noSubcategoryTasks = {
   [categoryId: string]: Task[];
};

export type projectStatus = {
   id: number;
   name: string;
};

export enum FilterTaskType {
   ASSIGNMENTS = "assignments",
   REQUESTS = "requests",
}

export enum dateQualifier {
   ONORBEFORE = "<=",
   ONORAFTER = ">=",
   ON = "=",
   AFTER = ">",
   BEFORE = "<",
}

export type ganttFilterType = {
   jobTitles: Array<{ id: string; name: string }>;
   projectStatuses: projectStatus[];
   onlyShow: FilterTaskType[];
   hideEmptyProject: boolean;
   // startDate: {
   //    qualifier: dateQualifier | null;
   //    date: string;
   // };
   // endDate: {
   //    qualifier: dateQualifier | null;
   //    date: string;
   // };
};

export type ganttFilterOptionTypes =
   | "jobTitles"
   | "projectStatuses"
   | "onlyShow"
   | "hideEmptyProject";
// | "startDate"
// | "endDate";

export enum ganttFilterPillType {
   MULTISELECT = "multiselect",
   BOOLEAN = "boolean",
   CHECKBOX = "checkbox",
   DATE = "date",
}
