import type { SerializedAttachment } from "@laborchart-modules/common/dist/rethink/serializers/attachment-serializer";
import type {
   ProjectAttachmentsData,
   ProjectDetailFormValues,
   ProjectRoleData,
   ProjectTagData,
   ProjectWageOverrideData,
} from "./types";
import Resizer from "react-image-file-resizer";
import { getAttachedDate } from "@laborchart-modules/common/dist/datetime";
import { DateUtils } from "@/lib/utils/date";
import { months } from "@/react/shared/constants";
import { v4 } from "uuid";

export function compareDates({
   startDate,
   endDate,
}: {
   startDate: Date;
   endDate: ProjectDetailFormValues["est_end_date"] | undefined;
}) {
   if (!startDate) {
      return true;
   }
   const formattedStartDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
   );
   if (endDate) {
      const formattedEndDate = new Date(
         endDate.getFullYear(),
         endDate.getMonth(),
         endDate.getDate(),
      );
      if (formattedEndDate.getTime() === formattedStartDate.getTime()) {
         return true;
      }
      return formattedEndDate.getTime() > formattedStartDate.getTime();
   }
   return true;
}

export function getUniqueAttachmentIds(
   attachments: SerializedAttachment[],
   deletedAttachmentIds: string[],
) {
   // Get array of attachment IDs from attachments by user
   const addedAttachmentIds = attachments.map((attachment) => attachment.id);

   // addedAttachmentIds, and convert to Set for uniqueness to remove duplicates if any
   let uniqueAttachmentIds: any = new Set([...addedAttachmentIds]);

   // Remove IDs that are in deletedAttachmentIds array
   deletedAttachmentIds.forEach((id) => {
      uniqueAttachmentIds.delete(id);
   });

   // Convert Set back to array
   uniqueAttachmentIds = Array.from(uniqueAttachmentIds);

   return uniqueAttachmentIds;
}

export function prepareTagPayload(
   attachments: SerializedAttachment[],
   deletedAttachmentIds: string[],
   selectedTagId: string,
) {
   const payload: ProjectTagData = {
      tag_instances: {
         [selectedTagId]: {
            attachment_ids: getUniqueAttachmentIds(attachments, deletedAttachmentIds),
         },
      },
   };

   return payload;
}

export function prepareAttachmentsPayload(
   attachments: SerializedAttachment[],
   deletedAttachmentIds: string[],
) {
   const payload: ProjectAttachmentsData = {
      attachment_ids: getUniqueAttachmentIds(attachments, deletedAttachmentIds),
   };

   return payload;
}

export function prepareOverridePayload(selectedOverrideId: string, wage: number | null) {
   const payload: ProjectWageOverrideData = {
      wage_overrides: {
         [selectedOverrideId]: wage,
      },
   };
   return payload;
}

export function formatFullDateTime(timestamp: number): string {
   if (isNaN(timestamp) || timestamp < 0) {
      return "";
   }

   const date = new Date(timestamp);
   const month = months[date.getUTCMonth()];
   const day = date.getUTCDate();
   const year = date.getUTCFullYear();

   let hours = date.getUTCHours();
   const minutes = date.getUTCMinutes();
   const ampm = hours >= 12 ? "PM" : "AM";
   hours = hours % 12;
   hours = hours ? hours : 12;
   const strMinutes = minutes < 10 ? "0" + minutes : minutes;

   hours = (hours + 19) % 24;

   return `${month} ${day}, ${year} at ${hours}:${strMinutes} ${ampm} EST`;
}

export function getInitials(fullName: string): string {
   const words = fullName.split(" ");
   if (words.length < 2) {
      return "";
   }
   const initials = words.slice(0, 2).map((word) => word.charAt(0).toUpperCase());
   return initials.join("");
}

export const getFileDimensions = (file: string) => {
   return new Promise<{ width: number; height: number }>((resolve) => {
      const img = new Image();
      img.src = file;
      img.addEventListener("load", () => resolve({ width: img.width, height: img.height }));
   });
};

export const resizeFile = (
   file: File,
   maxSize: number,
   minSize?: number,
   outputType: string = "file",
) => {
   return new Promise((resolve) => {
      Resizer.imageFileResizer(
         file,
         maxSize,
         maxSize,
         "png",
         100,
         0,
         (uri) => {
            resolve(uri);
         },
         outputType,
         minSize ?? undefined,
         minSize ?? undefined,
      );
   });
};

export const readFile = (file: File) => {
   return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
   });
};

export const readFileFromUrl = async (name: string, url: string) => {
   const response = await fetch(url);
   const blob = await response.blob();
   return new File([blob], name ?? "file_name.png", {
      type: blob.type,
   });
};

function processDataForView(breakdown: any, selectedView: string): Array<[number, number]> {
   const assignments = breakdown.daily_ass_totals;
   const requests = breakdown.daily_request_totals;
   const data: Array<[number, number]> = [];

   const processEntry = (k: string, yVal: number) => {
      data.push([getAttachedDate(Number(k)).getTime(), yVal]);
   };

   if (selectedView === "both") {
      const keys =
         Object.keys(requests).length >= Object.keys(assignments).length ? requests : assignments;
      Object.keys(keys).forEach((k) => {
         const yVal = (requests[k]?.count || 0) + (assignments[k]?.count || 0);
         processEntry(k, yVal);
      });
   } else {
      const source = selectedView === "requests" ? requests : assignments;
      Object.keys(source).forEach((k) => processEntry(k, source[k].count));
   }

   return data;
}

export function processTotalsDataForGraph(
   totals: any,
   selectedView: "assignments" | "requests" | "both",
   I18n: {
      t: (t: string) => string;
   },
): Highcharts.SeriesAreaOptions[] {
   return totals.breakdown_data
      .map((breakdown: any) => ({
         name: breakdown.name ?? I18n.t("views.company.workforce_planning.none"),
         color: breakdown.color ?? "#ACB5B9",
         type: "area" as const,
         data: processDataForView(breakdown, selectedView),
      }))
      .filter((series: Highcharts.SeriesAreaOptions) => {
         if (series.data) {
            return series.data.length > 0;
         }
         return false;
      });
}

export const getDateString = (date: Date) => {
   return `${DateUtils.getMonth(date)} ${date.getDate()}, ${date.getFullYear()}`;
};

export function prepareRolesPayload(
   selectedRoleId: string,
   jobTitleValue: string,
   personIdValue: string,
   archived: boolean,
) {
   const payload: ProjectRoleData = {
      roles: [
         {
            id: selectedRoleId !== "" ? selectedRoleId : v4(),
            position_id: jobTitleValue,
            person_id: personIdValue,
            archived,
         },
      ],
   };
   return payload;
}
