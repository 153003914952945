import template from "./people-filter-pane.pug"
import { ObservableData } from "@/lib/utils/observable-data"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { groupStore } from "@/stores/group-store"

### Popups ###
import { Popup } from "@/lib/components/popup/popup"
import { PopupPane } from "@/lib/components/popup/popup-pane"

## UI Assets ##
import { MultiDropDownItem } from "@/lib/components/drop-downs/multi-drop-down"

export class PeopleFilterPane extends PopupPane
   constructor: (selectedPositionIds, selectedTagIds, wageLow, wageHigh, ageLow, ageHigh) ->
      assertArgs(arguments, Function, Function, Function, Function, Function, Function)
      super(template(), "Filters")

      @tagCategoriesEnabled = authManager.companyModules()?.tagCategories

      # Popup Properties
      @enableOverflow = ko.observable(true)

      @selectedPositionIds = selectedPositionIds
      @editingPositions = ko.observableArray()
      @positionOptions = ko.observableArray()

      @selectedTagIds = selectedTagIds
      @editingTags = ko.observableArray()
      @tagOptions = ko.observableArray()
      @categorizedTags = ko.observable()

      @wageLow = wageLow
      @editingWageLow = ko.observable(@wageLow())
      @wageHigh = wageHigh
      @editingWageHigh = ko.observable(@wageHigh())

      @ageLow = ageLow
      @editingAgeLow = ko.observable(@ageLow())
      @ageHigh = ageHigh
      @editingAgeHigh = ko.observable(@ageHigh())

      @leftActionBtn = ko.observable({
         callback: @clearAll
         text: "Clear All"
         })

      @rightActionBtn = ko.observable({
         callback: @applyChanges
         text: "Apply"
         })

      @loadData()

   applyChanges: =>
      @selectedPositionIds(@editingPositions().map (item) -> return item.value())
      @selectedTagIds(@editingTags().map (item) -> return item.value())
      @wageLow(@editingWageLow())
      @wageHigh(@editingWageHigh())
      @ageLow(@editingAgeLow())
      @ageHigh(@editingAgeHigh())
      @dismissHandler(Popup.ExitStatus.AFFIRMATIVE_ACTION)

   clearAll: =>
      @selectedPositionIds([])
      @editingPositions([])
      @selectedTagIds([])
      @editingTags([])
      @wageLow(null)
      @editingWageLow(null)
      @wageHigh(null)
      @editingWageHigh(null)
      @ageLow(null)
      @editingAgeLow(null)
      @ageHigh(null)
      @editingAgeHigh(null)
      @dismissHandler(Popup.ExitStatus.NEGATING_ACTION)

   loadData: ->
      entities = ['positions']
      if @tagCategoriesEnabled
         entities.push('categorized-tags')
      else
         entities.push('tags')

      groupStore.getGroupEntities authManager.selectedGroupId(), entities, (err, data) =>
         return console.log "Error: ", err if err

         @positionOptions data.positionOptions.map (item) =>
            selected = @selectedPositionIds().indexOf(item.value()) != -1
            option = new MultiDropDownItem(item.name(), item.value(), selected)
            @editingPositions.push(option) if selected
            return option

         if data.tagOptions? and data.tagOptions.length > 0
            @tagOptions data.tagOptions.map (item) =>
               selected = @selectedTagIds().indexOf(item.value()) != -1
               option = new MultiDropDownItem(item.name(), item.value(), selected)
               @editingTags.push(option) if selected
               return option
         else if data.categorizedTagOptions?
            for key, val of data.categorizedTagOptions
               for item in val
                  selected = @selectedTagIds().indexOf(item.value()) != -1
                  if selected
                     item.baggage({group: key})
                     item.selected(selected)
                     @editingTags.push(item) 
            @categorizedTags(data.categorizedTagOptions)

