import type { FormSelectOption } from "@/react/prop-types";
import type { WorkdayOptions } from "../../labor-plans/prop-types";

export type AssignmentFormValues = {
   resource: FormSelectOption<string> | null;
   project: FormSelectOption<string> | null;
   status: FormSelectOption<string> | null;
   category: FormSelectOption<string> | null;
   subcategory: FormSelectOption<string> | null;
   start_date: Date | null;
   end_date: Date | null;
   start_time: FormSelectOption<number>;
   end_time: FormSelectOption<number>;
   assignment_by_time: boolean;
   assignment_by_allocation: boolean;
   work_days: WorkdayOptions;
   percent_allocated: number | null;
};

export type Assignment = {
   category_id: string | null;
   custom_fields: { [key: string]: any };
   end_day: number;
   end_time: number | null;
   percent_allocated: number | null;
   project_id: string;
   resource_id: string;
   start_day: number;
   start_time: number | null;
   status_id: string | null;
   subcategory_id: string | null;
   work_days: WorkdayOptions;
};

export type BaggageTimeKey = "daily_start_time" | "daily_end_time";

export type ProjectOptions = {
   baggage: {
      additional_searchables: string[];
      start_date: number;
      est_end_date: number | null;
      daily_start_time: number;
      daily_end_time: number;
      status: string;
   };
};

export enum AssignmentFields {
   ALLOCATION = "assignment_by_allocation",
   TIME = "assignment_by_time",
}

export enum DropDownType {
   CATEGORY = "category",
   SUBCATEGORY = "subcategory",
   PROJECT = "project",
   RESOURCE = "resource",
}

export type AssignmentFormattedTypes = { assignmentId: string; formattedAssignemntDetails: string };
