import { useGetPeopleStream } from "@/react/components/common/queries/queries";
import { useGroupContext } from "@/react/providers/group-context-provider";
import { AuthAction, usePermissionContext } from "@/react/providers/permission-context-provider";
import { CannedMessageType } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import type { SerializedCannedMessage } from "@laborchart-modules/common/dist/rethink/serializers/canned-message-serializer";
import { Ban, Check, Pencil } from "@procore/core-icons";
import {
   Box,
   Button,
   Card,
   Checkbox,
   Grid,
   H2,
   MultiSelect,
   Spinner,
   Title,
   Typography,
   useI18nContext,
} from "@procore/core-react";
import React, { useEffect, useState } from "react";
import { customAlertTypes } from "../constants";
import type { CustomAssignmentAlertsProps, DefaultRecipient } from "../types";

export const ProjectCustomAssignmentAlerts = ({
   projectData,
   editMode,
   loading,
   setEditMode,
   handleChange,
}: CustomAssignmentAlertsProps) => {
   const I18n = useI18nContext();
   const { checkAuthAction } = usePermissionContext();
   const { groupId } = useGroupContext();
   const { data: peopleData, isLoading: peopleDataLoading } = useGetPeopleStream(
      groupId != "my-groups" ? groupId : undefined,
   );

   const [canEditCustomAlerts, setCanEditCustomAlerts] = useState(false);
   const [canViewRecipients, setCanViewRecipients] = useState(false);
   const [canEditRecipients, setCanEditRecipients] = useState(false);
   const [defaultRecipients, setDefaultRecipients] = useState<DefaultRecipient[]>([]);

   const [assignmentAlerts, setAssignmentAlerts] = useState<{
      [key in CannedMessageType]: SerializedCannedMessage | null;
   }>({
      [CannedMessageType.ASSIGNMENT_NEW]: null,
      [CannedMessageType.ASSIGNMENT_EDIT]: null,
      [CannedMessageType.ASSIGNMENT_TRANSFER]: null,
      [CannedMessageType.ASSIGNMENT_DELETE]: null,
      [CannedMessageType.ASSIGNMENT_REMINDER]: null,
   });

   useEffect(() => {
      const canEditCustomAlerts = checkAuthAction(AuthAction.EDIT_PROJECT_CUSTOM_ALERTS);
      const canViewDefaultRecipients = checkAuthAction(AuthAction.VIEW_PROJECT_DEFAULT_RECIPIENTS);
      const canEditRecipients = checkAuthAction(AuthAction.EDIT_PROJECT_DEFAULT_RECIPIENTS);
      setCanEditCustomAlerts(canEditCustomAlerts);
      setCanViewRecipients(canViewDefaultRecipients);
      setCanEditRecipients(canEditRecipients);
   }, [checkAuthAction]);

   useEffect(() => {
      const { canned_messages: cannedMessages = [], default_recipients: recipients = [] } =
         projectData?.data ?? {};

      setDefaultRecipients(
         recipients.map((recipient) => ({ ...recipient, label: recipient.name })),
      );

      // Search for custom alerts inside the canned messages
      customAlertTypes.forEach((customAlertType) => {
         return cannedMessages.some((message) => {
            if (message.type === customAlertType.type) {
               setAssignmentAlerts((alerts) => ({
                  ...alerts,
                  [customAlertType.type]: message,
               }));
               return true;
            }
         });
      });
   }, [projectData?.data]);

   const renderCustomAlert = (alertType: {
      title: string;
      defaultTitle: string;
      type: CannedMessageType;
   }) => {
      const alertTitle = I18n.t(alertType.title, { defaultValue: alertType.defaultTitle });
      const hasCustomAlert = Boolean(assignmentAlerts[alertType.type]);
      let componentWithLabel = null;

      if (editMode && canEditCustomAlerts) {
         componentWithLabel = (
            <Checkbox checked={hasCustomAlert} readOnly data-testid="checkbox">
               {alertTitle}
            </Checkbox>
         );
      } else {
         componentWithLabel = (
            <Box gap="md" alignItems="center" display="flex">
               {hasCustomAlert ? <Check size="sm" /> : <Ban size="sm" />}
               <Typography intent="label" weight="regular">
                  {alertTitle}
               </Typography>
            </Box>
         );
      }

      return (
         <Grid.Row key={alertType.type} data-testid="custom-alert">
            <Grid.Col colWidth={6} style={{ display: "flex", alignItems: "center" }}>
               {componentWithLabel}
            </Grid.Col>

            <Grid.Col colWidth={6} style={{ display: "flex", alignItems: "center" }}>
               <Button
                  variant="tertiary"
                  onClick={() => {}}
                  disabled={!editMode || !canEditCustomAlerts}
                  data-testid="edit-button"
                  size="sm"
               >
                  <Pencil size="sm" />
               </Button>
            </Grid.Col>
         </Grid.Row>
      );
   };

   const handleOnChangeRecipients = (recipients: DefaultRecipient[]) => {
      setDefaultRecipients(recipients);
      handleChange({
         target: {
            name: "default_recipient_ids",
            value: recipients.map((recipient) => recipient.id),
         },
      });
   };

   return (
      <Card style={{ marginBottom: 20 }} data-testid="custom-assingment-alerts">
         <Spinner loading={loading} centered>
            <Box padding="lg">
               <Title>
                  <Title.Text>
                     <H2>
                        {I18n.t(
                           "views.company.workforce_planning.project_details_custom_assignment_alerts.title",
                        )}
                     </H2>
                  </Title.Text>
                  <Title.Subtext>
                     {I18n.t(
                        "views.company.workforce_planning.project_details_custom_assignment_alerts.subtitle",
                     )}
                  </Title.Subtext>
                  <Title.Actions>
                     {!editMode && (canEditCustomAlerts || canEditRecipients) && (
                        <Button
                           variant="secondary"
                           onClick={() => setEditMode()}
                           data-testid="enable-edit-mode"
                        >
                           {I18n.t("views.company.workforce_planning.edit")}
                        </Button>
                     )}
                  </Title.Actions>
               </Title>
            </Box>
            <Box padding="lg" paddingTop="none">
               {/* RENDER CUSTOM ALERTS */}
               <Grid gutterY="md">{customAlertTypes.map(renderCustomAlert)}</Grid>

               {/* DEFAULT RECIPIENTS */}
               {(canViewRecipients && defaultRecipients && defaultRecipients.length > 0) ||
               canEditRecipients ? (
                  <React.Fragment>
                     <Box paddingTop="md" paddingBottom="md">
                        <hr />
                     </Box>

                     <Box gap="md" display="flex" flexDirection="column">
                        <Typography intent="label" weight="semibold">
                           {I18n.t(
                              "views.company.workforce_planning.project_details_custom_assignment_alerts.send_alerts_to",
                           )}
                        </Typography>
                        {editMode && canEditRecipients ? (
                           // If can edit recipients, render the MultiSelect component to select recipients
                           <MultiSelect
                              onChange={(option) => handleOnChangeRecipients(option)}
                              disabled={peopleDataLoading}
                              options={(peopleData ?? []) as DefaultRecipient[]}
                              value={defaultRecipients}
                              block
                              data-testid="multiselect-recipients"
                              placeholder="Select Resources"
                           />
                        ) : (
                           // else cannot edit recipients, just display the names
                           <Typography data-testid="recipients">
                              {defaultRecipients.map((recipient) => recipient.name).join(", ")}
                           </Typography>
                        )}
                     </Box>
                  </React.Fragment>
               ) : null}
            </Box>
         </Spinner>
      </Card>
   );
};
