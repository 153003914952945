import { Button, Banner, useI18nContext } from "@procore/core-react";
import React, { useEffect, useState } from "react";
import { useGetCurrentAssignments } from "./queries";
import type { BannerVariant } from "@procore/core-react/dist/Banner/Banner.types";
import { Info } from "@procore/core-icons";
import type { SerializedFindAssignment } from "@laborchart-modules/lc-core-api/dist/api/assignments";
import { getCurrentAssignmentsFormatted } from "./helpers";
import type { AssignmentFormattedTypes } from "./types";

type CurrentAssignmentsBannerProps = {
   bodyContent?: number;
   icon?: string;
   titleContent?: number;
   variant: BannerVariant;
   expandableContentMaxHeight?: number;
   expandableContentLines?: number;
   personId: string;
   personName: string;
};
export const CurrentAssignmentsBanner = (props: CurrentAssignmentsBannerProps) => {
   const { personId, personName } = props;

   const { currentAssignments, isLoading } = useGetCurrentAssignments(personId);

   const I18n = useI18nContext();
   const [isExpanded, setIsExpanded] = React.useState(true);
   const [isBannerVisible, setIsBannerVisible] = useState(true);
   const [assignmentData, setAssignmentData] = useState<AssignmentFormattedTypes[]>([]);

   useEffect(() => {
      setAssignmentData([]);
      setIsExpanded(false);
      setIsBannerVisible(true);
   }, [personId]);

   const toggleBannerVisibility = () => {
      setIsBannerVisible((prevVisibility) => !prevVisibility);
   };
   const handleButtonClick = (assignment: SerializedFindAssignment[], displayState: boolean) => {
      if (displayState) {
         setAssignmentData([]);
         setIsExpanded(false);
         return;
      }
      const formattedAssignments: AssignmentFormattedTypes[] =
         getCurrentAssignmentsFormatted(assignment);

      setAssignmentData(formattedAssignments ?? []);
      setIsExpanded(!isExpanded);
   };
   return (
      (currentAssignments?.data.length && !isLoading && isBannerVisible && (
         <Banner {...props} data-testid="current-assignments-banner">
            <Banner.Icon icon={<Info size="md" />} />
            <Banner.Content>
               <Banner.Title>
                  {I18n.t("views.company.workforce_planning.assignment.additional_assignment_text")}{" "}
                  {personName}
               </Banner.Title>
               <Banner.Body>
                  {getCurrentAssignmentsFormatted(currentAssignments?.data.slice(0, 2)).map(
                     (assignment) => (
                        <li key={assignment.assignmentId}>
                           {assignment.formattedAssignemntDetails}
                        </li>
                     ),
                  )}
                  {assignmentData.map((assignment) => (
                     <li key={assignment.assignmentId}>{assignment.formattedAssignemntDetails}</li>
                  ))}
               </Banner.Body>
            </Banner.Content>
            <Banner.Action>
               <Button
                  onClick={() => handleButtonClick(currentAssignments?.data.slice(2), isExpanded)}
               >
                  {isExpanded
                     ? I18n.t("views.company.workforce_planning.show_less")
                     : I18n.t("views.company.workforce_planning.show_more")}
               </Button>
            </Banner.Action>
            <Banner.Dismiss
               onClick={toggleBannerVisibility}
               data-testid="current-assignments-banner-dismiss"
            />
         </Banner>
      )) ||
      null
   );
};
