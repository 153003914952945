import { DateUtils } from "@/lib/utils/date";
import { Format } from "@/lib/utils/format";
import type { CustomFieldProjectType, SortModel } from "@/react/prop-types";
import { useGroupContext } from "@/react/providers/group-context-provider";
import { PersonStore } from "@/stores/person-store.core";
import { PersonTearsheetProvider } from "../tearsheets/people/people-tearsheet";
import { CustomFieldEntity } from "@laborchart-modules/common/dist/rethink/schemas/enums/custom-fields";
import { PersonStatus } from "@laborchart-modules/common/dist/rethink/schemas/enums/people";
import type { FindPeoplePaginatedQueryParams } from "@laborchart-modules/lc-core-api";
import { Box, DetailPage, useI18nContext } from "@procore/core-react";
import type { TableApi } from "@procore/data-table";
import moment from "moment-timezone";
import * as React from "react";
import { useGetCustomFields } from "../common/queries/queries";
import { useGetGroupOptionsQuery } from "../tearsheets/project/queries";
import { convertDataTableFilters, convertDataTableSort, filterGroupDetails } from "./helpers";
import { PeopleListDataTable } from "./people-list-data-table";
import type { PeopleListServerFilter } from "./people-list-prop-types";

export function PeopleListContainer() {
   const { groupId } = useGroupContext();
   const I18n = useI18nContext();
   const { data: groupOptions } = useGetGroupOptionsQuery();
   const [tableApi, setTableApi] = React.useState<TableApi>();
   const { data: customFields } = useGetCustomFields(CustomFieldEntity.PERSON);

   const url = new URL(window.location.href);
   const params = url.searchParams;
   const [viewId] = React.useState(params.get("viewId"));

   const handleTableReady = (api: TableApi) => setTableApi(api);

   const fetchPeopleList = async (
      filters: PeopleListServerFilter[],
      sortModel: SortModel[],
      startingAfter?: string,
      search?: string,
   ) => {
      const coreApiFilters = convertDataTableFilters(filters);

      const coreApiSort = convertDataTableSort(sortModel);
      const params: FindPeoplePaginatedQueryParams = {
         timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
         limit: 100,
         starting_after: startingAfter,
         search: search,
         sort_by: coreApiSort.sort_by,
         sort_direction: coreApiSort.direction,
         custom_field_id: coreApiSort.custom_field_id, // required when sorted by custom field
         group_ids: groupId === "my-groups" ? undefined : [groupId],
         filters: coreApiFilters,
      };
      const peopleData = await PersonStore.findPeopleList(params).payload;

      const peopleListDetails = [];

      const setBooleanValue = (value: boolean) =>
         value
            ? I18n.t("views.company.workforce_planning.boolean_values.yes_value")
            : I18n.t("views.company.workforce_planning.boolean_values.no_value");

      const setDateValue = (value?: number | null) => (value ? new Date(value) : null);

      for (const personData of peopleData.data) {
         const personListOptions = filterGroupDetails({
            groupOptions,
            personGroupList: personData.group_ids ?? [],
         });
         const tags = personData.tag_instances?.map((tag) => ({
            label: tag.tag.abbreviation,
            color: tag.tag.color,
            id: tag.tag.id,
            name: tag.tag.name,
            shape: "pill",
            componentName: "multiselect",
         }));
         const personStatus = {
            value: personData.status,
            label:
               personData.status == PersonStatus.ACTIVE
                  ? I18n.t("views.company.workforce_planning.active")
                  : I18n.t("views.company.workforce_planning.inactive"),
            color: personData.status == PersonStatus.ACTIVE ? "green" : "gray",
         };
         // Initialize the base people details
         const personDetails: Record<string, any> = {
            ...personData,
            person: {
               name: {
                  first: personData.name.first ?? " ",
                  last: personData.name.last ?? " ",
               },
               profile_pic_url: personData.profile_pic_url,
            },
            permission: personData.permission_level?.name,
            job_title: {
               label: personData.position?.name ?? "",
               color: personData.position?.color ?? "",
               shape: "circle",
            },
            tag_instances: tags,
            status: personStatus,
            hired_date: setDateValue(personData.hired_date),
            linked_to_procore: personData.procore_id,
            language: Format.capitalize(personData.language),
            notification_profile: personData.notification_profile?.name,
            gender: Format.capitalize(personData.gender ?? ""),
            dob: setDateValue(personData.dob),
            receive_email: setBooleanValue(personData.can_receive_email),
            receive_mobile: setBooleanValue(personData.can_receive_mobile),
            receive_sms: setBooleanValue(personData.can_receive_sms),
            groups: personListOptions,
         };

         // Adding custom fields as individual attributes
         if (personData.custom_fields) {
            personData.custom_fields.forEach((customField: CustomFieldProjectType) => {
               const { name, value } = customField;
               if (name.includes("date") && value) {
                  personDetails[name] = DateUtils.getAttachedDate(value);
               } else {
                  personDetails[name] = value;
               }
            });
         }
         peopleListDetails.push(personDetails);
      }
      return { data: peopleListDetails, pagination: peopleData.pagination };
   };

   const currentDateTime = moment().format("YYYYMMDD_HHmmss");

   return (
      <PersonTearsheetProvider peopleTableApi={tableApi}>
         <DetailPage width="block" className={"border-box people-list-data-table"}>
            <DetailPage.Main>
               <DetailPage.Body>
                  <DetailPage.Card>
                     <DetailPage.Section>
                        <Box
                           style={{
                              height: "800px",
                              marginTop: "5px",
                           }}
                        >
                           {!viewId && groupOptions && (
                              <PeopleListDataTable
                                 fetchPeopleList={fetchPeopleList}
                                 groupId={groupId}
                                 savedView={null}
                                 currentDateTime={currentDateTime}
                                 tableApi={tableApi}
                                 handleTableReady={handleTableReady}
                                 customFields={customFields}
                                 groupOptions={groupOptions}
                              />
                           )}
                        </Box>
                     </DetailPage.Section>
                  </DetailPage.Card>
               </DetailPage.Body>
            </DetailPage.Main>
         </DetailPage>
      </PersonTearsheetProvider>
   );
}
