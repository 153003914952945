import { Box, DetailPage, useI18nContext } from "@procore/core-react";
import React, { useEffect, useState } from "react";
import { TimeOffStore } from "@/stores/time-off-store.core";
import { SavedViewStore } from "@/stores/saved-view-store.core";
import { GroupStore } from "@/stores/group-store.core";
import { DateUtils } from "@/lib/utils/date";
import { useGroupContext } from "@/react/providers/group-context-provider";
import { TimeOffListDataTable } from "./time-off-list-data-table";
import { getDetachedDay } from "@laborchart-modules/common/dist/datetime";
import { PersonStore } from "@/stores/person-store.core";
import type { IServerFilter } from "@procore/data-table";
import type {
   FindTimeOffPaginatedQueryParams,
   FindTimeOffPaginatedResponse,
} from "@laborchart-modules/lc-core-api/dist/api/time-off/find-time-off";
import type { CreateTimeOffFormValues, EditTimeOffFormValues } from "./time-off-list-prop-types";
import type { SelectValue, SortModel } from "@/react/prop-types";
import {
   convertDataTableFilters,
   convertDataTableSort,
   columnHeadersMap,
   defaultTimeOffTableConfig,
   filterFieldMap,
   filterRendererMap,
} from "./helpers";
import { convertSavedViewToDataTableConfig } from "@/react/shared/helper";
import { PositionStore } from "@/stores/position-store.core";
import moment from "moment-timezone";
import { timeOffReasons } from "../tearsheets/time-off/constants";
import { PersonStatus } from "@laborchart-modules/common/dist/rethink/schemas/enums/people";
import { useViewPreferenceContext } from "@/react/providers/permission-context-provider";

export const TimeOffListContainer = ({ setTitle }: { setTitle: (title: string) => void }) => {
   const I18n = useI18nContext();
   const [savedView, setSavedView] = useState<any>();
   const { groupId } = useGroupContext();
   const { getViewPreference } = useViewPreferenceContext();

   const url = new URL(window.location.href);
   const params = url.searchParams;
   const [viewId] = useState(params.get("viewId"));

   const isLastNameFirst = getViewPreference()?.displayLastNamesFirst() ?? false;

   const fetchTimeOffList = async (
      filters: IServerFilter[],
      sortModel: SortModel[],
      startingAfter?: string,
      search?: string,
   ) => {
      const coreApiFilters = convertDataTableFilters(filters);
      const coreApiSort = convertDataTableSort(sortModel, isLastNameFirst);
      const params: FindTimeOffPaginatedQueryParams = {
         timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
         limit: 100,
         starting_after: startingAfter ?? undefined,
         search: search,
         sort_by: coreApiSort.sort_by,
         sort_direction: coreApiSort.direction,
         group_id: groupId == "my-groups" ? undefined : groupId,
         filters: coreApiFilters,
      };
      const timeOffData: FindTimeOffPaginatedResponse = await TimeOffStore.findTimeOffPaginated(
         params,
      ).payload;
      const timeOffListDetails = [];

      const getWorkerName = (personName: { first?: string; last?: string }): string => {
         const { first = "", last = "" } = personName;
         return isLastNameFirst ? `${last} ${first}`.trim() : `${first} ${last}`.trim();
      };

      // TODO: Refactor server methods for testability
      /* istanbul ignore next */
      for (const timeOff of timeOffData.data) {
         const workerName = getWorkerName(timeOff.person_name ?? {});
         const reasonObject =
            timeOff.reason && timeOffReasons.find((x) => x.value == timeOff.reason);
         timeOffListDetails.push({
            ...timeOff,
            workers_name: {
               label: workerName,
               href: `/groups/${groupId}/people/${timeOff.person_id}`,
            },
            job_title: {
               label: timeOff.job_title ? timeOff.job_title.name : "",
               color: timeOff.job_title ? timeOff.job_title.color : "",
               shape: "circle",
            },
            start_date: timeOff.start_day ? DateUtils.getAttachedDate(timeOff.start_day) : "",
            end_date: timeOff.end_day ? DateUtils.getAttachedDate(timeOff.end_day) : "",
            daily_start_time: timeOff.batch_start_time
               ? DateUtils.formatTimeVal(timeOff.batch_start_time).trim()
               : "",
            daily_end_time: timeOff.batch_end_time
               ? DateUtils.formatTimeVal(timeOff.batch_end_time).trim()
               : "",
            reason: reasonObject ? reasonObject : timeOff.reason,
            type: timeOff.is_paid
               ? {
                    id: 1,
                    value: "paid",
                    label: I18n.t("views.company.workforce_planning.time_off.paid"),
                    color: "green",
                 }
               : {
                    id: 2,
                    value: "unpaid",
                    label: I18n.t("views.company.workforce_planning.time_off.unpaid"),
                    color: "yellow",
                 },
            repeat: timeOff.repeat ? timeOff.repeat : "",
            occurences: timeOff.occurrences_count ? timeOff.occurrences_count : 0,
            submitted_on: timeOff.created_at ? timeOff.created_at : "",
            person_status: timeOff.person_status,
         });
      }
      return { data: timeOffListDetails, pagination: timeOffData.pagination };
   };

   const streamPeople = async () => {
      const params: {
         group_id?: string;
         status: PersonStatus;
      } = {
         status: PersonStatus.ACTIVE,
      };
      if (groupId != "my-groups") {
         params.group_id = groupId;
      }
      const stream = await PersonStore.findPeopleStream(params).stream;

      const people = [];

      for await (const item of stream) {
         people.push({
            id: item.id,
            label: `${item.name.first} ${item.name.last}`,
         });
      }
      return people;
   };

   const streamGroups = async () => {
      const stream = await GroupStore.findGroupsStream().stream;

      const groups = [];

      for await (const item of stream) {
         groups.push({
            id: item.id,
            label: item.name,
         });
      }
      return groups;
   };

   const streamJobTitles = async () => {
      const params: { group_id?: string } = {};
      if (groupId != "my-groups") {
         params.group_id = groupId;
      }

      const stream = await PositionStore.findPositionsStream(params).stream;

      const job_titles = [];

      for await (const item of stream) {
         job_titles.push({
            id: item.id,
            label: item.name,
         });
      }

      return job_titles;
   };

   /* istanbul ignore next */
   const fetchSavedView = async (viewId: string) => {
      const data = await SavedViewStore.getSavedView(viewId).payload;
      setTitle(data.data?.name ?? I18n.t("views.company.workforce_planning.list"));
      setSavedView(data);
   };

   /* istanbul ignore next */
   const createTimeOff = async (params: CreateTimeOffFormValues) => {
      const payload = {
         batch_end_time: params.daily_end_time.value,
         batch_start_time: params.daily_start_time.value,
         end_day: getDetachedDay(params.end_date),
         start_day: getDetachedDay(params.start_date),
         person_id: params.resource_id.id,
         is_paid: params.type.value == "paid",
         repeat: params.repeat.value,
         apply_to_saturday: params.weekend?.find(
            (x: SelectValue<string>) => x.value == "apply_to_saturday",
         )
            ? true
            : false,
         apply_to_sunday: params.weekend?.find(
            (x: SelectValue<string>) => x.value == "apply_to_sunday",
         )
            ? true
            : false,
         archived_at: null,
         reason: params.reason.value,
         cadence: params.cadence,
         repeat_end_day: params.repeat_end_date ? getDetachedDay(params.repeat_end_date) : null,
      };
      const response = await TimeOffStore.createTimeOff(payload).payload;
      return response;
   };

   /* istanbul ignore next */
   const updateTimeOff = async (id: string, params: EditTimeOffFormValues) => {
      const payload = {
         batch_end_time: params.daily_end_time?.value ? params.daily_end_time.value : undefined,
         batch_start_time: params.daily_start_time?.value
            ? params.daily_start_time.value
            : undefined,
         end_day: params.end_date ? getDetachedDay(params.end_date) : undefined,
         start_day: params.start_date ? getDetachedDay(params.start_date) : undefined,
         is_paid: params.type.value == "paid",
         apply_to_saturday: params.weekend?.find(
            (x: SelectValue<string>) => x.value == "apply_to_saturday",
         )
            ? true
            : false,
         apply_to_sunday: params.weekend?.find(
            (x: SelectValue<string>) => x.value == "apply_to_sunday",
         )
            ? true
            : false,
         archived_at: null,
         reason: params.reason?.value ? params.reason.value : undefined,
      };
      const response = await TimeOffStore.updateTimeOff(id, payload).payload;
      return response;
   };

   /* istanbul ignore next */
   const bulkUpdateTimeOff = async (ids: string[], values: EditTimeOffFormValues) => {
      const payload = ids.map((x: any) => {
         return {
            id: x,
            is_paid: values.type ? values.type.value == "paid" : undefined,
            reason: values.reason ? values.reason.value : undefined,
            batch_start_time: values.daily_start_time ? values.daily_start_time.value : undefined,
            batch_end_time: values.daily_end_time ? values.daily_end_time.value : undefined,
            start_day: values.start_date ? getDetachedDay(values.start_date) : undefined,
            end_day: values.end_date ? getDetachedDay(values.end_date) : undefined,
         };
      });
      await TimeOffStore.updateTimeOffStream(payload).stream;
   };
   const currentDateTime = moment().format("YYYYMMDD_HHmmss");

   /* istanbul ignore next */
   useEffect(() => {
      if (viewId) {
         fetchSavedView(viewId);
      }
   }, [viewId]);

   /* istanbul ignore next */
   return (
      <DetailPage width="block" className={"border-box"}>
         <DetailPage.Main>
            <DetailPage.Body>
               <DetailPage.Card>
                  <DetailPage.Section>
                     <Box
                        style={{
                           height: "800px",
                           marginTop: "5px",
                        }}
                     >
                        {!viewId && (
                           <TimeOffListDataTable
                              fetchTimeOffList={fetchTimeOffList}
                              groupId={groupId}
                              streamPeople={streamPeople}
                              createTimeOff={createTimeOff}
                              savedView={null}
                              streamJobTitles={streamJobTitles}
                              updateTimeOff={updateTimeOff}
                              currentDateTime={currentDateTime}
                              streamGroups={streamGroups}
                              bulkUpdateTimeOff={bulkUpdateTimeOff}
                           />
                        )}
                        {/* If the view ID is present, wait until the saved view is loaded from the API to render the data table */}
                        {viewId && savedView && (
                           <TimeOffListDataTable
                              fetchTimeOffList={fetchTimeOffList}
                              groupId={groupId}
                              streamPeople={streamPeople}
                              createTimeOff={createTimeOff}
                              savedView={convertSavedViewToDataTableConfig(
                                 savedView.data,
                                 columnHeadersMap,
                                 defaultTimeOffTableConfig,
                                 filterFieldMap,
                                 filterRendererMap,
                              )}
                              streamJobTitles={streamJobTitles}
                              updateTimeOff={updateTimeOff}
                              currentDateTime={currentDateTime}
                              streamGroups={streamGroups}
                              bulkUpdateTimeOff={bulkUpdateTimeOff}
                           />
                        )}
                     </Box>
                  </DetailPage.Section>
               </DetailPage.Card>
            </DetailPage.Body>
         </DetailPage.Main>
      </DetailPage>
   );
};
