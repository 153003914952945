import { UNSAFE_FilterToken as FilterToken } from "@procore/core-react";
import type { Dispatch, SetStateAction } from "react";
import React from "react";
import type { ganttFilterOptionTypes, ganttFilterType } from "./prop-types";
import { ganttFilterPillType } from "./prop-types";
import { INITIAL_GANTT_FILTER } from "./gantt-container";
import "./gantt-container.css";

const ganttFilterPills: Array<{
   name: ganttFilterOptionTypes;
   filterType: ganttFilterPillType;
   label: string;
}> = [
   { name: "jobTitles", filterType: ganttFilterPillType.MULTISELECT, label: "Job Titles" },
   { name: "projectStatuses", filterType: ganttFilterPillType.MULTISELECT, label: "Statuses" },
   { name: "onlyShow", filterType: ganttFilterPillType.CHECKBOX, label: "Only show" },
   {
      name: "hideEmptyProject",
      filterType: ganttFilterPillType.BOOLEAN,
      label: "Hide Empty Projects",
   },
   //  { name: "startDate", filterType: ganttFilterPillType.DATE, label: "Start Date" },
   //  { name: "endDate", filterType: ganttFilterPillType.DATE, label: "End Date" },
];

export const GanttControlPanelFilterPills = (props: {
   ganttFilter: ganttFilterType;
   setGanttFilter: Dispatch<SetStateAction<ganttFilterType>>;
}) => {
   const { ganttFilter, setGanttFilter } = props;
   // const getDateQualifiersName = useCallback(
   //    (label: dateQualifier | null): string | null => {
   //       return dateQualifiers.find((qualifier) => qualifier.label === label)?.name ?? null;
   //    },
   //    [dateQualifiers],
   // );
   const onTokenRemoveClick = (name: ganttFilterOptionTypes) => {
      setGanttFilter((ganttFilter: ganttFilterType) => ({
         ...ganttFilter,
         [name]: INITIAL_GANTT_FILTER[name],
      }));
   };

   return (
      <div className="filterPillsWrapper">
         {ganttFilterPills.map((pill, index) => {
            const currentFilter: any = ganttFilter[pill.name];
            const isActive = currentFilter.length || currentFilter === true || currentFilter.date;
            let label;

            switch (pill.filterType) {
               case ganttFilterPillType.MULTISELECT:
                  if (currentFilter.length) label = `${pill.label} (${currentFilter.length})`;
                  break;
               case ganttFilterPillType.BOOLEAN:
                  if (currentFilter) label = pill.label;
                  break;
               case ganttFilterPillType.CHECKBOX:
                  if (currentFilter.length) label = `${pill.label}: ${currentFilter.join(", ")}`;
                  break;
               // case ganttFilterPillType.DATE:
               //    if (currentFilter.qualifier && currentFilter.date)
               //       label = `${pill.label} ${getDateQualifiersName(
               //          currentFilter.qualifier,
               //       )} ${dateFormat(new Date(currentFilter.date))}`;
               //    break;
            }

            return (
               isActive && (
                  <FilterToken
                     key={index}
                     style={{
                        padding: "0 12px",
                     }}
                  >
                     <FilterToken.Label
                        style={{
                           fontWeight: "600",
                        }}
                     >
                        {label}
                     </FilterToken.Label>
                     <FilterToken.Remove
                        onClick={() => onTokenRemoveClick(pill.name)}
                        style={{
                           marginLeft: "6px",
                           padding: "0 4px",
                        }}
                     />
                  </FilterToken>
               )
            );
         })}
      </div>
   );
};
