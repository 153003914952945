import { authManager } from "@/lib/managers/auth-manager";
import type { IntegratedField, PillValue, SelectValue, SortModel } from "@/react/prop-types";
import { AuthAction, usePermissionContext } from "@/react/providers/permission-context-provider";
import {
   createColumnDefinitionsFromCustomHeaders,
   findCustomField,
} from "@/react/shared/custom-field-utils";
import { getReorderedColumnDefinition, isSensitiveField } from "@/react/shared/helper";
import { ConfirmDeleteModal } from "@/react/shared/modals/confirm-delete-modal";
import { PersonStore } from "@/stores/person-store.core";
import type { CustomFieldType, Person } from "@laborchart-modules/common";
import { Lock } from "@procore/core-icons";
import { Box, Flex, FlexList, useI18nContext } from "@procore/core-react";
import type {
   ColumnDefinition,
   DataTableCellRendererProps,
   DateCellColumnDefinition,
   PillCellColumnDefinition,
   ServerSideGetRowsParams,
} from "@procore/data-table";
import {
   DateCellRenderer,
   MultiSelectCellRenderer,
   PersonCellRenderer,
   PillCellRenderer,
   PillSelectCellEditor,
   ServerSideDataTable,
   TextCellRenderer,
} from "@procore/data-table";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useGetIntegratedFields, useGetTagOptionsQuery } from "../common/queries/queries";
import {
   ColorSelectEditor,
   ColorSelectRenderer,
} from "../data-table/ColorSelectComponent/ColorSelectColumn";
import MultiSelectPillFilter from "../data-table/custom-filters/multiselect-pill-filter";
import { StatusFilter } from "../data-table/custom-filters/status-filter";
import { CustomLinkEditor } from "../data-table/custom-link-column/custom-link-cell";
import type { CustomMultiSelect } from "../data-table/custom-multiselect-column/custom-multiselect-column";
import { CustomMultiSelectEditor } from "../data-table/custom-multiselect-column/custom-multiselect-column";
import { getFormattedGroupName, isFieldLocked } from "../data-table/helpers";
import { LinkToProcoreRenderer } from "../data-table/LinkToProcore/LinkToProcore";
import { defaultPeopleListTableConfig } from "./helpers";
import type { FormattedTags } from "./people-list-prop-types";
import {
   PEOPLE_STATUS_OPTIONS,
   type PeopleListDataTableProps,
   type PeopleListServerFilter,
} from "./people-list-prop-types";
import { PeopleListQuickControls } from "./people-list-quick-controls";
import "./style.css";

const LOCAL_STORAGE_CONFIG_KEY = "personListTableConfig";

const renderHeaderNode = (peopleIntegratedFields: IntegratedField[], field: string) => {
   return isFieldLocked(peopleIntegratedFields, field) ? (
      <Lock size="sm" data-testid="lock-icon" />
   ) : null;
};

export const PeopleListDataTable = (props: PeopleListDataTableProps) => {
   const I18n = useI18nContext();
   const {
      customFields,
      groupOptions,
      groupId,
      savedView,
      tableApi,
      fetchPeopleList,
      handleTableReady,
   } = props;

   const [peopleIntegratedFields, setPeopleIntegratedFields] = useState<IntegratedField[]>([]);
   const { checkAuthAction } = usePermissionContext();
   const [peopleSensitiveFields, setPeopleSensitiveFields] = useState<string[]>([]);
   const [canDeletePeople, setCanDeletePeople] = useState(false);
   const [canViewPeopleTags, setCanViewPeopleTags] = useState(false);
   const [canViewPeopleSensitiveFields, setCanViewPeopleSensitiveFields] = useState(false);
   const { data: tagOptions, isLoading: isTagLoading } = useGetTagOptionsQuery();
   const { data: integratedFields } = useGetIntegratedFields();
   const [customColumnDefinitions, setCustomColumnDefinitions] = useState<ColumnDefinition[]>([]);

   const formattedOptions: FormattedTags[] = [];
   const formattedTags: FormattedTags[] = [];
   tagOptions?.forEach((tag) => {
      const formattedTag = { ...tag, shape: "pill", componentName: "multiselect" };
      formattedOptions.push(formattedTag);
      formattedTags.push({ ...formattedTag, label: tag.name });
   });

   // Next starting_after value for pagination
   const nextStartAfter = useRef(undefined);

   // reading tableConfig like this to avoid re-renders for server side filters
   // they kind of reset when re-rendered
   const cachedConfig =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_CONFIG_KEY)!) ?? defaultPeopleListTableConfig;

   const tableConfig = savedView ?? cachedConfig;

   const serverFilters = tableConfig.serverFilters;

   /*unique key formed to pass it to DataTable Component for remounting of component when needed
   this is required if filters are applied and someone switches the group from navigation bar
   or when create tearsheet or people hyperlink is clicked on the table cell, it cause re-rendering so remounting
   of DataTable component is needed for persisting with filters*/
   const dataTableKey = groupId + "" + JSON.stringify(serverFilters);

   //If the Group ID prop changes, fetch the people list again
   useEffect(() => {
      if (tableApi) {
         tableApi.refreshServerSide({});
      }
   }, [groupId]);

   useEffect(() => {
      if (integratedFields) {
         if (Array.isArray(integratedFields.data.people_integrated_fields)) {
            setPeopleIntegratedFields(
               integratedFields.data.people_integrated_fields.map((field) =>
                  field.property == "first_name" || field.property == "last_name"
                     ? { ...field, property: "person" }
                     : field,
               ),
            );
         }
      }
   }, [integratedFields]);

   const onTableConfigChange = useCallback(
      (tableConfig: any) => {
         if (!savedView) {
            localStorage.setItem(LOCAL_STORAGE_CONFIG_KEY, JSON.stringify(tableConfig));
         }
      },
      [savedView],
   );

   useEffect(() => {
      const canViewPeopleTags = checkAuthAction(AuthAction.VIEW_PEOPLE_TAGS);
      const canDeletePeople = checkAuthAction(AuthAction.DELETE_PEOPLE);

      setCanViewPeopleTags(canViewPeopleTags);
      setCanDeletePeople(canDeletePeople);

      const canViewPeopleSensitiveFields = checkAuthAction(AuthAction.VIEW_PEOPLE_SENSITIVE);
      setCanViewPeopleSensitiveFields(canViewPeopleSensitiveFields);

      setPeopleSensitiveFields(
         authManager
            .peopleSensitiveFields()
            .map((field) => (field == "is_male" ? "gender" : field)),
      );
   }, [checkAuthAction]);

   // DataTable Column definitions
   /* istanbul ignore next */
   const peopleNameColumn: ColumnDefinition = {
      field: "person",
      headerName: I18n.t("views.company.workforce_planning.people.person_name"),
      cellRenderer: PersonCellRenderer,
      cellRendererParams: {
         getPersonName: ({ name }: Person) => `${name.last} ${name.first}`,
         getInitials: ({ name }: Person) => name.first[0] + name.last[0],
         getImageUrl: ({ profile_pic_url }: Person) => profile_pic_url,
      },
      editable: false,
      hidden: false,
      lockVisible: true,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "person"),
      },
   };
   /* istanbul ignore next */
   const employeeNumberPeopleListColumn: ColumnDefinition = {
      field: "employee_number",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.employee_id"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "employee_number"),
      },
   };
   /* istanbul ignore next */
   const permissionPeopleListColumn: ColumnDefinition = {
      field: "permission",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.permission"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "permission"),
      },
   };

   /* istanbul ignore next */
   const jobTitleColumn: ColumnDefinition = {
      field: "job_title",
      cellRenderer: ColorSelectRenderer,
      cellRendererParams: {
         getColor: (item: { color: string }) => item.color,
         getShape: (item: { shape: string }) => item.shape,
      },
      getStringFormattedValue: (item) => item.label,
      cellEditor: ColorSelectEditor,
      cellEditorParams: {
         getColor: (item: { color: string }) => item.color,
      },
      headerName: I18n.t("views.company.workforce_planning.people.job_title"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "job_title"),
      },
   };
   const address1PeopleListColumn: ColumnDefinition = {
      field: "address_1",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.address"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "address_1"),
      },
      ...isSensitiveField(
         "address_1",
         peopleSensitiveFields,
         canViewPeopleSensitiveFields,
         tableConfig,
      ),
   };

   /* istanbul ignore next */
   const address2PeopleListColumn: ColumnDefinition = {
      field: "address_2",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.address_2"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "address_2"),
      },
      ...isSensitiveField(
         "address_2",
         peopleSensitiveFields,
         canViewPeopleSensitiveFields,
         tableConfig,
      ),
   };

   /* istanbul ignore next */
   const cityPeopleListColumn: ColumnDefinition = {
      field: "city_town",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.city"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "city"),
      },
      ...isSensitiveField(
         "city_town",
         peopleSensitiveFields,
         canViewPeopleSensitiveFields,
         tableConfig,
      ),
   };
   /* istanbul ignore next */
   const stateColumn: ColumnDefinition = {
      field: "state_province",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.state"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "state"),
      },
      ...isSensitiveField(
         "state_province",
         peopleSensitiveFields,
         canViewPeopleSensitiveFields,
         tableConfig,
      ),
   };
   /* istanbul ignore next */
   const zipcodePeopleListColumn: ColumnDefinition = {
      field: "zipcode",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.postal"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "zipcode"),
      },
   };
   /* istanbul ignore next */
   const countryPeopleListColumn: ColumnDefinition = {
      field: "country",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.country"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "country"),
      },
      ...isSensitiveField(
         "country",
         peopleSensitiveFields,
         canViewPeopleSensitiveFields,
         tableConfig,
      ),
   };
   /* istanbul ignore next */
   const tagsPeopleListColumn: ColumnDefinition = {
      field: "tag_instances",
      headerName: I18n.t("views.company.workforce_planning.people.tags"),
      cellRenderer: ColorSelectRenderer,
      cellRendererParams: {
         getShape: (item: { shape: string }) => (item.shape = "pill"),
         getObjectValue: (cellValue: any) => cellValue,
      },
      editable: false,
      cellEditor: ColorSelectEditor,
      cellEditorParams: {
         options: formattedOptions,
         getOptions: (cellValue: any) => cellValue,
         getComponentName: "multiselect",
      },
      getStringFormattedValue: (item) => item?.map((tag: any) => tag.label).join(", "),
      ...(canViewPeopleTags
         ? {
              filterRenderer: MultiSelectPillFilter as any,
              filterProps: {
                 getFilterHeadingText: () => I18n.t("views.company.workforce_planning.people.tags"),
                 getFilterOptions: (): any => {
                    return formattedTags as any;
                 },
                 getFilterTokenText: (item: any) => {
                    return `${I18n.t("views.company.workforce_planning.people.tags")}: (${
                       item.value.length
                    })`;
                 },
                 getLabel: (item: any) => item.label,
              },
           }
         : {}),
      sortable: false,
      hidden:
         !canViewPeopleTags ||
         tableConfig.columnState.find((col: any) => col.field === "tag_instances")?.hidden,
      lockVisible: !canViewPeopleTags,
   };

   /* istanbul ignore next */
   const statusPeopleListColumn: PillCellColumnDefinition = {
      field: "status",
      headerName: I18n.t("views.company.workforce_planning.people.status"),
      cellRenderer: PillCellRenderer,
      filterRenderer: StatusFilter,
      filterProps: {
         getFilterHeadingText: () => I18n.t("views.company.workforce_planning.people.status"),
         getFilterOptions: () => {
            return PEOPLE_STATUS_OPTIONS as any;
         },
         getFilterTokenText: (item: any) => {
            // Prefix text for the status
            const text = I18n.t("views.company.workforce_planning.people.status") + ": ";

            // Create an array of translated status values
            const status: string[] = item.value.map((v: any) => {
               return I18n.t("views.company.workforce_planning." + v.value);
            });

            // Join the translated statuses with a comma and return the complete text
            return text + status.join(" & ");
         },
         getLabel: (item: any) => item.label,
      },
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "status"),
      },
      cellEditor: PillSelectCellEditor,
      cellRendererParams: {
         getColor: (item: PillValue<any>) => {
            return item.color;
         },
      },
      cellEditorParams: {
         selectOptions: () =>
            new Promise((resolve) => {
               setTimeout(() => {
                  resolve(PEOPLE_STATUS_OPTIONS);
               }, 500);
            }),
         getId: (item: PillValue<any>) => item.id,
         getColor: (item: PillValue<any>) => {
            return item.color;
         },
      },
      getStringFormattedValue: (value: PillValue<any>) => {
         if (value === undefined) {
            return "";
         }
         return value.label;
      },
      flex: 1,
   };

   /* istanbul ignore next */
   const emailPeopleListColumn: ColumnDefinition = {
      field: "email",
      cellRenderer: TextCellRenderer,
      cellEditor: CustomLinkEditor,
      headerName: I18n.t("views.company.workforce_planning.people.email"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "email"),
      },
      ...isSensitiveField(
         "email",
         peopleSensitiveFields,
         canViewPeopleSensitiveFields,
         tableConfig,
      ),
   };

   /* istanbul ignore next */
   const hourlyWagePeopleListColumn: ColumnDefinition = {
      field: "hourly_wage",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.hourly_wage"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "hourly_wage"),
      },
   };

   /* istanbul ignore next */
   const notificationProfilePeopleListColumn: ColumnDefinition = {
      field: "notification_profile",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.notification_profile"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "notification_profile"),
      },
   };
   /* istanbul ignore next */
   const phonePeopleListColumn: ColumnDefinition = {
      field: "phone",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.phone_number"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "phone"),
      },
      ...isSensitiveField(
         "phone",
         peopleSensitiveFields,
         canViewPeopleSensitiveFields,
         tableConfig,
      ),
   };
   /* istanbul ignore next */
   const genderPeopleListColumn: ColumnDefinition = {
      field: "gender",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.gender"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "gender"),
      },
      sortable: false,
      ...isSensitiveField(
         "gender",
         peopleSensitiveFields,
         canViewPeopleSensitiveFields,
         tableConfig,
      ),
   };
   /* istanbul ignore next */
   const languagePeopleListColumn: ColumnDefinition = {
      field: "language",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.language"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "language"),
      },
   };
   /* istanbul ignore next */
   const hiredDateColumn: ColumnDefinition = {
      field: "hired_date",
      cellRenderer: DateCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.hired_date"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "hired_date"),
      },
      ...isSensitiveField(
         "hired_date",
         peopleSensitiveFields,
         canViewPeopleSensitiveFields,
         tableConfig,
      ),
   };
   /* istanbul ignore next */
   const birthDatePeopleListColumn: DateCellColumnDefinition = {
      field: "dob",
      cellRenderer: DateCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.birth_date"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "birth_date"),
      },
      ...isSensitiveField("dob", peopleSensitiveFields, canViewPeopleSensitiveFields, tableConfig),
   };
   const receiveEmailPeopleListColumn: ColumnDefinition = {
      field: "receive_email",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.receive_email"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "receive_email"),
      },
   };

   const receiveMobilePeopleListColumn: ColumnDefinition = {
      field: "receive_mobile",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.receive_mobile"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "receive_mobile"),
      },
   };

   const receiveSmsPeopleListColumn: ColumnDefinition = {
      field: "receive_sms",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.receive_sms"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "receive_sms"),
      },
   };

   const groupsPeopleListColumn: ColumnDefinition = {
      field: "groups",
      headerName: I18n.t("views.company.workforce_planning.people.groups"),
      cellRenderer: MultiSelectCellRenderer,
      cellRendererParams: {
         getValue(cellValue: any) {
            return getFormattedGroupName(cellValue, groupOptions, I18n);
         },
         getId: (item: SelectValue<any>) => item?.label,
      },
      sortable: false,
      cellEditor: CustomMultiSelectEditor,
      cellEditorParams: {
         options: groupOptions as CustomMultiSelect[],
         getOptions: (cellValue: any) => cellValue,
      },
      flex: 2,
   };
   /* istanbul ignore next */
   const linkedToProcoreColumn: ColumnDefinition = {
      field: "linked_to_procore",
      cellRenderer: LinkToProcoreRenderer,
      cellRendererParams: {
         entityType: "person",
         getCompanyId: (person: Person) => person.company_id,
      },
      headerName: I18n.t("views.company.workforce_planning.people.linked_to_procore"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "linked_to_procore"),
      },
      sortable: false,
   };

   const peopleColumnDefinitions: ColumnDefinition[] = [
      peopleNameColumn,
      employeeNumberPeopleListColumn,
      permissionPeopleListColumn,
      jobTitleColumn,
      address1PeopleListColumn,
      address2PeopleListColumn,
      cityPeopleListColumn,
      stateColumn,
      zipcodePeopleListColumn,
      countryPeopleListColumn,
      tagsPeopleListColumn,
      statusPeopleListColumn,
      emailPeopleListColumn,
      hourlyWagePeopleListColumn,
      notificationProfilePeopleListColumn,
      phonePeopleListColumn,
      genderPeopleListColumn,
      languagePeopleListColumn,
      hiredDateColumn,
      birthDatePeopleListColumn,
      receiveEmailPeopleListColumn,
      receiveMobilePeopleListColumn,
      receiveSmsPeopleListColumn,
      groupsPeopleListColumn,
      linkedToProcoreColumn,
   ];

   useEffect(() => {
      if (customFields) {
         const customColumnDefinitions = createColumnDefinitionsFromCustomHeaders(
            customFields,
            tableConfig,
         );
         if (customColumnDefinitions.length > 0) {
            setCustomColumnDefinitions(customColumnDefinitions);
         }
      }
   }, [customFields, groupId]);
   //Reorder the columns based on the saved column state to maintain column order
   const reorderedPeopleColumnDefinitions = getReorderedColumnDefinition(
      customColumnDefinitions
         ? peopleColumnDefinitions.concat(customColumnDefinitions)
         : peopleColumnDefinitions,
      tableConfig.columnState,
   );
   /* istanbul ignore next */
   const handleServerSideDataRequest = useCallback(
      async ({ request, onSuccess, onError }: ServerSideGetRowsParams) => {
         try {
            let startingAfter = undefined;
            // If requesting a second page of data, apply the starting_after query param
            if ((request?.startRow ?? 0) > 0) {
               startingAfter = nextStartAfter.current;
            }
            const sortModel: SortModel[] = request.sortModel;
            const serverFilter: PeopleListServerFilter[] = request.serverFilters;

            if (customFields) {
               // Map each field in sortModel to its corresponding fieldId if it exists in customFields
               sortModel.forEach((item) => {
                  const customField = findCustomField(customFields, item.field);
                  if (customField) {
                     item.fieldId = customField.id;
                  }
               });

               // Map each filter in serverFilter to its corresponding fieldId and fieldType if it exists in customFields
               serverFilter.forEach((filter) => {
                  const customField = findCustomField(customFields, filter.field);
                  if (customField) {
                     filter.fieldId = customField.id;
                     filter.fieldType = customField.type as CustomFieldType;
                  }
               });
            }
            const data = await fetchPeopleList(
               serverFilter,
               sortModel,
               startingAfter,
               request.searchValue.trim(),
            );
            onSuccess({ rowData: data.data, rowCount: data.pagination.total_possible });
            nextStartAfter.current = data.pagination.next_starting_after;
         } catch (err) {
            onError();
         }
      },
      [groupId, customFields],
   );

   const Delete = (props: DataTableCellRendererProps) => {
      return (
         <ConfirmDeleteModal
            id={props.data.id}
            tableApi={tableApi}
            headerText={"views.company.workforce_planning.people.modals.delete.header"}
            deleteText={"views.company.workforce_planning.people.modals.delete.description"}
            successText={"views.company.workforce_planning.people.modals.delete.success"}
            onDelete={PersonStore.deletePerson}
         />
      );
   };

   /* istanbul ignore next */
   return (
      !isTagLoading && ( // Show the table only when the tag options are loaded
         <ServerSideDataTable
            columnDefinitions={reorderedPeopleColumnDefinitions}
            onServerSideDataRequest={handleServerSideDataRequest}
            getRowId={(params) => params.data.id}
            onTableConfigChange={onTableConfigChange}
            initialTableConfig={tableConfig}
            enableDynamicRowHeight
            key={dataTableKey}
         >
            <Flex
               style={{
                  height: "100%",
                  width: "100%",
               }}
               alignItems="stretch"
            >
               <Flex flex="0 1 0px" alignItems="stretch">
                  <ServerSideDataTable.FiltersPanel
                     style={{
                        marginRight: "16px",
                     }}
                  />
               </Flex>

               <Box flex="1" display="flex-column" alignItems="stretch">
                  {/* @ts-expect-error Quick Controls need to optionally accept children*/}
                  <ServerSideDataTable.QuickControls>
                     <Flex>
                        <FlexList size="xs" alignItems="center">
                           <ServerSideDataTable.Search
                              placeholder={I18n.t(
                                 "views.company.workforce_planning.people.search_placeholder",
                              )}
                           />
                           <ServerSideDataTable.FiltersPanelButton />
                           <ServerSideDataTable.QuickFilters />
                        </FlexList>
                        <FlexList size="xs" alignItems="center" style={{ marginLeft: "auto" }}>
                           <PeopleListQuickControls />
                           <ServerSideDataTable.ConfigPanelButton />
                        </FlexList>
                     </Flex>
                  </ServerSideDataTable.QuickControls>
                  <ServerSideDataTable.Table
                     onTableReady={handleTableReady}
                     paginationPageSize={100}
                     rowActions={canDeletePeople ? [Delete] : []}
                     rowSelectionEnabled={canDeletePeople}
                  />
               </Box>
               <Flex flex="0 1 0px" alignItems="stretch">
                  <ServerSideDataTable.ContextPanel style={{ marginLeft: "16px" }} />
               </Flex>
            </Flex>
         </ServerSideDataTable>
      )
   );
};
