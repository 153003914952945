import React from "react";
import { AttachmentLink } from "@/react/shared/components/attachment-links";
import { AttachmentStore } from "@/stores/attachment-store.core";
import { useToastAlertContext } from "@procore/toast-alert";
import { Spinner, Box, Dropzone, useDropzone, useI18nContext } from "@procore/core-react";
import type { FileUploadProps } from "@/react/prop-types";
import { ActionMode } from "@/react/prop-types";

// Component to upload files
export const FileUpload: React.FC<FileUploadProps> = ({
   attachments,
   setAttachments,
   deletedAttachmentIds,
   setDeletedAttachmentIds,
   loading,
   setLoading,
}) => {
   const { showToast } = useToastAlertContext();
   const I18n = useI18nContext();

   /* istanbul ignore next */
   const dropzoneState = useDropzone({
      onDropAccepted: (accepted) => {
         setLoading(true);
         const { name, size, type } = accepted[0];
         const data = {
            name,
            mimetype: type,
            bytes: size,
         };
         uploadFile(data, accepted[0]);
      },
      // this part of code will get triggered if we add some kind of validations for uploading file and it fails
      onDropRejected: (rejected) => {
         //keeping the log for now, if some validation is added in future
         console.log({
            rejected,
         });
      },
   });

   /* istanbul ignore next */
   const uploadFile = async (data: any, file: File) => {
      try {
         const attachment = await AttachmentStore.createAttachment(data).payload;
         if (attachment.id) {
            setAttachments([...attachments, attachment]);
            const formData = new FormData();
            formData.append("upload", file);
            await AttachmentStore.uploadFile(attachment.id, formData).payload;
         }
      } catch (error) {
         showToast.error(I18n.t("views.company.workforce_planning.failure_file_upload_message"));
      } finally {
         setLoading(false);
      }
   };

   const deleteAttachments = async (attachmentId: string) => {
      try {
         setLoading(true);
         const deleted = await AttachmentStore.deleteAttachment(attachmentId).payload;
         if (deleted) {
            const updatedAttachments = attachments.filter(
               (attachment) => attachment.id !== attachmentId,
            );
            setAttachments(updatedAttachments);
            setDeletedAttachmentIds([...deletedAttachmentIds, attachmentId]);
         }
      } catch (error) {
         showToast.error(I18n.t("views.company.workforce_planning.failure_file_delete_message"));
      } finally {
         setLoading(false);
      }
   };

   return (
      <Box>
         <Spinner loading={loading}>
            <Dropzone {...dropzoneState} />
            <br />
            {!loading && attachments && attachments.length > 0 && (
               <AttachmentLink
                  attachments={attachments}
                  onLinkClick={() => {}}
                  mode={ActionMode.EDIT}
                  onAttachmentDelete={deleteAttachments}
               />
            )}
         </Spinner>
      </Box>
   );
};
