import React, { useEffect } from "react";
import {
   Box,
   Button,
   Card,
   Checkbox,
   FlexList,
   Grid,
   Link,
   Select,
   Spinner,
   Title,
   Typography,
   useI18nContext,
   H2,
} from "@procore/core-react";
import styles from "../custom-style.scss";
import classnames from "classnames";
import { requestContext } from "@/stores/common/request-context";
const cx = classnames.bind(styles);
import QRCode from "qrcode";
import { Download, ExternalLink, Check } from "@procore/core-icons";
import { ActionMode, type QRCodeCardProps } from "@/react/prop-types";
import { getDownloadUrl } from "../helper";
import { downloadFile } from "@/lib/reports/report-options";
import { useToastAlertContext } from "@procore/toast-alert";
const sizeOptions = [
   {
      id: 1,
      value: 300,
      label: "300px",
   },
   {
      id: 2,
      value: 600,
      label: "600px",
   },
   {
      id: 3,
      value: 1200,
      label: "1200px",
   },
];

// this component can be extended further to include more functionality for the QR code card in any other module
export const QRCodeCard: React.FC<QRCodeCardProps> = ({
   companyQrId,
   entityQrId,
   entitySubtitle,
   entityTitle,
   entityAbbreviation,
}) => {
   const I18n = useI18nContext();
   const { showToast } = useToastAlertContext();
   const [qrCode, setQrCode] = React.useState("");
   const [destinationUrl, setDestinationUrl] = React.useState("");
   const [sizeOption, setSizeOption] = React.useState(sizeOptions[0]);
   const [includeInfo, setIncludeInfo] = React.useState(true);
   const [mode, setMode] = React.useState(ActionMode.READ);

   const generateQR = async (text: string) => {
      try {
         setQrCode(await QRCode.toDataURL(text));
      } catch (err) {
         console.error(err);
         showToast.error(
            I18n.t("views.company.workforce_planning.qr_code_generation_failure_message"),
         );
      }
   };

   const openProfilePreview = () => {
      window.open(
         destinationUrl,
         `${I18n.t("views.company.workforce_planning.qr_profile_preview")}`,
         "resizable,height=650,width=400",
      );
   };
   const downloadQRCode = () => {
      const downloadUrl = getDownloadUrl({
         companyQrId,
         entityQrId,
         entityTitle,
         entitySubtitle,
         selectedSize: sizeOption.value,
         includeInfo,
      });

      handleDownload(downloadUrl);
   };

   const handleDownload = async (downloadUrl: string) => {
      try {
         // its a function used from a jquery library
         await downloadFile(downloadUrl);
      } catch (error) {
         showToast.error(I18n.t("views.company.workforce_planning.failure_file_download_message"));
      }
   };

   useEffect(() => {
      const generateQRCode = async () => {
         let destinationUrl = `${location.origin}/qrc/${companyQrId}/${entityAbbreviation}/${entityQrId}`;
         if (requestContext.baseUrl) {
            destinationUrl = destinationUrl.replace(window.location.origin, requestContext.baseUrl);
         }
         setDestinationUrl(destinationUrl);
         await generateQR(destinationUrl);
      };

      generateQRCode();
   }, [companyQrId, entityQrId, entityAbbreviation]);

   const toggleMode = () => {
      if (mode === ActionMode.READ) {
         setMode(ActionMode.EDIT);
      } else {
         setIncludeInfo(true);
         setSizeOption(sizeOptions[0]);
         setMode(ActionMode.READ);
      }
   };

   return (
      <Card style={{ marginBottom: "5px" }}>
         <Spinner loading={!qrCode}>
            <Box padding="lg" style={{ paddingBottom: "0px" }}>
               <FlexList justifyContent="space-between">
                  <Title>
                     <Title.Text>
                        <H2>{I18n.t("views.company.workforce_planning.qr_code")}</H2>
                     </Title.Text>
                  </Title>
                  <Button variant="secondary" onClick={toggleMode}>
                     {mode === ActionMode.READ
                        ? I18n.t("views.company.workforce_planning.edit")
                        : I18n.t("views.company.workforce_planning.cancel")}
                  </Button>
               </FlexList>
            </Box>
            <Box padding="xs">
               <Grid>
                  <Grid.Row>
                     <Grid.Col colWidth={12} style={{ display: "flex" }}>
                        <img src={qrCode} className={cx("qr-code-container")} />
                        <div style={{ margin: "10px 0 0 5px" }}>
                           <Link onClick={openProfilePreview}>
                              <div className={cx("attachment-link")}>
                                 <span>
                                    {I18n.t("views.company.workforce_planning.qr_code_profile")}
                                 </span>
                                 <ExternalLink size="sm" style={{ marginLeft: "5px" }} />
                              </div>
                           </Link>
                        </div>
                     </Grid.Col>
                  </Grid.Row>
               </Grid>
            </Box>

            <hr style={{ margin: "0 10px" }} />
            <Box padding="md">
               <Grid style={{ marginBottom: "10px" }}>
                  <Grid.Row>
                     <Grid.Col colWidth={12} style={{ display: "flex" }}>
                        <Link onClick={downloadQRCode}>
                           <div className={cx("attachment-link")}>
                              <span>{I18n.t("views.company.workforce_planning.qr_download")}</span>
                              <Download size="sm" style={{ marginLeft: "5px" }} />
                           </div>
                        </Link>
                     </Grid.Col>
                  </Grid.Row>
               </Grid>
               <Grid>
                  <Grid.Row>
                     <Grid.Col colWidth={6}>
                        <Typography intent="label">
                           {I18n.t("views.company.workforce_planning.image_size")}
                        </Typography>
                     </Grid.Col>
                     <Grid.Col colWidth={6}>
                        <Typography intent="label">
                           {I18n.t("views.company.workforce_planning.image_details")}
                        </Typography>
                     </Grid.Col>
                  </Grid.Row>
                  <Grid.Row style={{ marginTop: "5px" }}>
                     <Grid.Col colWidth={6}>
                        {mode === ActionMode.READ ? (
                           <Typography>{sizeOption.label}</Typography>
                        ) : (
                           <Select
                              label={sizeOption.label}
                              placeholder={I18n.t(
                                 "views.company.workforce_planning.image_size_options_placeholder",
                              )}
                              onSelect={(sizeOption) => setSizeOption(sizeOption.item)}
                           >
                              {sizeOptions.map((item) => (
                                 <Select.Option
                                    key={item.label}
                                    value={item}
                                    selected={item.id === sizeOption.id}
                                 >
                                    {item.label}
                                 </Select.Option>
                              ))}
                           </Select>
                        )}
                     </Grid.Col>
                     <Grid.Col colWidth={6}>
                        {mode === ActionMode.READ ? (
                           <div className={cx("inline-flex")}>
                              <Check size="sm" style={{ marginRight: "10px" }} />
                              <Typography>
                                 {I18n.t(
                                    "views.company.workforce_planning.image_details_help_text",
                                 )}
                              </Typography>
                           </div>
                        ) : (
                           <Checkbox
                              checked={includeInfo}
                              onChange={() => {
                                 setIncludeInfo(!includeInfo);
                              }}
                           >
                              {I18n.t("views.company.workforce_planning.image_details_help_text")}
                           </Checkbox>
                        )}
                     </Grid.Col>
                  </Grid.Row>
               </Grid>
            </Box>
         </Spinner>
      </Card>
   );
};
