import React, { useEffect, useState } from "react";
import {
   Card,
   Box,
   EmptyState,
   FlexList,
   H2,
   Button,
   Title,
   H3,
   Grid,
   Typography,
   useI18nContext,
   Flex,
   Spinner,
} from "@procore/core-react";
import { Pencil, Trash } from "@procore/core-icons";
import { ColorSwab } from "../../../data-table/ColorSelectComponent/ColorSelectColumn";
import type { TagInstance, TagOption } from "@/react/prop-types";
import { ActionMode } from "@/react/prop-types";
import { DateUtils } from "@/lib/utils/date";
import { FileDownloadModal } from "@/react/shared/modals/file-download-modal";
import { AttachmentLink } from "@/react/shared/components/attachment-links";
import { useToastAlertContext } from "@procore/toast-alert";
import { AddTags } from "./project-details-add-tags";
import { useGetTagOptionsQuery } from "../../../common/queries/queries";
import { useModal } from "@/react/shared/hooks/useModal";
import type {
   EmptyTagsProps,
   ListTagsCardProps,
   ProjectDetailsCardProps,
   ProjectTagData,
} from "../types";
import { FileUpload } from "@/react/shared/components/file-upload";

import styles from "./project-details.scss";

import classnames from "classnames";
import { getDateString, prepareTagPayload } from "../helpers";
import type { SerializedAttachment } from "@laborchart-modules/common/dist/rethink/serializers/attachment-serializer";
import { usePermissionContext, AuthAction } from "@/react/providers/permission-context-provider";
import { DetailsCardHeader } from "@/react/shared/components/details-card-header";
import { commonButtonStyle } from "@/react/shared/helper";

const cx = classnames.bind(styles);

const EmptyTags: React.FC<EmptyTagsProps> = ({ openAddTagsModal, isAddTagsOpen }) => {
   const I18n = useI18nContext();
   if (isAddTagsOpen) {
      return null;
   }
   return (
      <React.Fragment>
         <Box padding="md">
            <FlexList justifyContent="space-between">
               <H2>{I18n.t("views.company.workforce_planning.project_details_tags.title")}</H2>
            </FlexList>
         </Box>
         <Box>
            <EmptyState compact>
               <EmptyState.Title>
                  {I18n.t(
                     "views.company.workforce_planning.project_details_tags.tags_empty_state_title",
                  )}
               </EmptyState.Title>
               <EmptyState.Description className={cx("empty-state-description")}>
                  {I18n.t(
                     "views.company.workforce_planning.project_details_tags.tags_empty_state_description",
                  )}
               </EmptyState.Description>
               <EmptyState.Actions className={cx("empty-state-actions")}>
                  <Button variant="secondary" onClick={openAddTagsModal}>
                     {I18n.t("views.company.workforce_planning.project_details_tags.add_tags")}
                  </Button>
               </EmptyState.Actions>
            </EmptyState>
         </Box>
      </React.Fragment>
   );
};

const ListTagsCard: React.FC<ListTagsCardProps> = ({
   tags,
   onUpdateProject,
   selectedTagId,
   setSelectedTagId,
   openAddTagsModal,
   isAddTagsOpen,
   canEditTags,
}) => {
   const I18n = useI18nContext();
   const [selectedAttachment, setSelectedAttachment] = useState<any>(null);
   const [attachments, setAttachments] = useState<SerializedAttachment[]>([]);
   const [deletedAttachmentIds, setDeletedAttachmentIds] = useState<string[]>([]);
   const [loading, setLoading] = useState(false);
   const { isOpen, openModal, closeModal } = useModal();

   const filterAttachmentsByTagId = (data: TagInstance, tagIdToMatch: string) => {
      return data
         .filter((item) => item.tag_id === tagIdToMatch)
         .map((item) => item.attachments)
         .flat();
   };

   useEffect(() => {
      setAttachments(filterAttachmentsByTagId(tags, selectedTagId));
   }, [selectedTagId, tags]);

   /* istanbul ignore next */
   useEffect(() => {
      const updateProject = async () => {
         if (deletedAttachmentIds.length > 0) {
            const payload: ProjectTagData = prepareTagPayload(
               attachments,
               deletedAttachmentIds,
               selectedTagId,
            );
            await onUpdateProject(payload, ActionMode.DELETE);
         }
      };

      updateProject();
   }, [deletedAttachmentIds]);

   useEffect(() => {
      setAttachments([]);
   }, [tags]);

   const toggleSelectedTagId = (tagId: string) => {
      setSelectedTagId(selectedTagId === tagId ? "" : tagId);
   };

   const handleDownloadLinkClick = (attachment: SerializedAttachment) => {
      setSelectedAttachment(attachment);
      openModal();
   };

   const handleModalClose = () => {
      setSelectedAttachment(null);
      closeModal();
   };

   const updateProject = async () => {
      const payload: ProjectTagData = prepareTagPayload(
         attachments,
         deletedAttachmentIds,
         selectedTagId,
      );
      await onUpdateProject(payload, ActionMode.SAVE);
   };

   if (isAddTagsOpen) {
      return null;
   }

   return (
      <React.Fragment>
         <DetailsCardHeader
            titleKey="views.company.workforce_planning.project_details_tags.title"
            helpTextKey="views.company.workforce_planning.project_details_tags.tags_certification_help_text"
            buttonTextKey="views.company.workforce_planning.project_details_tags.add_tag"
            openAddItemModal={openAddTagsModal}
            btnDisabled={selectedTagId !== ""}
            permission={canEditTags}
         />

         <Box className={cx("custom-box")}>
            <React.Fragment>
               {tags.map((item, index: number) => (
                  <React.Fragment key={item.id}>
                     <Box
                        padding="md"
                        style={{
                           borderBottom: index !== tags.length - 1 ? "1px solid #D6DADC" : "none",
                        }}
                     >
                        {selectedTagId !== item.tag_id && (
                           <FlexList justifyContent="space-between">
                              <Title>
                                 <Title.Text>
                                    <ColorSwab
                                       color={item.tag.color}
                                       shape="pill"
                                       label={item.tag.abbreviation}
                                    />
                                 </Title.Text>
                              </Title>
                              {canEditTags && (
                                 <span>
                                    <Pencil
                                       size="sm"
                                       style={{
                                          ...commonButtonStyle({
                                             selectedId: selectedTagId,
                                             currentId: item.id,
                                          }),
                                          marginRight: "10px",
                                       }}
                                       onClick={() => toggleSelectedTagId(item.tag_id)}
                                       data-testid="edit-tag"
                                    />

                                    <Trash
                                       size="sm"
                                       style={commonButtonStyle({
                                          selectedId: selectedTagId,
                                          currentId: item.id,
                                       })}
                                       data-testid="delete-tag"
                                    />
                                 </span>
                              )}
                           </FlexList>
                        )}
                        {selectedTagId == item.tag_id ? (
                           <Grid>
                              <Grid.Row>
                                 <Grid.Col colWidth={6} style={{ display: "flex" }}>
                                    <H3>
                                       {I18n.t(
                                          "views.company.workforce_planning.project_details_tags.tag_title",
                                       )}
                                    </H3>
                                 </Grid.Col>
                                 <Grid.Col colWidth={6}>
                                    <H3>
                                       {I18n.t(
                                          "views.company.workforce_planning.project_details_tags.tags_attachments_label",
                                       )}
                                    </H3>
                                 </Grid.Col>
                              </Grid.Row>

                              <Grid.Row style={{ marginTop: "5px" }}>
                                 <Grid.Col colWidth={6} style={{ display: "flex" }}>
                                    <Typography>{item.tag.name}</Typography>
                                 </Grid.Col>

                                 <Grid.Col colWidth={6}>
                                    <Box>
                                       <FileUpload
                                          attachments={attachments}
                                          setAttachments={setAttachments}
                                          deletedAttachmentIds={deletedAttachmentIds}
                                          setDeletedAttachmentIds={setDeletedAttachmentIds}
                                          loading={loading}
                                          setLoading={setLoading}
                                       />
                                    </Box>
                                 </Grid.Col>
                              </Grid.Row>
                              <Grid.Row>
                                 <Grid.Col colWidth={12}>
                                    <Flex justifyContent="flex-end" alignItems="center">
                                       <FlexList space="sm">
                                          <Button
                                             variant="secondary"
                                             onClick={() => {
                                                setSelectedTagId("");
                                                setAttachments([]);
                                             }}
                                             disabled={loading}
                                          >
                                             {I18n.t("views.company.workforce_planning.cancel")}
                                          </Button>
                                          <Button
                                             type="submit"
                                             onClick={() => {
                                                updateProject();
                                             }}
                                             disabled={loading}
                                          >
                                             {I18n.t("views.company.workforce_planning.save")}
                                          </Button>
                                       </FlexList>
                                    </Flex>
                                 </Grid.Col>
                              </Grid.Row>
                           </Grid>
                        ) : (
                           <Grid style={{ paddingTop: "16px" }}>
                              <Grid.Row>
                                 <Grid.Col colWidth={6} style={{ display: "flex" }}>
                                    <H3 style={{ marginRight: "10px" }}>{item.tag.name}</H3>
                                    {item.expr_date && (
                                       <Typography
                                          intent="small"
                                          className={cx("expiration-date-Tags")}
                                       >
                                          {I18n.t(
                                             "views.company.workforce_planning.project_details_tags.tags_expiration_date_label",
                                          )}
                                          :{" "}
                                          {DateUtils.getShortNumericDate(new Date(item.expr_date))}
                                       </Typography>
                                    )}
                                 </Grid.Col>
                                 <Grid.Col colWidth={6}>
                                    <Box>
                                       <H3>
                                          {I18n.t(
                                             "views.company.workforce_planning.project_details_tags.tags_attachments_label",
                                          )}
                                       </H3>
                                       {item.attachments && item.attachments.length > 0 && (
                                          <AttachmentLink
                                             attachments={item.attachments}
                                             onLinkClick={handleDownloadLinkClick}
                                          />
                                       )}
                                    </Box>
                                 </Grid.Col>
                              </Grid.Row>
                           </Grid>
                        )}
                     </Box>
                  </React.Fragment>
               ))}
            </React.Fragment>
         </Box>
         <Box padding="xxs" />
         {selectedAttachment && (
            <FileDownloadModal
               open={isOpen}
               onClose={handleModalClose}
               fileName={selectedAttachment.name}
               uploadedOn={getDateString(new Date(selectedAttachment.created_at))}
               attachmentId={selectedAttachment.id}
               mimeType={selectedAttachment.mimetype}
            />
         )}
      </React.Fragment>
   );
};

export const ProjectDetailsTagsCard: React.FC<ProjectDetailsCardProps> = ({
   projectData,
   refreshTable,
   updateProjectData,
   refetchData,
   loading: projectDataLoading,
}) => {
   const { showToast } = useToastAlertContext();
   const I18n = useI18nContext();
   const { checkAuthAction } = usePermissionContext();
   const { data: tagOptions } = useGetTagOptionsQuery();
   const [filteredTags, setFilteredTags] = useState<TagOption[]>([]);
   const [loading, setLoading] = useState<boolean>(false);
   const { openModal, closeModal, isOpen } = useModal();
   const [selectedTagId, setSelectedTagId] = useState<string>("");
   const [canEditProjectTags, setCanEditProjectTags] = useState<boolean>(false);

   const handleTagCreate = async (payload: ProjectTagData, mode: string) => {
      setLoading(true);
      try {
         await updateProject(payload, mode);
         closeModal();
      } catch (error) {
         showToast.error(I18n.t("views.company.workforce_planning.error"));
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      if (tagOptions) {
         // Filter tagOptions based on additional conditions for group_ids and globally_accessible
         const filtered = tagOptions.filter(
            (row) =>
               row.globally_accessible ||
               row.group_ids.some((id) => projectData?.data.group_ids.includes(id)),
         );
         // Filter out tags that are already available in the project
         const filteredTags = filtered.filter(
            (tag) =>
               !projectData?.data.tag_instances.some(
                  (availableTag) => availableTag.tag_id === tag.id,
               ),
         );
         setFilteredTags(filteredTags);
      }
   }, [tagOptions, projectData?.data]);

   const updateProject = async (payload: ProjectTagData, mode: string) => {
      const updatedProject = await updateProjectData(payload);
      if (updatedProject.data && mode === ActionMode.SAVE) {
         setSelectedTagId("");
         refetchData();
         if (refreshTable) {
            refreshTable();
         }
      }
   };

   useEffect(() => {
      const canEditProjectTags = checkAuthAction(AuthAction.EDIT_PROJECT_TAGS);
      setCanEditProjectTags(canEditProjectTags);
   }, [checkAuthAction]);

   return (
      <>
         {(canEditProjectTags || (projectData && projectData.data?.tag_instances.length > 0)) && (
            <Card style={{ marginBottom: "5px" }}>
               <Spinner loading={projectDataLoading}>
                  {canEditProjectTags &&
                     projectData &&
                     projectData.data?.tag_instances.length === 0 && (
                        <EmptyTags openAddTagsModal={openModal} isAddTagsOpen={isOpen} />
                     )}
                  {projectData && projectData.data?.tag_instances.length > 0 && (
                     <ListTagsCard
                        tags={projectData?.data?.tag_instances}
                        onUpdateProject={updateProject}
                        selectedTagId={selectedTagId}
                        setSelectedTagId={setSelectedTagId}
                        openAddTagsModal={openModal}
                        isAddTagsOpen={isOpen}
                        canEditTags={canEditProjectTags}
                     />
                  )}
                  {canEditProjectTags && (
                     <AddTags
                        open={isOpen}
                        onClose={closeModal}
                        handleCreate={handleTagCreate}
                        availableTags={filteredTags}
                        showLoader={loading}
                     />
                  )}
               </Spinner>
            </Card>
         )}
      </>
   );
};
